<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <div class="page-wrapper">
      <section class="appointment-cancellation-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 text-center">
              <div class="cancellation-info">
                <div class="cancellation-content text-center">
                  <h3>Cancel Your Appointment</h3>

                  <template v-if="!otpSent">
                    <p v-if="appointmentDetails.appointmentstatus !== 'C'">
                      Are you sure you want to cancel the appointment for
                      <strong>{{ appointmentDetails.patient_name }}</strong>?
                    </p>
                    <p v-else>
                      The appointment for
                      <strong>{{ appointmentDetails.patient_name }}</strong> has been cancelled.
                    </p>
                    <div class="appointment-details">
                      <div class="detail-item">
                        <span class="label">
                          <strong>Appointment Date:</strong>
                        </span>
                        <span class="value">{{ formatedDate(appointmentDetails.appointmentdate) }}</span>
                      </div>
                      <div class="detail-item">
                        <span class="label">
                          <strong>Appointment Time:</strong>
                        </span>
                        <span class="value">{{ formatedTime(appointmentDetails.appointmenttime) }}</span>
                      </div>
                      <div class="detail-item">
                        <span class="label">
                          <strong>Doctor:</strong>
                        </span>
                        <span class="value">Dr. {{ appointmentDetails.doctorname }}</span>
                      </div>
                      <div class="detail-item">
                        <span class="label">
                          <strong>Clinic:</strong>
                        </span>
                        <span class="value">{{ appointmentDetails.clinicname }}</span>
                      </div>
                      <div class="detail-item">
                        <span class="label">
                          <strong>Appointment Status:</strong>
                        </span>
                        <span :class="status.class">{{ status.text }}</span>
                      </div>
                    </div>
                    <div v-if="appointmentDetails.appointmentstatus !== 'C'">
                      <div class="form-group">
                        <label for="cancelReason">
                          <strong>Reason for Cancellation:</strong>
                        </label>
                        <textarea v-model="reason" id="cancelReason" class="form-control" rows="1"></textarea>
                      </div>
                      <button
                        @click="confirmCancellation"
                        class="btn btn-primary prime-btn"
                      >Confirm Cancel</button>
                    </div>
                  </template>

                  <template v-else>
                    <div class="account-content">
                      <div class="account-info">
                        <div class="login-back">
                        </div>
                        <div class="login-verify-img">
                          <img src="@/assets/img/icons/email-icon.svg" alt="email-icon" />
                        </div>
                        <div class="login-title">
                          <h3>OTP Verification</h3>
                          <p class="mb-0">
                             OTP sent to <i class="fa-brands fa-whatsapp" style="color: #25D366;"></i>&nbsp;WhatsApp <strong>{{ phone }}</strong>
                          </p>
                        </div>
                        <p v-if="error">
                          <b-alert v-model="showError" dismissible variant="danger">{{ error }}</b-alert>
                        </p>
                        <form
                          method="get"
                          class="digit-group login-form-control"
                          data-group-name="digits"
                          data-autosubmit="false"
                          autocomplete="off"
                          @submit.prevent="submitOtp"
                        >
                          <div class="otp-boxe">
                            <div class="mb-3e">
                              <v-otp-input length="4" v-model="otp"
                           :loading="loading"
                           autofocus></v-otp-input>
                            </div>
                          </div>
                          <div class="mb-3">
                            <div class="otp-info">
                              <div class="otp-code">
                                <p>Didn't receive OTP code?</p>
                                <v-btn
                                  @click="resendCode"
                                  :disabled="countDown > 0"
                                  variant="outlined"
                                >Resend Code</v-btn>
                              </div>
                              <div class="otp-sec">
                                <p>
                                  <i class="feather-clock"></i>
                                  {{ countDown }} secs
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="reset-btn d-flex justify-content-center">
                          <b-button
                            @click="goBack"
                            class="btn btn-secondary btn-lg"
                            type="button"
                          >
                            Back
                          </b-button>
                          <b-button
                            :disabled="otp.length < 4 || loading"
                            class="btn btn-primary btn-lg"
                            type="submit"
                          >
                            <span
                              class="spinner-border spinner-border-sm me-2"
                              role="status"
                              v-if="loading"
                            ></span>
                            Submit
                          </b-button>
                        </div>
                        </form>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <indexfooter></indexfooter>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      title: "Cancel Appointment",
      text: "Home",
      text1: "Cancel Appointment",
      patientId: null,
      appointmentId: null,
      appointmentDetails: [],
      reason: "",
      otp: "",
      loading: false,
      otpSent: false,
      error: null,
      showError: false,
      countDown: 30,
      email: "",
      phone: "",
      maskedEmail: ""
    };
  },
  computed: {
    status() {
      if (this.appointmentDetails.appointmentstatus === "E") {
        return {
          text: "Confirmed",
          class: "badge rounded-pill bg-success-light"
        };
      } else if (this.appointmentDetails.appointmentstatus === "C") {
        return {
          text: "Cancelled",
          class: "badge rounded-pill bg-danger-light"
        };
      } else {
        return { text: "Unknown", class: "status-unknown" };
      }
    }
  },
  methods: {
    formatedTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    formatedDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    confirmCancellation() {
      this.sendOTP(this.email, this.phone);
    },
    confirmCancellation() {
      this.sendOTP(this.email, this.phone);
    },
    async fetchAppointmentDetails() {
      try {
        const response = await axios.get(
          `patients/${this.patientId}/appointments/${this.appointmentId}?detailed=True`
        );
        this.appointmentDetails = response.data;
        this.email = this.appointmentDetails.email;
        this.phone = this.appointmentDetails.contactnumbers;
        this.maskedEmail = this.getMaskedEmail(this.appointmentDetails.email);
      } catch (error) {
        console.error("Error fetching appointment details:", error);
      }
    },
    async sendOTP(email, phone) {
      try {
        const apiUrl = "otp";
        const requestData = {
          mobileNumber: phone,
          email: email
        };
        const response = await axios.post(apiUrl, requestData);
        if (response.status === 200) {
          this.otpSent = true;
          this.countDown = 60;
          this.countDownTimer();
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
      }
    },
    async completeCancellation() {
      try {
        const cancelApiUrl = `/patients/${this.appointmentDetails.appointmentby}/appointments/${this.appointmentDetails.slno}`;
        const response = await axios.put(cancelApiUrl, {
          cancelreason: this.reason
        });
        if (response.status === 200) {
          this.fetchAppointmentDetails();
          this.reason = "";
          this.otp = "";
          this.otpSent = false;
        } else {
          this.error = "Failed to cancel the appointment. Please try again.";
          this.showError = true;
        }
      } catch (error) {
        console.error("Error cancelling appointment:", error);
        this.error = "Error cancelling appointment. Please try again.";
        this.showError = true;
      }
    },
    getMaskedEmail(email) {
      const [username, domain] = email.split("@");
      return `${username[0]}***@${domain[0]}***`;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async resendCode() {
      try {
        const apiUrl = "otp";
        const requestData = {
          mobileNumber: this.phone,
          email: this.email
        };
        const response = await axios.post(apiUrl, requestData);
        this.showError = false;
        this.countDown = 60;
        this.countDownTimer();
        this.otp = "";
      } catch (error) {
        console.error("Error sending otp:", error);
      }
    },
    async submitOtp() {
      this.loading = true;
      this.showError = false;
      try {
        const requestData = {
          mobileNumber: this.phone,
          email: this.email,
          otpCode: this.otp
        };
        const response = await axios.post("otp/validator", requestData);
        if (response.status === 200) {
          this.completeCancellation();
        } else {
          this.showError = true;
          this.loading = false;
          this.error =
            "The entered OTP is incorrect. Please enter the OTP sent to you.";
        }
      } catch (error) {
        this.showError = true;
        this.loading = false;
        this.error = "The entered OTP is incorrect";
      }
    },
    goBack() {
      this.otpSent = false;
      this.reason = "";
      this.otp = "";
    }
  },
  created() {
    this.countDownTimer();
    this.patientId = this.$route.query.patid;
    this.appointmentId = this.$route.query.aptid;
  },
  beforeMount() {
    this.fetchAppointmentDetails();
  }
};
</script>

<style scoped>
.appointment-cancellation-section {
  padding: 60px 0;
}
.cancellation-content {
  margin-top: 20px;
  background: #f8f9fa;
  padding: 30px;
  border-radius: 5px;
}
.appointment-details {
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detail-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
}
.label {
  flex: 1;
  text-align: left;
}
.value {
  flex: 1;
  text-align: right;
}
.form-group {
  margin-bottom: 20px;
}
.account-content {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 5px;
  position: relative;
}
.reset-btn .btn {
  margin: 0 10px;
}
.reset-btn {
  display: flex;
  justify-content: center;
}
</style>
