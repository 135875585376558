<script setup>
</script>
<template>
  <div class="main-wrapper">
    <!-- <clinic-patient-search></clinic-patient-search> -->
    <div class="account-content">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-12 col-lg-6" style="margin-top: 50px;">
          <div class="login-header" style="display: flex; justify-content: center;">
            <h3>Patient Registration</h3>
          </div>
          <div class="paitent-appointment">
            <div>
              <b-alert v-model="showError" dismissible variant="danger">{{ error }}</b-alert>
            </div>
            <div class="forms-block row">
              <div class="mb-3 form-focus">
                 <vue-tel-input
                    class="form-control floating"
                    mode='national'
                    @on-input="updateInput"
                    @focus="onFocus"
                    @blur="phoneTouched = true"
                    :defaultCountry='defaultCountry'
                    :autoFormat="autoFormat"
                    :only-countries="onlyCountries"
                    :inputOptions="inputOptions"
                    :dropdownOptions="dropdownOptions"
                    :validCharactersOnly='true'
                    ref="phonenumber"
                                                    
                    />
                 <label class="focus-label" :class="{ 'has-input': isFocused || patient.phone}">
                Phone number*
                  </label>
              </div>
              <div class="mb-3 form-focus">
                <b-form-input
                  type="email"
                  v-model="patient.email"
                  class="form-control floating"
                  @blur="emailTouched = true"
                  required
                  maxlength="45"
                />
                <label
                  class="focus-label"
                  :class="{ 'has-input': patient.email }"
                  style="margin-left: 10px;"
                >Email</label>
              </div>
              <div class="mb-3 form-focus">
                <b-form-input
                  type="text"
                  v-model="age"
                  class="form-control floating"
                  required
                  maxlength="4"
                />
                <label
                  class="focus-label"
                  :class="{ 'has-input': age }"
                  style="margin-left: 10px;"
                >Age</label>
              </div>
              <div class="mb-3 form-focus">
                <b-form-input
                  type="text"
                  v-model="patient.firstName"
                  class="form-control floating"
                  required
                  :class="{ 'is-invalid': firstnameTouched && !validatefirstname() }"
                  @blur="firstnameTouched = true"
                  maxlength="45"
                />
                <label
                  class="focus-label"
                  :class="{ 'has-input': patient.firstName }"
                  style="margin-left: 10px;"
                >
                  First
                  Name*
                </label>
              </div>
              <div class="mb-3 form-focus">
                <b-form-input
                  type="text"
                  v-model="patient.lastName"
                  class="form-control floating"
                  @blur="lastnameTouched = true"
                  maxlength="45"
                />
                <label
                  class="focus-label"
                  :class="{ 'has-input': patient.lastName }"
                  style="margin-left: 10px;"
                >
                  Last
                  Name
                </label>
              </div>
            </div>
            <div class="forms-block" style="display: flex;">
              <div class="form-check">
                <input
                  v-model="patient.gender"
                  type="radio"
                  id="M"
                  value="M"
                  class="form-check-input"
                  required
                />
                <label for="male" class="form-check-label">Male</label>
              </div>
              <div class="form-check" style="margin-left: 10px;">
                <input
                  v-model="patient.gender"
                  type="radio"
                  id="F"
                  value="F"
                  class="form-check-input"
                  required
                />
                <label for="female" class="form-check-label">Female</label>
              </div>
              <div class="form-check" style="margin-left: 10px;">
                <input 
                  v-model="patient.gender" 
                  type="radio" id="O" 
                  value="O" 
                  class="form-check-input"                
                  required />
              <label for="female" class="form-check-label">Other</label>
              </div>
            </div>
            <div
              class="forms-block mb-0"
              style="display: flex; justify-content: center; align-items: center;"
            >
              <div class="booking-btn">
                <b-button
                  type="button"
                  class="btn btn-primary prime-btnc justify-content-center align-items-center"
                  @click="submitForm"
                >
                  Next
                  <i class="feather-arrow-right-circle"></i>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Paitent -->
  <!-- /Page Content -->
  <cursor></cursor>
</template>
<script>
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
import { ModelListSelect } from "vue-search-select";
export default {
  data() {
    return {
      doctorid: null,
      doctorDetails: [],
      patientid: null,
      errors: [],
      showRegisterForm: false,
      patient: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        gender: ""
      },
      reason: "",
      age: "",
      firstnameTouched: false,
      lastnameTouched: false,
      emailTouched: false,
      phoneTouched: false,
      error: null,
      showError: false,
      isFocused: true,
      autoFormat: false,
      defaultCountry: 'IN',
      onlyCountries: [
          'IN', 'US', 'GB', 'CA', 'AU', 
          'AE', 'BH', 'KW', 'OM', 'QA', 'SA', 
          'SG', 'MY', 'NZ'
        ],
      dropdownOptions: {
        showFlags: true,
        showDialCodeInList:true,
        // showCountryName: true,
        showDialCodeInSelection: true,
         
      },
      inputOptions: {
        maxlength: "10",
        placeholder: "",
        required: true,
        autofocus: true,
        // styleClasses: 'form-control floating'

      },
      isValidPhone: '',
    };
  },
  methods: {
    updateInput(phone, phoneObject){
      this.patient.phone = phoneObject.number // E.164 number convention
      this.isValidPhone = phoneObject.valid 
    },
    validatefirstname() {
      const firstnameRegex = /^[a-zA-Z\s]{1,25}$/;
      return firstnameRegex.test(this.patient.firstName);
    },
    validatelastname() {
      const lastnameRegex = /^[a-zA-Z]{1,25}$/;
      return lastnameRegex.test(this.patient.lastName);
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.patient.email);
    },
    validatePhoneNumber() {
      const phoneNumberRegex = /^\d{10,12}$/;
      return phoneNumberRegex.test(this.patient.phone);
    },
    async submitForm() {
      this.showError = false;
      let invalids = [];
      if (!this.patient.firstName || !this.validatefirstname()) {
        invalids.push("first name");
      }
      if (this.patient.lastName && !this.validatelastname()) {
        invalids.push("last name");
      }
      if (!this.patient.gender) {
        invalids.push("gender");
      }
      if (this.patient.email && !this.validateEmail()) {
        invalids.push("valid email");
      }
      if (!this.isValidPhone) {
        invalids.push("valid phone");
      }
      if (invalids.length > 0) {
        this.error = "Please enter " + invalids.join(", ");
        this.showError = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      try {
        const patientDetails = {
          firstname: this.patient.firstName,
          lastname: this.patient.lastName,
          contactnumbers: this.patient.phone,
          email: this.patient.email,
          gender: this.patient.gender,
          age: this.age,
          listyn: true
        };
        const postResponse = await axios.post("/patients", patientDetails);
        if (postResponse.status === 200) {
          this.$store.dispatch("updateAppointmentDraft", {
            patientid: postResponse.data,
            doctorid: this.doctorid,
            doctorImage: this.doctorDetails.doctorImage
          });
        }
        this.$router.push({
          path: "/bookingclinic/" + this.doctorid,
          query: { from: "ClinicDashboard" }
        });
        // console.log("Doctor ID god mode:", this.doctorid);
      } catch (error) {
        this.working = false;
        // console.log(error);
        if (error.name === "UsernameExistsException") {
          this.info = "Your email is already registered.";
        } //else console.log("Unexpected error occured", error);
      }
    }
  },
  mounted(){
    const doctorinfo = this.$store.getters.getclinicDetails;
    this.doctorDetails = doctorinfo;
    this.doctorid = doctorinfo.doctorId;
  }
};
</script>
<style scoped>
.is-invalid {
  border-color: red;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.form-focus .form-control {
    height: 40px;
    padding: 21px 12px 6px;
}
.vue-tel-input input {
  border: none;
  box-shadow: none;
  outline: none; /* Remove any box-shadow if applicable */
}

.vue-tel-input {
  display: flex; /* Ensures the elements inside are aligned in a row */
  align-items: center; /* Vertically centers the input field with the flag */
}

.vue-tel-input input {
  border: none;
  box-shadow: none;
  outline: none;
  flex-grow: 1; /* Ensures the input takes the remaining space */
  padding-left: 2px; /* Adds some spacing between the flag and the input */
  height: 40px;
   /* Adjust height if needed to match other form elements */
}

.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #bbb !important;
}
:deep .vti__dropdown-list.below {
    top: 33px;
    z-index: 12;
}
</style>