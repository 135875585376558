<template>
  <div
    class="modal fade custom-modal custom-medicalrecord-modal"
    id="view_medical_records_modal"
    tabindex="-1"
    aria-labelledby="viewMedicalRecordsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="viewMedicalRecordsModalLabel">Medical Records</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="card card-table mb-0">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <!-- <th>Name</th> -->
                      <th>Date</th>
                      <th>Report Name</th>
                      <th>Ordered By</th>
                      <th>Attachment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loading">
                      <td colspan="6" class="text-center">Loading...</td>
                    </tr>
                    <tr v-else-if="medical_records.length === 0">
                      <td colspan="6" class="text-center">No Records Found</td>
                    </tr>
                    <tr v-else v-for="item in medical_records" :key="item.id">
                      <td>{{ item.reportid }}</td>
                      <!-- <td>{{ item.patientname }}</td> -->
                      <td>{{ formatDate(item.testdate) }}</td>
                      <td>{{ item.testname }}</td>
                      <td>{{ item.orderby_doctor }}</td>
                      <td>
                        <a
                          href="javascript:void(0);"
                          :title="item.testname"
                          class="btn btn-primary btn-sm me-2"
                          @click="downloadRecord(item.reportid, item.patientid, item.testname)"
                        >
                          <i class="fa fa-download"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          :title="item.testname"
                          class="btn btn-primary btn-sm"
                          @click="viewRecord(item.reportid, item.patientid, item.testname)"
                        >
                          <i class="fa fa-eye"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    patientId: {
      type: Number,
      required: true
    },
    appointmentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      medical_records: [],
      loading: true
    };
  },
  computed: {
    patientAndAppointment() {
      return { patientId: this.patientId, appointmentId: this.appointmentId };
    }
  },
  watch: {
    patientAndAppointment: {
      handler(newVal, oldVal) {
        if (newVal.patientId && newVal.appointmentId) {
          this.fetchMedicalRecords();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    fetchMedicalRecords() {
      this.loading = true;
      setTimeout(() => {
        axios
          .get(`/patients/${this.patientId}/med-report/${this.appointmentId}`)
          .then(response => {
            this.medical_records = response.data;
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              console.warn("Medical records not found, setting to empty.");
              this.medical_records = [];
            } else {
              console.error("Error fetching medical records:", error);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }, 500);
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    },
    viewRecord(reportId, patientId, reportName) {
      axios({
        method: "get",
        url: `/patients/${patientId}/report/${reportId}/download`,
        responseType: "blob"
      })
        .then(response => {
          const fileExtension = this.getFileExtension(reportId);

          if (fileExtension === 'pdf') {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

            // Open the PDF in a new tab
            const newWindow = window.open();
            newWindow.document.write(`<html><head><title>${reportName}</title></head><body style="margin:0"><embed width="100%" height="100%" src="${fileURL}" type="application/pdf" /></body></html>`);
          } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'image/jpeg' }));
            window.open(fileURL, "_blank");
          } else {
            // If it's not a PDF or image, fall back to downloading the file
            this.downloadRecord(reportId, patientId, reportName);
          }
        })
        .catch(error => {
          console.error("Error viewing medical record:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to view the record!"
          });
        });
    },
    downloadRecord(reportId, patientId, reportName) {
      axios({
        method: "get",
        url: `/patients/${patientId}/report/${reportId}/download`,
        responseType: "blob"
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const fileExtension = this.getFileExtension(reportId);
          const fullReportName = `${reportName}.${fileExtension}`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fullReportName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error("Error downloading medical record:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to download the record!"
          });
        });
    },
    getFileExtension(reportId) {
      const record = this.medical_records.find(
        item => item.reportid === reportId
      );
      if (record && record.testfile_path) {
        return record.testfile_path.split(".").pop();
      }
      return "txt";
    }
  },
};
</script>
