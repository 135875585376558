<template>
  <!-- Testimonial Section -->
  <section class="testimonial-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="testimonial-slider slick">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in IndexTestimonial" :key="item.id">
                <div class="testimonial-grid">
                  <div class="testimonial-info">
                    <div class="testimonial-img">
                      <img
                        :src="require(`@/assets/img/clients/${item.Image}`)"
                        class="img-fluid"
                        alt="Image"
                      />
                    </div>
                    <div class="testimonial-content">
                      <div class="section-header section-inner-header testimonial-header">
                        <h5>Testimonials</h5>
                        <h2>What Our Client Says</h2>
                      </div>
                      <div class="testimonial-details">
                        <p>
                          {{ item.Details }}
                        </p>
                        <h6>
                          <span>{{ item.Name }}</span> {{ item.Location }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Testimonial Section -->
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
const testimonials = [
    {
        "id": 1,
        "Image": "client-01.jpg",
        "Name": "Dr. Jayanth Shenoy",
        "Location": "Mangalore",
        "Details": "After I started using YourDoc, my patients appointment management has become seamless. It is vey easy to keep track of all my patients and provide them better service."
    },
    {
        "id": 2,
        "Image": "client-02.jpg",
        "Name": "Dr. Anand Rao",
        "Location": "Udupi",
        "Details": "Since using YourDoc my appointments are pretty much full. I have been able to increase my patient base and they have found it very easy to book my time."
    },
    {
        "id": 3,
        "Image": "client-03.jpg",
        "Name": "Dr Anita Vas",
        "Location": "Kundapur",
        "Details": "I am able to view my patients past history on the go using my mobile phone. This enables me to provide better heathcare to my patients."
    },
];
export default {
  data() {
    return {
      IndexTestimonial: testimonials,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
