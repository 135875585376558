<template>
  <div class="login-body">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="login-content-info">
        <div class="container">
          <!-- Login Email Otp -->
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="account-content">
                <div class="login-shapes">
                  <div class="shape-img-left">
                    <img src="@/assets/img/shape-01.png" alt="shape-image" />
                  </div>
                  <div class="shape-img-right">
                    <img src="@/assets/img/shape-02.png" alt="shape-image" />
                  </div>
                </div>
                <div class="account-info">
                  <div class="login-back">
                    <router-link :to="`/patient-details/${doctorId}`">
                      <i class="fa-solid fa-arrow-left-long"></i> {{ $t('back') }}
                    </router-link>
                  </div>
                  <div class="login-verify-img">
                    <img src="@/assets/img/icons/email-icon.svg" alt="email-icon" />
                  </div>
                  <div class="login-title">
                    <h3>{{ $t('email_otp_verification') }}</h3>
                    <p class="mb-0">
                      {{ $t('otp_sent_to_email') }} <strong>{{ this.maskemail }}</strong>
                    </p>
                  </div>
                  <p v-if="error">
                    <b-alert v-model="showError" dismissible variant="danger">
                      {{ error }}
                    </b-alert>
                  </p>
                  <form
                    method="get"
                    class="digit-group login-form-control"
                    data-group-name="digits"
                    data-autosubmit="false"
                    autocomplete="off"
                    action="booking"
                    @submit.prevent="submitOtp"
                  >
                    <div class="otp-boxe">
                      <div class="mb-3e">
                        <v-otp-input length="4" v-model="otp" :loading="loading" autofocus>
                        </v-otp-input>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="otp-info">
                        <div class="otp-code">
                          <p>{{ $t('didnt_receive_otp') }}</p>
                          <v-btn @click="resendCode" :disabled="countDown > 0" variant="outlined">{{ $t('resend_code') }}</v-btn>
                        </div>
                        <div class="otp-sec">
                          <p><i class="feather-clock"></i> {{ countDown }} {{ $t('secs') }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="reset-btn">
                      <b-button :disabled="otp.length < 4 || loading" class="btn btn-primary w-100 btn-lg login-btn" type="submit">
                        <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                        {{ $t('submit') }}
                      </b-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- /Login Email Otp -->
        </div>
      </div>
      <!-- /Page Content -->
      <cursor></cursor>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      countDown: 30,
      error: null,
      showError: false,
      otp: '',
      loading: false,
      doctorId: this.$store.getters.getAppointmentDraft.doctorid,
      email: this.$store.getters.getEmail,
      mobileNumber: this.$store.getters.getMobileNumber,
      maskemail: this.getMaskedEmail(this.$store.getters.getEmail,this.$store.getters.getMobileNumber)
    } 
  },
  methods: {
    countDownTimer () {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
    },
    async resendCode() {
      try {
        const apiUrl = "otp";
        const requestData = {
          mobileNumber: this.mobileNumber,
          email: this.email,
        };
        const response = await axios.post(apiUrl, requestData);
        this.showError = false;
        this.countDown=30;
        this.countDownTimer();
        this.otp = "";
      } catch (error) {
        console.error("Error sending otp:", error);
      }
    },
    async submitOtp() {
      this.loading = true;
      this.showError = false;
      try {
  
        const requestData = {
          mobileNumber: this.mobileNumber,
          email: this.email,
          otpCode: this.otp,
        };
        const response = await axios.post("otp/validator", requestData);
        if (response.status === 200) {
          
          const patientDetails = {
            contactnumbers: this.$store.getters.getMobileNumber,
            email: this.$store.getters.getEmail !== undefined ? this.$store.getters.getEmail : '',
            firstname: this.$store.getters.getfirstName,
            lastname: this.$store.getters.getlastName !== undefined ? this.$store.getters.getlastName : '',
            gender: this.$store.getters.getGender,
            listyn: true,
            area: "",
            areacode: "",
            address: "",
            age: "",
            dateof_birth: null,
            medicalhistory: "",
            geolocation: null,
            parentid: null,
            idp_id: "",
          };


          const postResponse = await axios.post("/patients", patientDetails);

          if (postResponse.status === 200) {
            this.$store.dispatch("updateAppointmentDraft", {
              patientid: postResponse.data,
              appointmentby: postResponse.data,
            });
            this.$router.push("/booking-confirm");
          }
        } else {
          this.showError = true;
          this.loading = false;
          console.log('error confirming otp');
          this.error = "The entered OTP is incorrect. Please enter the OTP sent to you.";
        }
        
      } catch (error) {
        this.showError = true;
        console.error("Error validating OTP:", error);
        this.loading = false;
        this.error = "The entered OTP is incorrect";
      }
    },
    getMaskedEmail(email, phone) {
      // // Split the email into username and domain
      // const [username, domain] = email.split("@");

      // // Display only the first letter of the username and domain
      // return `${username[0]}***@${domain[0]}***`;
       const lastFourDigits = phone.slice(-3);
       return `+91****${lastFourDigits}`;
    }
  },
  created () {
      this.countDownTimer()
  },
};
</script>
