import { createI18n } from 'vue-i18n';
import english from '@/assets/json/Languages/language_en.json';
import kannada from '@/assets/json/Languages/language_ka.json';
import hindi from '@/assets/json/Languages/language_hi.json';
import malayalam from '@/assets/json/Languages/language_ma.json';

const messages = {
  en: english,
  kn: kannada,
  hi: hindi,
  ma: malayalam,
};

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

export default i18n;