<template>
  <div class="chat-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>
      <div class="content-chat top-space-chat">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div class="chat-window">
                <!-- Chat Left -->
                <chatleft
                  :chats="chats"
                  @chatSelected="updateCurrentChat"
                  @refreshChats="fetchChats"
                  :is-mobile-view="isMobileView"
                  :current-description="currentDescription"
                ></chatleft>
                <!-- /Chat Left -->

                <!-- Chat Right (Only visible on desktop) -->
                <chatright v-if="!isMobileView" :description="currentDescription"></chatright>
                <!-- /Chat Right -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Voice Call Modal -->
    <div class="modal fade call-modal" id="voice_call">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <!-- Outgoing Call -->
            <div class="call-box incoming-box">
              <div class="call-wrapper">
                <div class="call-inner">
                  <div class="call-user">
                    <img
                      alt="User Image"
                      src="@/assets/img/doctors/doctor-thumb-02.jpg"
                      class="call-avatar"
                    />
                    <h4>Darren Elder</h4>
                    <span>Connecting...</span>
                  </div>
                  <div class="call-items">
                    <a
                      href="javascript:void(0);"
                      class="btn call-item call-end"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ><i class="material-icons">call_end</i></a
                    >
                    <a
                      href="javascript:;"
                      class="btn call-item call-start"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ><i class="material-icons">call</i></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Outgoing Call -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Voice Call Modal -->

    <!-- Video Call Modal -->
    <div class="modal fade call-modal" id="video_call">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <!-- Incoming Call -->
            <div class="call-box incoming-box">
              <div class="call-wrapper">
                <div class="call-inner">
                  <div class="call-user">
                    <img
                      class="call-avatar"
                      src="@/assets/img/doctors/doctor-thumb-02.jpg"
                      alt="User Image"
                    />
                    <h4>Darren Elder</h4>
                    <span>Calling ...</span>
                  </div>
                  <div class="call-items">
                    <a
                      href="javascript:void(0);"
                      class="btn call-item call-end"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ><i class="material-icons">call_end</i></a
                    >
                    <a
                      href="javascript:;"
                      class="btn call-item call-start"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ><i class="material-icons">videocam</i></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- /Incoming Call -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import ChatLeft from '@/components/ChatLeft.vue';
// import ChatRight from '@/components/ChatRight.vue';
// import LayoutHeader from '@/components/LayoutHeader.vue'; // Assuming you have a LayoutHeader component

export default {
  // components: { ChatLeft, ChatRight, LayoutHeader },
  data() {
    return {
      chats: [],
      currentDescription: '',
      isMobileView: false
    };
  },
  async created() {
    await this.fetchChats();
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobileView);
  },
  methods: {
    async fetchChats() {
      const patientId = sessionStorage.getItem('userdbid');
      try {
        const response = await axios.get(`patients/${patientId}/notifications`);
        this.chats = response.data;
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    },
    updateCurrentChat(chat) {
      this.currentDescription = chat;
     // console.log("chat is main chat is ", chat);
    },
    checkMobileView() {
      this.isMobileView = window.innerWidth <= 992;
    }
  }
}
</script>

<style scoped>
.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.top-space-chat {
  margin-top: 20px;
}
.chat-window {
  display: flex;
  height: 100%;
}
.chat-right-container {
  flex: 1;
}
</style>
