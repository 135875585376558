<template>
  <div class="main-wrapper">
    <layoutheader :class="['header-fixed bg-white']" />
    <pharmacyslider></pharmacyslider>

    <indextwoclinic></indextwoclinic>

    <indextwopopular></indextwopopular>

    <indextwofeature></indextwofeature>

    <indexfooter></indexfooter>
  </div>
</template>
