<template>
    <!-- Footer -->
    <footer class="footer footer-one">
        <div class="footer-top aos" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <div class="footer-widget footer-about">
                            <div class="footer-logo">
                                <img src="@/assets/img/logo.png" alt="logo">
                            </div>
                            <div class="footer-about-content">
                                <p>YourDoc is a platform which intents to help people like you and me by making healthcare easy to access.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">For Patients</h2>
                            <ul>
                                <li><router-link to="/search">Search for Doctors</router-link></li>
                                <li><router-link to="/login">Login</router-link></li>
                                <li><router-link to="/register">Register</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">For Doctors</h2>
                            <ul>
                                <li><router-link to="/search?speciality=general">Find a Doctor</router-link></li>
                                <li><router-link to="/search?speciality=cardiologist">Find a Cardiologist</router-link></li>
                                <li><router-link to="/search?speciality=pediatrist">Find a Pediatrist</router-link></li>
                                <li><router-link to="/search?speciality=dentist">Find a Dentist</router-link></li>
                                <li><router-link to="/search?speciality=physiotherapist">Find a Physiotherapist</router-link></li>
                                <li><router-link to="/search?speciality=chiropractor">Find a Chiropractor</router-link></li>
                                <li><router-link to="/search?speciality=psychologist">Find a Psychologist</router-link></li>
                                <li><router-link to="/search?speciality=dietitian">Find a Dietitian</router-link></li>
                                <li><router-link to="/search?speciality=osteopath">Find a Osteopath</router-link></li>
                                <li><router-link to="/search?speciality=dermatologist">Find a Dermatologist</router-link></li>
                                <li><router-link to="/search?speciality=radiologist">Find a Radiologist</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-5">
                        <div class="footer-widget footer-contact">
                            <h2 class="footer-title">Contact Us</h2>
                            <div class="footer-contact-info">
                                <div class="footer-address">
                                <p><i class="feather-map-pin"></i> Mangaluru, India</p>
                                </div>
                                <div class="footer-address">
                                <p><i class="feather-phone-call"></i> +91 824 6637145</p>
                                </div>
                                <div class="footer-address mb-0">
                                <p><i class="feather-mail"></i> info@yourdoc.in</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-7">
                        <div class="footer-widget">
                            <h2 class="footer-title">Join Our Newsletter</h2>
                            <div class="subscribe-form">
                                <form action="javascript:;">
                                    <input type="email" class="form-control" placeholder="Enter Email">
                                    <b-button type="submit" class="btn">Submit</b-button>
                                </form>
                            </div>
                            <div class="social-icon">
                                <ul>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fab fa-facebook"></i> </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fab fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fab fa-x-twitter"></i> </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/thegiglabs" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <!-- Copyright -->
                <div class="copyright">
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div class="copyright-text">
                                <p class="mb-0"> Copyright © {{ new Date().getFullYear() }} <a href="https://themeforest.net/user/dreamguys/portfolio"
                                        target="_blank">Dreamguys.</a> All Rights Reserved</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">

                            <!-- Copyright Menu -->
                            <div class="copyright-menu">
                                <ul class="policy-menu">
                                    <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                    <li><router-link to="/terms-condition">Terms and Conditions</router-link></li>
                                </ul>
                            </div>
                            <!-- /Copyright Menu -->

                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
    </footer>
    <!-- /Footer -->
</template>

<script>
</script>