<template>
  <div class="main-wrapper">
    <layoutheader />

    <indexbanner />


    <indexwork />

    <indexapp />

    <cursor />
    <indexfooter />

    <scrolltotop />
  </div>
</template>
<script>
export default {
  name: "index",
};
</script>
