<template>
  <div class="stickysidebar">
    <div class="filter-contents">
      <div class="filter-header">
        <h4 class="filter-title">
          <router-link to="#collapsezero" data-bs-toggle="collapse">{{ $t('filter') }}</router-link>
        </h4>
      </div>
      <div class="filter-details collapse show" id="collapsezero">
        <!-- Filter Grid -->
        <!--div class="filter-grid">
          <h4>
            <router-link to="#collapseone" data-bs-toggle="collapse">{{ $t('gender') }}</router-link>
          </h4>
          <div id="collapseone" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedGender"
                      value="M"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    {{ $t('male') }}
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedGender"
                      value="F"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    {{ $t('female') }}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <!--div class="filter-grid">
          <h4>
            <router-link to="#collapsetwo" data-bs-toggle="collapse">{{ $t('availability') }}</router-link>
          </h4>
          <div id="collapsetwo" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedAvailability"
                      value="today"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    {{ $t('available_today') }}
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedAvailability"
                      value="In7days"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    {{ $t('available_next_7_days') }}
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedAvailability"
                      value="In14days"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    {{ $t('available_next_14_days') }}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div-->
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <div class="filter-grid">
          <h4>
            <router-link to="#collapsefour" data-bs-toggle="collapse">{{ $t('speciality') }}</router-link>
          </h4>
          <div id="collapsefour" class="collapse show">
            <div class="filter-collapse" style="max-height: 200px; overflow-y: auto;">
              <ul>
                <li v-for="speciality in specialities" :key="speciality.id">
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedSpeciality"
                      :value="speciality.Title"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    {{ speciality.Title }}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <!--div class="filter-grid">
          <h4>
            <router-link to="#collapseeight" data-bs-toggle="collapse">{{ $t('languages') }}</router-link>
          </h4>
          <div id="collapseeight" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedLanguage"
                      value="English"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    English
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedLanguage"
                      value="Hindi"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    हिंदी
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedLanguage"
                      value="Kannada"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    ಕನ್ನಡ
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedLanguage"
                      value="Tulu"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    ತುಳು
                  </label>
                </li>

                <li>
                  <label class="custom_check d-inline-flex">
                    <input
                      type="checkbox"
                      v-model="selectedLanguage"
                      value="Malayalam"
                      @change="applyFilters()"
                    />
                    <span class="checkmark"></span>
                    മലയാളം
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div-->
        <!-- /Filter Grid -->

        <!-- Filter Btn -->
        <div class="filter-btn apply-btn">
          <div class="row">
            <!--div class="col-6">
              <a @click="applyFilters()" class="btn btn-primary">Apply</a>
            </div-->
            <div class="col-15">
              <a @click="resetFilters()" class="btn btn-outline-primary">{{ $t('reset') }}</a>
            </div>
          </div>
        </div>
        <!-- /Filter Btn -->
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import specialitiesData from "@/assets/json/specialization_settings_doctor.json";

export default {
  data() {
    return {
      value3: [0, 50],
      merge: 10,
      format: {
        prefix: "$",
        suffix: "0"
      },
      selectedGender: [],
      selectedSpeciality: [],
      selectedLanguage: [],
      selectedAvailability: [],
      specialities: specialitiesData // Load the specialities from the JSON file
    };
  },
  methods: {
    applyFilters() {
      this.$emit("apply-filters", {
        selectedGender: this.selectedGender,
        selectedSpeciality: this.selectedSpeciality,
        selectedLanguage: this.selectedLanguage,
        selectedAvailability: this.selectedAvailability
      });
    },
    resetFilters() {
      this.selectedGender = [];
      this.selectedSpeciality = [];
      this.selectedLanguage = [];
      this.selectedAvailability = [];
      this.$emit("reset-filters");
    }
  },
  components: {
    Slider
  }
};
</script>

<style>
.stickysidebar {
  position: sticky;
  top: 0;
}
.slider-connect {
  background: var(--slider-connect-bg, #0e82fd) !important;
  cursor: pointer;
}
.slider-tooltip {
  background: var(--slider-tooltip-bg, #0b2540) !important;
  border: 1px solid var(--slider-tooltip-bg, #0b2540) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.roundskin .slider-tooltip {
  background: var(--slider-tooltip-bg, #0e82fd) !important;
  border: 1px solid var(--slider-tooltip-bg, #0e82fd) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.slider-handle {
  background-color: #fff !important;
  border: 2px solid #0e82fd !important;
}
.slider-tooltip.slider-tooltip-top {
  display: none !important;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
