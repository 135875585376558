<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Account Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img
                      src="@/assets/img/login-banner.png"
                      class="img-fluid"
                      alt="Login Banner"
                    />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right" v-if="!confirmemail">
                    <div class="login-header">
                      <h3>Forgot Password?</h3>
                      <p class="small text-muted">
                        Enter your registered
                        <span v-if="isDoctor">email</span>
                        <span v-else>mobile number</span> 
                        to get a password reset link
                      </p>
                    </div>
                    <p v-if="error">
                      {{ error }}
                    </p>
                    <!-- Forgot Password Form -->
                    <div class="mb-3 form-focus" v-if="isDoctor">
                      <input type="email" class="form-control floating" v-model="email" @keyup.enter="submitForm" />
                      <label class="focus-label" :class="{ 'has-input': email }">Email</label>
                    </div>

                    <!-- Mobile Number Input Field -->
                    <div class="mb-3 form-focus" v-else>
                      <vue-tel-input
                        class="form-control floating"
                        mode='national'
                        @on-input="updateInput"
                        @focus="onFocus"
                        @blur="phoneTouched = true"
                        :defaultCountry='defaultCountry'
                        :autoFormat="autoFormat"
                        :only-countries="onlyCountries"
                        :inputOptions="inputOptions"
                        :dropdownOptions="dropdownOptions"
                        ref="phonenumber"
                        @keyup.enter="submitForm"
                      />
                      <label class="focus-label" :class="{ 'has-input': isFocused || phone }">Mobile Number (WhatsApp only)*</label>
                    </div>
                      <div class="text-end">
                        <router-link class="forgot-link" :to="getLoginRoute()">
                          Remember your password?
                        </router-link>
                      </div>
                      <b-button
                        class="btn btn-primary w-100 btn-lg login-btn"
                        type="submit"
                        @click="submitForm"
                        >Reset Password</b-button
                      >
                    
                    <!-- /Forgot Password Form -->
                  </div>
                  <div class="col-md-12 col-lg-6 login-right" v-else-if="!resetsuccess">
                    <div class="login-header">
                      <h3>Reset Password</h3>
                      <p class="small text-muted">
                        A verification code is sent by
                        <span v-if="isDoctor">email</span>
                        <span v-else>
                          <i class="fa-brands fa-whatsapp" style="color: #25D366;"></i>&nbsp;WhatsApp
                        </span>
                        to {{ maskedphone }}
                      </p>
                    </div>
                    <p v-if="error">
                      {{ error }}
                    </p>
                    <!-- Change Password Form -->
                    <form @submit.prevent="handleConfirmResetPassword">
                      <div class="mb-3 form-focus">
                        <input type="text" class="form-control floating" v-model="confrm_code" required />
                        <label class="focus-label" :class="{ 'has-input': confrm_code }">Verification Code</label>
                      </div>
                      <div class="mb-3 form-focus position-relative">
                        <input :type="showNewPassword ? 'text' : 'password'" class="form-control floating" v-model="newpwd" required />
                        <label class="focus-label" :class="{ 'has-input': newpwd }">New Password</label>
                        <button type="button" class="password-toggle" @click="toggleNewPasswordVisibility">
                          <i :class="showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="mb-3 form-focus">
                        <input :type="showPassword ? 'text' : 'password'" class="form-control floating" v-model="confirmpwd" required/>
                        <label class="focus-label" :class="{ 'has-input': confirmpwd }">Confirm Password</label>
                        <button type="button" class="password-toggle" @click="togglePasswordVisibility">
                          <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="text-end">
                        <router-link class="forgot-link" :to="{ path: '', query: { type: $route.query.type} }" @click="confirmemail=false"
                          >Change <span v-if="isDoctor">email</span><span v-else>phone</span>?</router-link
                        >
                      </div>
                      <div class="submit-section">
                        <b-button type="submit" class="btn btn-primary submit-btn">
                          Save Changes
                        </b-button>
                      </div>
                    </form>
                    <!-- /Change Password Form -->
                  </div>
                  <div class="col-md-12 col-lg-6 login-right" v-else>
                    <div class="login-header">
                      <h3>Password reset successfully</h3>
                      <p class="small text-muted">
                        Please login with your
                        <span v-if="isDoctor">email</span>
                        <span v-else>phone number</span> 
                        and the new password
                      </p>
                    </div>
                    <b-button
                      class="btn btn-primary w-100 btn-lg login-btn"
                      type="button" @click="gologin"
                    >Login
                    </b-button>
                  </div>
                </div>
              </div>
              <!-- /Account Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
  <indexfooter></indexfooter>
</template>
<script>
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import axios from "axios";
export default {
  data() {
    return {
      error: null,
      user_pool: this.$store.getters.getUserPool,
      phone: '',
      email: '',
      confirmemail: false,
      maskedphone: '',
      confrm_code: '',
      newpwd: '',
      confirmpwd: '',
      successPass: false,
      successPass1: false,
      resetsuccess: false,
      showPassword: false,
      showNewPassword: false,
      autoFormat: false,
      defaultCountry: 'IN',
      onlyCountries: [
          'IN', 'US', 'GB', 'CA', 'AU', 
          'AE', 'BH', 'KW', 'OM', 'QA', 'SA', 
          'SG', 'MY', 'NZ'
        ],
      dropdownOptions: {
        showFlags: true,
        showDialCodeInList:true,
        // showCountryName: true,
        showDialCodeInSelection: true,
         
      },
      inputOptions: {
        maxlength: "10",
        placeholder: "",
        required: true,
        autofocus: true,
        // styleClasses: 'form-control floating'

      },
      isFocused: true,
    };
  },
  methods: {
    getLoginRoute() {
      return this.user_pool === "PATIENT_USERPOOL" ? "/login" : "/doctor-login";
    },
    updateInput(phone, phoneObject){
      this.phone = phoneObject.number // E.164 number convention
    },
    min8: function(value) {
      if (value && value.length >= 8) {
        return true;
      } else {
        this.error = 'Password should have atleast 8 characters.';
        return false;
      }
    },
    matchingPasswords: function() {
      if (this.newpwd === this.confirmpwd) {
        return true;
      } else {
        this.error = 'Passwords do not match.';
        return false;
      }
    },
    async submitForm() {
     
      this.error = null; 

      if (this.isDoctor && !this.email) {
        this.error = 'Please enter a valid email address.';
        return;
      }
      
      if (!this.isDoctor && !this.phone) {
        this.error = 'Please enter a valid phone number.';
        return;
      }

      try {
        const username = this.isDoctor ? this.email : this.phone;
        const output = await resetPassword({ username });
        this.handleResetPasswordNextSteps(output);
      } catch (error) {
        console.log(error); 
        this.error = error.message; 
      }
    },
    handleResetPasswordNextSteps(output) {
      const { nextStep } = output;
      switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          
          this.maskedphone = codeDeliveryDetails.destination;
          this.confirmemail = true;

          // Collect the confirmation code from the user and pass to confirmResetPassword.
          break;
        case 'DONE':
          console.log('Successfully reset password.');
          this.error = 'Successfully reset password.';
          break;
      }
    },
    async handleConfirmResetPassword() {
      if (!this.min8(this.newpwd)) return;
      if (!this.matchingPasswords()) return;
      try {
        const res = await confirmResetPassword({ 
          username: this.isDoctor ? this.email : this.phone,
          confirmationCode: this.confrm_code,
          newPassword: this.newpwd });
          this.resetsuccess = true; 
        //this.$router.push("/login");
    } catch (error) {
      console.log(error);
        this.error = error.message;
    }
    },
    gologin() {
       //console.log("userpool", this.user_pool)
      if(this.user_pool==="PATIENT_USERPOOL"){ 
        this.$router.push("/login")
      }else{
        this.$router.push("/doctor-login")
      }
    },
    toggleNewPasswordVisibility() {
      this.showNewPassword = !this.showNewPassword;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  },
  computed: {
    isDoctor() {
      return this.$route.query.type === 'doctor';
    },
    passRule: function() {
      if (this.newpwd === '') {
        // field is empty
        this.successPass = false;
        return '';
      } else if (this.min8(this.newpwd) === true) {
        // password ok
        this.successPass = true;
        return 'mdi-check';
      } else {
        // password wrong
        this.successPass = false;
        return 'mdi-close';
      }
    },
    passRule1: function() {
      if (this.confirmpwd === '') {
        // field is empty
        this.successPass1 = false;
        return '';
      } else if (this.min8(this.confirmpwd) === true && this.matchingPasswords() === true) {
        // password ok
        this.successPass1 = true;
        return 'mdi-check';
      } else {
        // password wrong
        this.successPass1 = false;
        return 'mdi-close';
      }
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.password-toggle .fas {
  font-size: 1.2em;
  color: #6c757d;
}

.custom-wrapper .vti__selection {
    padding-top: 8px;
}

.vue-tel-input.form-control {
    padding-top: 24px;
}


</style>
