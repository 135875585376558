<template>
  <!-- Add Time Slot Modal -->
  <div class="modal fade custom-modal" id="add_time_slot">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Time Slots</h5>
          <!-- <div>
            <h3>Debug Output</h3>
            <pre>{{ forms }}</pre>
          </div>-->
          <b-button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></b-button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Clinic Name</label>
              <model-list-select
                v-if="clinicData.length > 0 && forms.length > 0"
                :list="clinicData"
                v-model="forms[0].clinicid"
                option-value="clinicid"
                option-text="clinicname"
                placeholder="Select item"
                style="z-index: 1000;"
              />
            </div>

            <div class="hours-info">
              <div class="row hours-cont">
                <div class="col-12 col-md-10">
                  <div class="row">
                    <!-- <div class="col-12 col-md-6">
                      <div class="mb-3">
                      <label class="mb-2">Start Time</label>
                      <input type="time" v-model="forms.starttime" class="form-control">
                    </div>
                    </div>-->
                    <!-- <div class="col-12 col-md-6">
                      <div class="mb-3">
                      <label class="mb-2">End Time</label>
                      <input type="time" v-model="forms.endtime" class="form-control">
                    </div>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>

            <div class="row hours-cont" v-for="(slot, index) in forms" :key="index">
              <div class="col-12 col-md-10">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <label class="mb-2">Start Time</label>

                      <input
                        type="time"
                        :class="{ 'is-invalid': isInvalidTime(index, 'start') }"
                        :step="timeStep"
                        v-model="slot.starttime"
                        class="form-control"
                        @input="onInput(slot, 'starttime')"
                      />

                      <div
                        v-if="isInvalidTime(index, 'start')"
                        class="invalid-feedback"
                      >{{ isInvalidTime(index, 'start') === 'invalid' ? 'Invalid time' : 'Overlapping time' }}</div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <label class="mb-2">End Time</label>
                      <input
                        type="time"
                        v-model="slot.endtime"
                        :class="{ 'is-invalid': isInvalidTime(index, 'end') }"
                        :step="timeStep"
                        class="form-control"
                        @input="onInput(slot, 'endtime')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2 col-lg-1">
                <a
                  href="javascript:void(0);"
                  class="btn btn-danger trash"
                  @click="deleteslot(index)"
                >
                  <i class="far fa-trash-alt"></i>
                </a>
              </div>
            </div>

            <div class="add-more mb-3">
              <a href="javascript:void(0);" class="add-hours" @click="addslot">
                <i class="fa fa-plus-circle"></i> Add More
              </a>
            </div>
            <div class="submit-section text-center">
              <b-button
                type="submit"
                class="btn btn-primary submit-btn"
                @click.prevent="saveForm"
              >Save</b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Time Slot Modal -->
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { ModelListSelect } from "vue-search-select";
export default {
  props: {
    selectedWeekday: String,
    doctorId: String
  },
  data() {
    return {
      forms: [],
      clinicData: []
    };
  },
  components: {
    ModelListSelect
  },
  computed: {
    timeStep() {
      return "300";
    }
  },
  methods: {
    onInput(slot, type) {
      if (slot[type]) {
        let time = slot[type].split(":");
        let hours = parseInt(time[0]);
        let minutes = parseInt(time[1]);

        minutes = Math.round(minutes / 5) * 5;
        if (minutes >= 60) {
          hours++;
          minutes = 0;
        }

        let formattedHours = hours < 10 ? "0" + hours : hours.toString();
        let formattedMinutes =
          minutes < 10 ? "0" + minutes : minutes.toString();

        slot[type] = formattedHours + ":" + formattedMinutes;
      }
    },
    addslot() {
      const selectedClinicId =
        this.clinicData.length > 0 ? this.clinicData[0].clinicid : "";

      const newSlot = {
        clinicid: selectedClinicId,
        starttime: "",
        endtime: "",
        doctorid: this.doctorId,
        weekday: this.selectedWeekday
      };

      this.forms.push(newSlot);
    },
    deleteslot(index) {
      this.forms.splice(index, 1);
    },
    async fetchClinicData() {
      try {
        const response = await axios.get("/doctors/clinichospital");
        this.clinicData = response.data;
        //console.log("API Response:", response.data);
      } catch (error) {
        console.error("API Error:", error);
      }
    },
    saveForm() {
      const hasInvalidSlots = this.forms.some((slot, index) => {
        return (
          this.isInvalidTime(index, "start") || this.isInvalidTime(index, "end")
        );
      });

      if (hasInvalidSlots) {
        this.showSweetAlert(
          "error",
          "Invalid or overlapping time slots",
          "Please correct them before saving."
        );
        return;
      }
      const postData = this.forms;

      //console.log("POST Data:", postData);

      axios
        .post("/doctors/scheduletiming", postData)
        .then(response => {
          this.showSweetAlert("success", "Slots saved successfully");
          this.$emit("slots-saved");
          const closeButton = document.querySelector("#add_time_slot .btn-close");
          if (closeButton) {
            closeButton.click();
          }
         
          console.log("API Response:", response.data);
        })
        .catch(error => {
          this.showSweetAlert("error", "Error saving data", error.message);
        });
    },
    showSweetAlert(icon, title, message) {
      Swal.fire({
        icon: icon,
        title: title,
        text: message
      });
    },
    isInvalidTime(index, type) {
      const currentSlot = this.forms[index];

      const startTime = new Date(`1970-01-01T${currentSlot.starttime}`);
      const endTime = new Date(`1970-01-01T${currentSlot.endtime}`);

      if (type === "start" && startTime >= endTime) {
        return "invalid";
      }

      for (let i = 0; i < this.forms.length; i++) {
        if (i !== index) {
          const slot = this.forms[i];

          const slotStartTime = new Date(`1970-01-01T${slot.starttime}`);
          const slotEndTime = new Date(`1970-01-01T${slot.endtime}`);

          if (
            this.checkOverlap(startTime, endTime, slotStartTime, slotEndTime)
          ) {
            return "overlap";
          }
        }
      }

      return false;
    },
    checkOverlap(start1, end1, start2, end2) {
      return start1 < end2 && end1 > start2;
    },
    closeModal() {
      this.forms = [];
    }
  },
  watch: {
    selectedWeekday: function(newWeekday) {
      if (this.forms.length > 0) {
        this.forms[0].weekday = newWeekday;
      }
    },
    forms: {
      handler: function(newForms) {
        if (newForms.length > 0) {
          const newClinicId = newForms[0].clinicid;
          newForms.forEach(form => {
            form.clinicid = newClinicId;
          });
        }
      },
      deep: true
    }
  },
  mounted() {
    this.fetchClinicData();
  }
};
</script>

<style scoped>
.hours-cont {
  align-items: center;
}
</style>
