<template>
  <div role="tabpanel" id="doc_locations" class="tab-pane fade">
    <!-- Location List -->
    <div class="location-list" v-if="doctor">
      <div class="row">
        <!-- Clinic Content -->
        <div class="col-md-6">
          <div class="clinic-content">
            <h4 class="clinic-name">
              <a href="javascript:;">{{doctor.clincorhospname}}</a>
            </h4>
            <p class="doc-speciality">{{doctor.qualifications}}</p>

            <div class="clinic-details mb-0">
              <h5 class="clinic-direction">
                <i class="fas fa-map-marker-alt"></i>
                {{ doctor.address}}, {{ doctor.area }}
                {{doctor.areacode}}, India
                <br />
                <a
                  target="_blank"
                  :href="'https://www.google.com/maps/search/?api=1&ll=12.9141%2C74.8560&query_place_id=&z=13&query='+doctor.clincorhospname"
                >Get Directions</a>
              </h5>
            </div>
          </div>
        </div>
        <!-- /Clinic Content -->

        <!-- Clinic Timing -->
        <div class="col-md-4">
          <div class="clinic-timing">
            <template v-for="day in ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']">
              <div v-if="hasTimings(fullDayName(day))" :key="day">
                <p class="timings">
                  <span class="day bold">{{ day }}</span> -
                  <span class="time">{{ getTimings(fullDayName(day)) }}</span>
                </p>
              </div>
            </template>
          </div>
        </div>
        <!-- /Clinic Timing -->
      </div>
    </div>
    <!-- /Location List -->

    <vue-easy-lightbox
      :visible="visible"
      :index="index"
      :imgs="
        galleryImages.map((image) => ({
          src: require(`@/assets/img/features/${image.src}`),
        }))"
      @hide="visible = false"
      @on-prev="handlePrev"
      @on-next="handleNext"
    ></vue-easy-lightbox>
  </div>
</template>
<script>
import VueEasyLightbox from "vue-easy-lightbox";
import axios from "axios";
export default {
  props: {
    doctor: {
      type: Object,
      default: {}
    },
    doctortimmings:{
      type: Array,
    }
  },
  components: {
    VueEasyLightbox
  },
  data() {
    return {
      visible: false,
      index: 0,
      galleryImages: [
        {
          src: "feature-01.jpg"
        },
        {
          src: "feature-02.jpg"
        },
        {
          src: "feature-03.jpg"
        },
        {
          src: "feature-04.jpg"
        }
      ]
    };
  },
  methods: {
    fullDayName(abbreviatedDay) {
      const daysMap = {
        Mon: "Monday",
        Tue: "Tuesday",
        Wed: "Wednesday",
        Thu: "Thursday",
        Fri: "Friday",
        Sat: "Saturday",
        Sun: "Sunday"
      };
      return daysMap[abbreviatedDay];
    },
    hasTimings(day) {
      return this.doctortimmings.some(timing => timing.weekday === day);
    },
    getTimings(day) {
      const timings = this.doctortimmings.filter(
        timing => timing.weekday === day
      );
      return timings
        .map(timing => {
          const startTime = this.convertToAMPM(timing.starttime);
          const endTime = this.convertToAMPM(timing.endtime);
          return `${startTime} - ${endTime}`;
        })
        .join(", ");
    },
    convertToAMPM(timeString) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const ampm = hours >= 12 ? "PM" : "AM";
      const hour12 = hours % 12 || 12;
      return `${hour12}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    },
    show(index) {
      this.index = index;
      this.visible = true;
    },
    handlePrev(oldIndex, newIndex) {
      // console.log("when prev btn click or user swipe right ----");
      // console.log("oldIndex of imgs:", oldIndex);
      // console.log("newIndex of imgs:", newIndex);
    },
    handleNext(oldIndex, newIndex) {
      // console.log("when next btn click or user swipe left ----");
      // console.log("oldIndex of imgs:", oldIndex);
      // console.log("newIndex of imgs:", newIndex);
    },
    
  },
  
};
</script>
<style scoped>
.day {
  font-size: 12px;
}

.time {
  font-size: 12px;
}

.day.bold {
  font-weight: bold;
}
</style>