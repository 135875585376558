<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>

    <!-- Page Content -->
    <div class="doctor-content">
      <div class="container">
        <!-- Paitent -->
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <!-- Conditionally show phone number or OTP screen -->
            <div class="paitent-appointment">
              <div class="back-link">
                <router-link to @click="$router.go(-1)">
                  <i class="fa-solid fa-arrow-left-long"></i>
                  {{ $t('back') }}
                </router-link>
              </div>
              <div>
                <div class="paitent-header">
                  <h4 class="paitent-title">{{formTitle}}</h4>
                </div>
                <div>
                    <div class="text" v-if="info">
                      Mobile Number not confirmed yet.<br>
                      <router-link class="forgot-link" to="" @click="resendOTP">Resend confirmation code</router-link>
                    </div>
                  <b-alert v-model="showError" dismissible variant="danger">{{ error }}</b-alert>
                </div>
                <div v-if="userExists" class="user-exists-message">
                    <p class="text-warning">This phone number is already registered. Please log in.</p>
                </div>
                <div class="forms-block">
                  <label class="form-group-title">
                    {{ $t('mobile_number') }}
                    <span class="text-danger" style="font-size: 16px;">*</span>
                  </label>
                  <div class="col-md-6">
                    <vue-tel-input
                      class="form-control floating"
                      mode='national'
                      @on-input="updateInput"
                      @blur="phoneTouched = true"
                      :defaultCountry='defaultCountry'
                      :autoFormat="autoFormat"
                      :only-countries="onlyCountries"
                      :inputOptions="inputOptions"
                      :dropdownOptions="dropdownOptions"
                      @enter="handleNextClick"
                      :validCharactersOnly='true'
                      ref="phonenumber"
                    />
                    <div
                      v-if="phoneTouched && !validatePhoneNumber()"
                      class="error-message"
                    >{{ $t('invalid_phone') }}</div>
                  </div>
                </div>
                <div v-if="userExists" class="forms-block">
                  <label class="form-group-title">
                    {{ $t('password') }}
                    <span class="text-danger" style="font-size: 16px;">*</span>
                  </label>
                  <div class="col-md-6 position-relative">
                    <input :type="showPassword ? 'text' : 'password'" class="form-control floating" v-model="pwd" />
                    <!-- <label class="focus-label" :class="{ 'has-input': pwd }">Password</label> -->
                    <button type="button" class="password-toggle" @click="togglePasswordVisibility">
                      <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                    </button>
                  </div>

                  <!-- Add Forgot Password link here -->
                   <div class="d-flex justify-content-between align-items-center mt-2">
                      <router-link
                        class="forgot-link"
                        :to="{ name: 'forgot-password' }"
                        @click="handleForgotPasswordClick"
                      >
                        Forgot Password?
                      </router-link>
                    </div>
                </div>
                <div class="forms-block mb-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <!-- Show Login Button if the user exists -->
                    <div v-if="userExists" class="d-flex align-items-center">
                      <b-button
                        type="button"
                        class="btn btn-primary prime-btn mr-2"
                        @click="handleLogin"
                      >
                        Login
                        <i class="feather-log-in"></i>
                      </b-button>

                      <span class="mx-2">OR</span>

                      <!-- Continue without login option (next to Login button) -->
                      <b-button
                        variant="link"
                        @click="handleContinueWithoutLogin"
                        class="simple-continue-btn"
                      >Continue Booking without login
                      </b-button>
                    </div>

                    <!-- Show Next Button if the user does not exist -->
                    <b-button
                      v-if="!userExists"
                      type="button"
                      class="btn btn-primary prime-btn"
                      @click="handleNextClick"
                    >
                      Next
                      <i class="feather-arrow-right-circle"></i>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12">
            <!-- Booking summary remains the same -->
            <div class="booking-header">
              <h4 class="booking-title">{{ $t('booking_summary') }}</h4>
            </div>
            <div class="card booking-card">
              <div class="card-body booking-card-body">
                <div class="booking-doctor-details">
                  <div class="booking-doctor-left">
                    <div class="booking-doctor-img">
                      <img
                        :src="doctorImage || require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                        :alt="doctor.doctorname"
                      />
                    </div>
                    <div class="booking-doctor-info">
                      <h4>Dr. {{ doctor.doctorname }}</h4>
                      <p>{{ doctor.qualifications }}, {{ doctor.specialization }}</p>
                      <p>{{ doctor.area }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card booking-card">
              <div class="card-body booking-card-body booking-list-body">
                <div class="booking-list">
                  <div class="booking-date-list">
                    <ul>
                      <li>{{ $t('booking_date') }}: <span>{{ formatDate(appointmentday) }}</span></li>
                      <li>{{ $t('booking_time') }}: <span>{{ formatTime(appointmenttime) }}</span></li>
                      <li>
                        {{ $t('clinic') }}:
                        <a target="_blank" :href="'https://www.google.com/maps/search/?api=1&ll=12.9141%2C74.8560&query_place_id=&z=13&query=' + clinicname">
                          <span> {{ clinicname }}</span>
                          <!-- <i class="feather-map-pin"></i> -->
                        </a>
                      </li>
                      <li>
                        <div class="booking-doctor-right">
                          <p><router-link :to="{ path: '/booking/' + doctorId }">{{ $t('edit') }}</router-link></p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- <div class="booking-doctor-right">
                    <p><router-link :to="{ path: '/booking/' + doctorId }">{{ $t('edit') }}</router-link></p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Paitent -->
      </div>
    </div>
    <!-- /Page Content -->
   <indexfooter />
  </div>
  
</template>

<script>
import axios from "axios";
import { signIn, resendSignUpCode } from 'aws-amplify/auth';
import { PATIENT_POOL} from '@/AWSResources';
import { Amplify } from 'aws-amplify';
import moment from "moment";

export default {
  data() {
    return {
      doctorId: null,
      patientid: null,
      userExists: false,
      showPassword: false,
      pwd:'',
      doctor: {},
      appointmentday: this.$store.getters.getAppointmentDraft.day,
      appointmenttime: this.$store.getters.getAppointmentDraft.time,
      clinicname: this.$store.getters.getAppointmentDraft.clinicname,
      doctorImage: this.$store.getters.getAppointmentDraft.doctorImage,
      phone: "",
      phoneTouched: false,
      error: null,
      showError: false,
      autoFormat: false,
      defaultCountry: 'IN',
      onlyCountries: ['IN', 'US', 'GB', 'CA', 'AU', 'AE', 'BH', 'KW', 'OM', 'QA', 'SA', 'SG', 'MY', 'NZ'],
      dropdownOptions: {
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        searchBoxPlaceholder: ''
      },
      inputOptions: {
        maxlength: "10",
        placeholder: "",
        required: true,
      },
      isValidPhone: '',
      countDown: 30,
      loading: false,
      info: '',

    };
  },
  computed: {
    formTitle() {
      return this.userExists
        ? "Login to Your Account"  // If user exists, show login title
        : "Enter Your Mobile Number";  // If user doesn't exist, show phone entry title
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const parsedDate = moment(date, "YYYY-MM-DD");
      if (!parsedDate.isValid()) {
        console.error("Invalid date format:", date);
        return "";
      }
      return parsedDate.toDate().toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
      });
    },
    formatTime(time) {
      if (!time) return "";
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    handleForgotPasswordClick(){
      this.$store.dispatch('updateUserPool', "PATIENT_USERPOOL");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    updateInput(phone, phoneObject) {
      this.phone = phoneObject.number;
      this.isValidPhone = phoneObject.valid;
    },
    async handleNextClick() {
      if (!this.validatePhoneNumber()) {
        this.error = "Please enter a valid phone number.";
        this.showError = true;
        return;
      }else{
       try {

          const response = await axios.get(`/patients/search-patient?phone=${encodeURIComponent(this.phone)}`);

          const data = response.data;

          if (data.status) {
            this.userExists = true;
          } else {
            const patientDetails = {
              phone: this.phone,
            };
            this.$store.dispatch("updatePatientDetails", patientDetails);
            this.$router.push(`/patient-details/${this.doctorId}`);
          }
        } catch (error) {
          console.error("Error checking patient existence:", error);
          this.error = "Something went wrong. Please try again.";
          this.showError = true;
        }
      }
    },
    async handleLogin() {
      // this.working = true; 
      try {
        const { isSignedIn, nextStep } = await signIn({
          username: this.phone, 
          password: this.pwd
        });

        if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
          this.working = false;
          this.info = "You have not yet confirmed your phone.";
          return; 
        }

        if (isSignedIn) {
          this.$router.push(`/patient-details/${this.doctorId}`);
        }
      } catch (error) {
    
        // this.working = false;
        // this.showDismissibleAlert = true;

        if (error.name === "UserNotFoundException") {
          this.error = "Invalid username or password.";
          this.showError = true;
        } else if (error.name === "UserNotConfirmedException") {
          this.error = "You have not yet confirmed your mobilenumber.";
        } else {
          console.error("Error signing in:", error);
          if(error.message === "password is required to signIn"){
            this.error = "Password is required to SignIn"
            this.showError = true;
          }else{
          this.error = error.message;
          this.showError = true; 
          }// General error message
        }
      }
    },
    validatePhoneNumber() {
      const phoneNumberRegex = /^\+\d{10,15}$/;
      return phoneNumberRegex.test(this.phone)  && this.isValidPhone;
    },
     async fetchDoctorData() {
      const apiUrl = `doctors/${this.doctorId}`;

      try {
        let docDetails = sessionStorage.getItem(
          `doctorDetails${this.doctorId}`
        );
        if (!docDetails) {
          const response = await axios.get(apiUrl);
          docDetails = response.data;
          sessionStorage.setItem(
            `doctorDetails${this.doctorId}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctor = docDetails;
      } catch (error) {
        console.error("Error fetching doctor data:", error);
      }
    },
    async resendOTP() {
      try {
        if (!this.phone) {
          this.error = "Please enter your mobile number";
          this.showDismissibleAlert = true;
          return;
        }
        resendSignUpCode({
          username: this.phone
        });
        localStorage.setItem("confirmotpphone", this.phone);
        localStorage.setItem("signupphone", this.phone);
        this.$router.push("/signup-otp");
      } catch (e) {
        console.log(e);
      }
    },
    setUserpool() {
      const poolConfig = PATIENT_POOL;
      Amplify.configure(poolConfig);
    },
    handleContinueWithoutLogin(){
      const patientDetails = {
          phone: this.phone,
      };
      this.$store.dispatch("updatePatientDetails", patientDetails);
       this.$router.push(`/patient-details/${this.doctorId}`);
    }
  },
  created() {
     this.doctorId = this.$route.params.doctorId;
     this.fetchDoctorData();
     this.setUserpool();
  }
};
</script>



<style scoped>

.position-relative {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.password-toggle .fas {
  font-size: 1.2em;
  color: #6c757d;
}

.reset-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.reset-btn .btn {
  width: 150px; 
  max-width: 100%;
  padding: 10px;
  font-size: 16px;
  text-align: center; 
}

.simple-continue-btn {
  color: #007bff; /* Blue text */
  font-size: 16px;
  padding: 8px 12px;
  text-decoration: underline; /* Underline to keep it simple */
  background: none; /* No background color */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
}

.simple-continue-btn:hover {
  color: #0056b3; /* Slightly darker blue on hover */
  text-decoration: none; /* Remove underline on hover for subtle effect */
}
@media (max-width: 767px) {
  .login-options {
    display: block; 
    text-align: center; 
  }

  .prime-btn {
    width: 100%; 
    margin-bottom: 10px;
  }

  .simple-continue-btn {
    display: block; 
    width: 100%;
    text-align: center; 
    margin: 0 auto;
    font-size: 14px;
    color: #007bff;
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
  }

  .simple-continue-btn:hover {
    color: #0056b3;
    text-decoration: none;
  }
}

</style>
