<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <div class="content">
      <div class="container">
        <div class="row justify-content-center" style="margin-top:60px">
          <div class="col-md-7 col-lg-8 col-xl-9">
            <h4 class="card-title-main">ADD DOCTOR</h4>
            <form id="demo-form" @submit.prevent="onSubmit">
              <div class="card-body pt-0">
                <!-- Tab Menu -->
                <nav class="user-tabs mb-4">
                  <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" @click="setActiveTab('personal')">Personal
                        Information</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" @click="setActiveTab('social')">Social media</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="card" v-if="activeTab === 'personal'">
                <div class="card-body">
                  <p v-if="errorvalidation">
                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
                      {{ errorvalidation
                      }}
                    </b-alert>
                  </p>
                  <div>
                    <b-alert v-model="showError" dismissible variant="danger">{{ error }}</b-alert>
                  </div>
                  <div>
                    <b-alert v-model="showSuccessAlert" variant="success" dismissible>{{ successMessage }}</b-alert>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Name
                        </label>
                        <!-- king -->
                        <div class="input-group">
                          <input type="text" ref="doctorNameInput" class="form-control"
                            v-model="doctorDetails.doctorname" maxlength="100" @input="validateName"
                            :class="{ 'is-invalid': nameError }">
                          <div class="invalid-feedback" v-if="nameError">{{ nameError }}</div>
                        </div>
                        <!-- <input type="text" ref="inputBox" /> -->

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Email ID
                        </label>
                        <!-- king -->
                        <div class="input-group">
                          <input type="text" ref="doctorNameEmail" class="form-control" v-model="doctorDetails.email"
                            maxlength="100" @input="validateEmail" :class="{ 'is-invalid': emailError }">
                          <div class="invalid-feedback" v-if="emailError">{{ emailError }}</div>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Age
                        </label>
                        <input type="text" ref="doctorNameAge" class="form-control" v-model="doctorDetails.age"
                          maxlength="2" @input="validateAge" :class="{ 'is-invalid': ageError }" />
                        <div class="invalid-feedback" v-if="ageError">{{ ageError }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Gender</label>
                        <select class="form-control" v-model="doctorDetails.gender">
                          <option v-for="gender in genders" :key="gender.value" :value="gender.value">
                            {{ gender.label }}
                          </option>
                          <div class="invalid-feedback" v-if="genderError">{{ genderError }}</div>
                        </select>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Languages Spoken</label>
                        <VueMultiselect v-model="doctorDetails.languagesspoken" :options="languageOptions" :max="5"
                          placeholder="Select languages" label="label" track-by="value" multiple>
                          <template slot="tag" slot-scope="{ option }">
                            <span class="custom__tag">{{ option.label }}</span>
                          </template>
                        </VueMultiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Registrations</h4>
                    <div class="registrations-info">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">
                            Registration Info
                          </label>
                          <input type="text" ref="doctorNameReg" class="form-control"
                            v-model="doctorDetails.registration_info" maxlength="50"
                            :class="{ 'is-invalid': regError }" />
                          <div class="invalid-feedback" v-if="regError">{{ regError }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Clinic Info</h4>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">

                          <label class="mb-2">Clinic Name</label>
                          <input type="text" ref="doctorNameClinic" class="form-control"
                            v-model="doctorDetails.clincorhospname" maxlength="100"
                            :class="{ 'is-invalid': clinicError }" />
                          <div class="invalid-feedback" v-if="clinicError">{{ clinicError }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Contact Details</h4>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">

                          <label class="mb-2">Address</label>
                          <textarea ref="doctorNameAddress" class="form-control" v-model="doctorDetails.address"
                            maxlength="500" :class="{ 'is-invalid': addressError }"></textarea>
                          <div class="invalid-feedback" v-if="addressError">{{ addressError }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Area</label>
                          <select v-model="doctorDetails.area" class="form-control"
                            :class="{ 'is-invalid': areaError }">
                            <option value="" disabled>Select an area</option>
                            <option v-for="areaOption in areaOptions" :key="areaOption.value" :value="areaOption.value">
                              {{ areaOption.label }}
                            </option>
                          </select>
                          <div class="invalid-feedback" v-if="areaError">{{ areaError }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Area Code</label>
                          <input type="text" ref="doctorNameAreaCode" class="form-control"
                            v-model="doctorDetails.areacode" maxlength="10" @input="validateAreacode"
                            :class="{ 'is-invalid': areacodeError }" />
                          <div class="invalid-feedback" v-if="areacodeError">{{ areacodeError }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Contact Numbers</label>
                          <input type="text" ref="doctorNameNum" class="form-control"
                            v-model="doctorDetails.contactnumbers" maxlength="50" @input="validateContactNumber"
                            :class="{ 'is-invalid': contactError }" />
                          <div class="invalid-feedback" v-if="contactError">{{ contactError }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card services-card">
                  <div class="card-body">
                    <h4 class="card-title">Services and Specialization</h4>

                    <div class="mb-0">
                      <label class="mb-2">Specialization</label>
                      <select class="form-control" v-model="doctorDetails.specialization"
                        :class="{ 'is-invalid': specError }">
                        <option v-for="speciality in specialities" :key="speciality.id" :value="speciality.Title">{{
                          speciality.Title }}
                        </option>
                      </select>
                      <div class="invalid-feedback" v-if="specError">{{ contactError }}</div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Education & Experience</h4>
                    <div class="education-info">
                      <div class="row education-cont">
                        <div class="col-12 col-md-10 col-lg-11">
                          <div class="row">
                            <div class="col-12">
                              <div class="mb-3">
                                <label class="mb-2">Qualifications</label>
                                <input type="text" ref="doctorNameQual" class="form-control"
                                  v-model="doctorDetails.qualifications" maxlength="100"
                                  :class="{ 'is-invalid': qualError }" />
                                <div class="invalid-feedback" v-if="qualError">{{ qualError }}</div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="mb-3">
                                <label class="mb-2">Experience</label>
                                <input type="text" ref="doctorNameExp" class="form-control"
                                  v-model="doctorDetails.experience" maxlength="2" @input="validateExperience"
                                  :class="{ 'is-invalid': experienceError }" />
                                <div class="invalid-feedback" v-if="experienceError">{{ experienceError }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">About Me</h4>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-0">
                          <label class="mb-2">Biography</label>
                          <textarea ref="doctorNameDesp" class="form-control" v-model="doctorDetails.description"
                            rows="10" maxlength="1000" :class="{ 'is-invalid': desError }"></textarea>
                          <div class="invalid-feedback" v-if="desError">{{ desError }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="submit-section submit-btn-bottom">
                  <b-button type="submit" class="btn btn-secondary">Save Changes</b-button>
                </div> -->
                <div class="center-container-btn" style="margin-bottom:30px">
                  <div id="recaptcha-container" style="margin-bottom: 20px"</div>
                  <button type="submit" class="btn btn-primary submit-btn">Add doctor</button>
                </div>
              </div>
              <div class="card" v-if="activeTab === 'social'">
                <div class="card-body">
                  <p v-if="errorvalidation">
                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
                      {{ errorvalidation
                      }}
                    </b-alert>
                  </p>
                  <div>
                    <b-alert v-model="showError" dismissible variant="danger">{{ error }}</b-alert>
                  </div>
                  <div>
                    <b-alert v-model="showSuccessAlert" variant="success" dismissible>{{ successMessage }}</b-alert>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Facebook URL
                        </label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="doctorDetails.facebookurl" maxlength="100">
                        </div>
                        </input>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Twitter URL
                        </label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="doctorDetails.twitterurl" maxlength="100">
                        </div>
                        </input>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Instagram URL
                        </label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="doctorDetails.instagramurl" maxlength="100">
                        </div>
                        </input>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Pinterest URL</label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="doctorDetails.pintresturl" maxlength="100">
                        </div>
                        </input>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Linkedin URL</label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="doctorDetails.linkedinurl" maxlength="100">
                        </div>
                        </input>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Youtube URL</label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="doctorDetails.youtubeurl" maxlength="100">
                        </div>
                        </input>
                      </div>
                    </div>
                    <!-- <div class="center-container-btn" style="margin-top: 10px; margin-bottom:20px">
                      <button @click="setActiveTab('personal')" class="btn btn-primary submit-btn">Add doctor</button>
                    </div> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div </div>
    </div>
    <doctorfooter></doctorfooter>
  </div>
</template>
<script>
import axios from "axios";
import IndexBanner from "@/assets/json/specialization_settings_doctor.json";
import VueMultiselect from "vue-multiselect";
import defaultImageUrl from "@/assets/img/doctors/doctor-thumb-01.jpg";
import areaOptions from "@/assets/json/doctor/doctorarea.json";
import { signUp } from 'aws-amplify/auth';
import { signIn, resendSignUpCode } from 'aws-amplify/auth';
import { DOCTOR_POOL} from '@/AWSResources';
import { Amplify } from 'aws-amplify';

export default {
  components: { VueMultiselect },
  data() {
    return {
      title: "Add new doctor",
      text: "Home",
      text1: "Add new doctor",
      sorting: ["Select", "Male", "Female"],
      file: null,
      filePreview: null,
      isFileOver: false,
      doctorDetails: {
        doctorname: "",
        gender: "",
        idp_id: "",
        age: "",
        languagesspoken: [],
        specialization: "",
        qualifications: "",
        experience: "",
        contactnumbers: "",
        email: "",
        area: null,
        areacode: "",
        address: "",
        registration_info: "",
        clincorhospname: "",
        description: "",
        weburl: "empty",
        appointment_duration: 0
      },
      areaOptions: areaOptions,
      languageOptions: [
        { label: "English", value: "English" },
        { label: "Hindi", value: "Hindi" },
        { label: "Kannada", value: "Kannada" },
        { label: "Tulu", value: "Tulu" },
        { label: "Malayalam", value: "Malayalam" },
        { label: "Konkani", value: "Konkani" },
        { label: "Urdu", value: "Urdu" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu", value: "Telugu" },
      ],
      genders: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
        { label: 'Other', value: 'O' }
      ],
      showDismissibleAlert: false,
      errorvalidation: "",
      specialities: IndexBanner,
      nameError: "",
      emailError: "",
      email: '',
      phone_number: null,
      given_name: '',
      family_name: '',
      password: null,
      ageError: "",
      areacodeError: "",
      areaError: "",
      contactError: "",
      genderError: "",
      experienceError: "",
      regError: "",
      specError: "",
      qualError: "",
      conError: "",
      desError: "",
      imageFile: null,
      error: null,
      showError: false,
      showSuccessAlert: false,
      successMessage: "",
      profileImageUrl: "",
      defaultImageUrl: defaultImageUrl,
      showShareIcons: false,
      activeTab: 'personal',
      recaptchaSiteKey: '6LebK30qAAAAAC_R7S23jQE4zG4YQ_NqPJbDNGIK',
      recaptchaWidgetId: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof grecaptcha !== 'undefined') {
        this.renderRecaptcha();
      } else {
        console.error('reCAPTCHA not loaded. Retrying...');
        setTimeout(this.renderRecaptcha, 500);
      }
    });
  },
  methods: {
    renderRecaptcha() {
      if (typeof grecaptcha !== 'undefined') {
        this.recaptchaWidgetId = grecaptcha.render('recaptcha-container', {
          sitekey: this.recaptchaSiteKey,
        });
      } else {
        console.error('reCAPTCHA not loaded. Check the script.');
      }
    },
    onSubmit() {
      if (typeof grecaptcha === 'undefined') {
        alert('reCAPTCHA is not loaded yet. Please try again.');
        return;
      }
      const recaptchaResponse = grecaptcha.getResponse(this.recaptchaWidgetId);
      if (recaptchaResponse === '') {
        this.showDismissibleAlert = true;
        this.errorvalidation =
        "Please complete the reCAPTCHA verification";
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      this.showDismissibleAlert = false;
      this.updateDoctorDetails();
    },
    setUserpool() {
      const poolConfig = DOCTOR_POOL;
      Amplify.configure(poolConfig);
    },
    setAlertTimeout() {
      setTimeout(() => {
        this.showDismissibleAlert = false;
        this.showSuccessAlert = false;
      }, 5000);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    validateName() {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(this.doctorDetails.doctorname)) {
        this.nameError = "Name should only contain alphabetical characters.";
        return false;
      } else {
        this.nameError = "";
        return true;
      }
    },
    validateEmail() {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.doctorDetails.email)) {
        this.emailError = "Please enter a valid email address.";
        return false;
      } else {
        this.emailError = "";
        return true;
      }
    },
    validateAge() {
      const regex = /^\d*$/;
      if (!regex.test(this.doctorDetails.age)) {
        this.ageError = "Age should contain only numbers.";
        return false;
      } else {
        this.ageError = "";
        return true;
      }
    },
    validateAreacode() {
      const regex = /^\d*$/;
      if (!regex.test(this.doctorDetails.areacode)) {
        this.areacodeError = "Areacode should contain only numbers.";
        return false;
      } else {
        this.areacodeError = "";
        return true;
      }
    },
    validateContactNumber() {
      const regex = /^\+\d{12}$/;
      if (!regex.test(this.doctorDetails.contactnumbers)) {
        this.contactError = "ContactNumber should contain only numbers.";
        return false;
      } else {
        this.contactError = "";
        return true;
      }
    },
    validateExperience() {
      const regex = /^\d*$/;
      if (!regex.test(this.doctorDetails.experience)) {
        this.experienceError = "Experience should contain only numbers.";
        return false;
      } else {
        this.experienceError = "";
        return true;
      }
    },
    async updateDoctorDetails() {
      try {
        if (!this.doctorDetails.doctorname) {
          // this.errorvalidation = "The doctor’s name cannot be empty";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameInput.focus();
          this.$refs.doctorNameInput.setSelectionRange(0, 0);
          this.nameError = "The doctor’s name cannot be empty";
          this.setAlertTimeout();
          return;
        }
        if (!this.validateName()) {
          // this.errorvalidation = "Please enter a valid name";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameInput.focus();
          this.$refs.doctorNameInput.setSelectionRange(0, 0);
          this.nameError = "The doctor’s name cannot be empty";
          this.setAlertTimeout();
          return;
        }
        if (!this.doctorDetails.email || !this.validateEmail()) {
          // this.errorvalidation = "Please enter a valid email";
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.showDismissibleAlert = true;
          this.activeTab = 'personal';
          this.$refs.doctorNameEmail.focus();
          this.$refs.doctorNameEmail.setSelectionRange(0, 0);
          this.emailError = "Please enter a valid email";
          this.setAlertTimeout();
          return;
        }
        if (!this.doctorDetails.age || !this.validateAge()) {
          // this.errorvalidation = "Please enter a valid age";
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.showDismissibleAlert = true;
          this.activeTab = 'personal';
          this.$refs.doctorNameAge.focus();
          this.$refs.doctorNameAge.setSelectionRange(0, 0);
          this.ageError = "Please enter a valid age";
          this.setAlertTimeout();
          return;
        }

        if (!this.doctorDetails.gender) {
          this.errorvalidation = "Please enter gender";
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.showDismissibleAlert = true;
          this.activeTab = 'personal';
          this.genderError = "Please enter gender";
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.languagesspoken ||
          this.doctorDetails.languagesspoken.length > 50
        ) {
          this.errorvalidation =
            "Please enter the languages spoken; the entry must not exceed 50 characters";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.setAlertTimeout();
          return;
        }

        if (!this.doctorDetails.email) {
          // this.errorvalidation = "Please enter the email";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameEmail.focus();
          this.$refs.doctorNameEmail.setSelectionRange(0, 0);
          this.emailError = true;
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.registration_info ||
          this.doctorDetails.registration_info.length > 50
        ) {
          // this.errorvalidation =
          //   "Please enter the registration info; the entry must not exceed 50 characters";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameReg.focus();
          this.$refs.doctorNameReg.setSelectionRange(0, 0);
          this.regError = "Please enter the registration info; the entry must not exceed 50 characters";
          this.setAlertTimeout();
          return;
        } else { this.regError = ""; }

        if (
          !this.doctorDetails.clincorhospname ||
          this.doctorDetails.clincorhospname.length > 100
        ) {
          // this.errorvalidation =
          //   "Please enter the clinic name; the entry must not exceed 100 characters";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameClinic.focus();
          this.$refs.doctorNameClinic.setSelectionRange(0, 0);
          this.clinicError = "Please enter the clinic name; the entry must not exceed 100 characters";
          this.setAlertTimeout();
          return;
        } else { this.clinicError = ""; }

        if (!this.doctorDetails.area || this.doctorDetails.area.length > 25) {
          this.errorvalidation =
            "Please enter the area.";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          // this.$refs.doctorNameArea.focus();
          // this.$refs.doctorNameArea.setSelectionRange(0, 0);
          this.areaError = "Please enter the area.";
          this.setAlertTimeout();
          return;
        } else { this.areaError = ""; }

        if (!this.doctorDetails.areacode || !this.validateAreacode()) {
          // this.errorvalidation = "Please enter valid area code";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameAreaCode.focus();
          this.$refs.doctorNameAreaCode.setSelectionRange(0, 0);
          this.this.areacodeError = "Please enter valid area code";
          this.setAlertTimeout();
          return;
        } else { this.areacodeError = ""; }

        if (
          !this.doctorDetails.address ||
          this.doctorDetails.address.length > 500
        ) {
          // this.errorvalidation =
          //   "Please enter the address; the entry must not exceed 500 characters";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameAddress.focus();
          this.$refs.doctorNameAddress.setSelectionRange(0, 0);
          this.addressError = "Please enter the address; the entry must not exceed 500 characters";
          this.setAlertTimeout();
          return;
        } else { this.addressError = ""; }

        if (
          !this.doctorDetails.specialization ||
          this.doctorDetails.specialization.length > 50
        ) {
          this.errorvalidation =
            "Please select the specialization.";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          // this.$refs.doctorNameSpec.focus();
          // this.$refs.doctorNameSpec.setSelectionRange(0, 0);
          this.specError = "Please select the specialization.";
          this.setAlertTimeout();
          return;
        } else { this.specError = ""; }

        if (
          !this.doctorDetails.qualifications ||
          this.doctorDetails.qualifications.length > 1000
        ) {
          // this.errorvalidation =
          //   "Please enter the qualifications; the entry must not exceed 1000 characters";
          this.showDismissibleAlert = true;
          this.activeTab = 'personal';
          this.$refs.doctorNameQual.focus();
          this.$refs.doctorNameQual.setSelectionRange(0, 0);
          this.qualError = "Please enter the qualifications; the entry must not exceed 1000 characters";
          this.setAlertTimeout();
          return;
        } else { this.qualError = ""; }

        if (!this.doctorDetails.experience || !this.validateExperience()) {
          // this.errorvalidation = "Please enter the valid experience";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameExp.focus();
          this.$refs.doctorNameExp.setSelectionRange(0, 0);
          this.experienceError = "Please enter the valid experience";
          this.setAlertTimeout();
          return;
        } else { this.experienceError = ""; }

        if (
          !this.doctorDetails.contactnumbers ||
          !this.validateContactNumber()
        ) {
          // this.errorvalidation = "Please enter the valid contact numbers";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameNum.focus();
          this.$refs.doctorNameNum.setSelectionRange(0, 0);
          this.conError = "Please enter the valid contact numbers";
          this.setAlertTimeout();
          return;
        } else { this.conError = ""; }

        if (!this.doctorDetails.description) {
          // this.errorvalidation = "Please enter the description";
          this.showDismissibleAlert = true;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          this.activeTab = 'personal';
          this.$refs.doctorNameDesp.focus();
          this.$refs.doctorNameDesp.setSelectionRange(0, 0);
          this.desError = "Please enter the description";
          this.setAlertTimeout();
          return;
        } else { this.desError = ""; }

        this.doctorDetails.languagesspoken = this.doctorDetails.languagesspoken
          .map(lang => lang.value)
          .join(", ");
        const { isSignUpComplete, userId, nextStep } = await signUp({
          username: this.doctorDetails.email,
          password: "changemenow@12345",
          options: {
            userAttributes: {
              email: this.doctorDetails.email,
              phone_number: this.doctorDetails.contactnumbers,
              given_name: this.doctorDetails.doctorname,
              family_name: this.doctorDetails.doctorname,
              gender: this.doctorDetails.gender,
            },
            autoSignIn: false
          }
        });
        this.doctorDetails.idp_id = userId;
        const response = await axios.post(
          `doctors/`,
          this.doctorDetails
        );
        this.errorvalidation = "";
        this.showSuccessAlert = true;
        this.successMessage = "Doctor created successfully!";
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.activeTab = 'personal';
        Object.keys(this.doctorDetails).forEach(key => {
          this.doctorDetails[key] = "";
        });
        this.setAlertTimeout();
        this.doctorDetails = {
          doctorname: "",
          gender: "",
          idp_id: "",
          age: "",
          languagesspoken: [],
          specialization: "",
          qualifications: "",
          experience: "",
          contactnumbers: "",
          email: "",
          area: null,
          areacode: "",
          address: "",
          registration_info: "",
          clincorhospname: "",
          description: "",
          weburl: "empty",
          appointment_duration: 0
        };
      } catch (error) {
        this.showDismissibleAlert = true;
        this.errorvalidation =
          "Failed to update Doctor details. Please check details entered/try again later.";
        this.activeTab = 'personal';
        this.setAlertTimeout();
      }
    },
    onDragOver(event) {
      event.preventDefault();
      this.isFileOver = true;
    },
    onDragLeave(event) {
      event.preventDefault();
      this.isFileOver = false;
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.file = file;
      this.filePreview = URL.createObjectURL(file);
    },
    onFileDrop(event) {
      event.preventDefault();
      this.isFileOver = false;
      const file = event.dataTransfer.files[0];
      this.file = file;
      this.filePreview = URL.createObjectURL(file);
    },
    removeImage() {
      this.file = null;
      this.filePreview = null;
      this.$refs.fileInput.value = "";
    }
  },
  created() {
    this.setUserpool();
  },

};
</script>
<style>
.d-flex {
  display: flex;
}

.form-control:disabled {
  background-color: hsla(0, 0%, 94%, 0.149);
  color: black;
}

.align-items-center {
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.grey-icon {
  color: grey;
  transition: color 0.3s ease;
}

.grey-icon:hover {
  color: black;
}

.share-icons {
  display: flex;
  align-items: center;
  position: absolute;
  right: 50px;
}

.file-over {
  border: 2px dashed #ccc;
}

.file-option img {
  width: 120px;
  height: 120px;
}

.call-lable {
  position: relative;
}

.remove-btn {
  position: absolute;
  top: 23px;
  right: 45%;
}

.remove-btn i {
  color: #e63333;
}

.center-container-btn {
  display: grid;
  place-items: center;
}

.card-title-main {
  text-align: center;
  margin-bottom: 40px;
}
</style>