<template>
  <!-- App Section -->
  <section class="app-section">
    <div class="container">
      <div class="app-bg">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="app-content">
              <div class="app-header aos" data-aos="fade-up">
                <h5>{{ $t("app_working_for_health") }}</h5>
                <h2>{{ $t("app_download_today") }}</h2>
              </div>
              <div class="app-scan aos" data-aos="fade-up">
                <p>Scan the QR code to get the app now</p>
                <img src="@/assets/img/scan-img.png" alt="Image" />
              </div>
              <div class="google-imgs aos" data-aos="fade-up">
                <b-button variant="primary me-1" @click="install">{{ $t("app_install_button") }}</b-button>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 aos" data-aos="fade-up">
            <div class="mobile-img">
              <img src="@/assets/img/mobile-img.png" class="img-fluid" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /App Section -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexApp from "@/assets/json/indexapp.json";
export default {
  data() {
    return {
      IndexApp: IndexApp,
      deferredPrompt: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      
      this.deferredPrompt = e;
      
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    
    async install() {
      this.deferredPrompt.prompt();
    }
  }
};
</script>
