// store.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState({
    storage: sessionStorage,
  })],
  state: {
    patientDetails: null,
    patientid: localStorage.getItem('patientid') || null,
    selectedPatientId: null,
    user: null,
    appoitmentDraft: '{}',
    clinicDetails: {
      clinicId: null,
      doctorId: null,
      doctorImage:""
    },
    userpool: null,
  },
  mutations: {
    setAuthUser(state, user) {
      state.user = JSON.stringify(user);
    },
    setPatientDetails(state, details) {
      state.patientDetails = details;
    },
    clearPatientDetails(state) {
      state.patientDetails = null;
    },
    setPatientId(state, patientid) {
      state.patientid = patientid;
      localStorage.setItem('patientid', patientid);
    },
    setSelectedPatientId(state, patientid) { 
      state.selectedPatientId = patientid;
    },
    setAppointmentDraft(state, data) {
      let appmDraft;
      try {
        appmDraft = state.appoitmentDraft ? JSON.parse(state.appoitmentDraft) : {};
      } catch {
        appmDraft = {};
      }
      if(data.doctorid) appmDraft.doctorid = data.doctorid;
      if(data.patientid) appmDraft.patientid = data.patientid;
      if(data.appointmentby) appmDraft.appointmentby = data.appointmentby;
      if(data.day) appmDraft.day = data.day;
      if(data.time) appmDraft.time = data.time;
      if(data.clinic) appmDraft.clinic = data.clinic;
      if(data.clinicname) appmDraft.clinicname = data.clinicname;
      if(data.reason) appmDraft.reason = data.reason;
      if(data.age) appmDraft.age = data.age;
      if(data.doctorImage) appmDraft.doctorImage = data.doctorImage;
      state.appoitmentDraft = JSON.stringify(appmDraft);
    },
    clearAppointmentDraft(state) {
      state.appoitmentDraft = '{}';
    },
    setClinicDetails(state, { clinicId, doctorId, doctorImage }) {
      state.clinicDetails = { clinicId, doctorId, doctorImage };
    },
    setUserPool(state, userpool) {  // Mutation for setting userpool
      state.userpool = userpool;
    },
  },
  actions: {
    updatePatientDetails({ commit }, details) {
      commit('setPatientDetails', details);
    },
    resetPatientDetails({ commit }, details) {
      commit('clearPatientDetails');
    },
    updatePatientId({ commit }, patientid) {
      commit('setPatientId', patientid);
    },
    updateSelectedPatientId({ commit }, patientid) { 
      commit('setSelectedPatientId', patientid);
    },
    updateAppointmentDraft({ commit }, data) {
      commit('setAppointmentDraft', data);
    },
    resetAppointmentDraft({ commit }, data) {
      commit('clearAppointmentDraft');
    },
    storeClinicDetails({ commit }, { clinicId, doctorId, doctorImage }) {
      commit('setClinicDetails', { clinicId, doctorId, doctorImage });
    },
    updateUserPool({ commit }, userpool) {  
      commit('setUserPool', userpool);
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.user ? true : false
    },
    getPatientDetails: (state) => { 
      state.patientDetails;
     },
    getMobileNumber: (state) => {
      return state.patientDetails ? state.patientDetails.phone : null;
    },
    getEmail: (state) => {
      return state.patientDetails ? state.patientDetails.email : null;
    },

    getfirstName: (state) => {
      return state.patientDetails ? state.patientDetails.firstName : null;
    },
    getlastName: (state) => {
      return state.patientDetails ? state.patientDetails.lastName : null;
    },
    getGender: (state) => {
      return state.patientDetails ? state.patientDetails.gender : null;
    },
    getPatientId: (state) => {
      return state.patientid;
    },
    getSelectedPatientId(state) { 
      return state.selectedPatientId;
    },
    getAppointmentDraft: (state) => {
      try {
        return state.appoitmentDraft ? JSON.parse(state.appoitmentDraft) : {};
      } catch {
        return {};
      }
    },
    getclinicDetails(state) { 
      return state.clinicDetails;
    },
    getUserPool(state) {
      return state.userpool;
    },
    getUserTokens: (state) => {
      if (state.user) {
        try {
          const currUser = JSON.parse(state.user);
          return currUser.tokens;
        } catch(err) {
          state.user = null;
          return null;
        }
      }
    }
  }
});
