<template>
    <div class="main-wrapper">
        <clinicheader></clinicheader>
        <breadcrumb-clinic :title="title" :text="text" :text1="text1" />
        <div class="content">
            <div class="container">
                <div class="row">
                    <doctorsidebar2></doctorsidebar2>
                    <div class="col-md-7 col-lg-8 col-xl-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <clinic-patient-search></clinic-patient-search>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <clinicfooter />
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: "Add Appointment",
            text: "Clinic Dashboard",
            text1: "Add Appointment",
            error: "",
        };
    },
};
</script>