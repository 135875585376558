<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="ml-auto">
                          <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>{{ error }}</b-alert>
                          <b-alert v-model="showDismissibleSuccess" variant="success" dismissible>{{ success }}</b-alert>
                    </div>
                    <h4 class="card-title">Cancel Slots</h4>
                    <div class="profile-box">
                      <div class="row">
                        <div class="col-sm-6 col-12 avail-time">
                          <div class="mb-3">
                            <div class="schedule-calendar-col justify-content-start">
                              <form @submit.prevent="fetchSlots">
                                <span>Date:</span>
                                <div class="me-3">
                                  <input
                                    type="text"
                                    id="daterange"
                                    class="form-control"
                                    v-model="startdate"
                                    @change="onDateChange"
                                  />
                                </div>
                                <button type="button" @click="prevDate" class="btn btn-secondary me-2">Prev</button>
                                <button type="button" @click="nextDate" class="btn btn-secondary me-3">Next</button>
                                <div class="me-3">
                                  <span>Clinic:</span>
                                  <select
                                    v-model="selectedClinic"
                                    class="form-control custom-select"
                                    @change="fetchSlots"
                                  >
                                    <option
                                      v-for="clinic in clinics"
                                      :key="clinic.clinicid"
                                      :value="clinic.clinicid"
                                    >
                                      {{ clinic.clinicname }}
                                    </option>
                                  </select>
                                </div>
                                <div class="search-time-mobile">
                                  <input
                                    type="submit"
                                    name="submit"
                                    value="Search"
                                    @click=search
                                    class="btn btn-primary h-100"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="bookedSlots.length > 0" class="row">
                        <div class="col-lg-12">
                          <h3 class="h3 text-center book-btn2 mt-3 px-5 py-1 mx-3 rounded">
                            Booked Slots
                          </h3>
                          <div class="text-center mt-3">
                            <h4 class="h4 mb-2" >Select Slots to Cancel
                               <button class="btn btn-link select-all-btn"
          :class="{ active: areAllAvailableSlotsSelected }"
                                @click="selectAllBookedSlots">
        {{ areAllBookedSlotsSelected ? 'Unselect All' : 'Select All' }}
        </button> </h4>
                            <div class="token-slot mt-2">
                              <div
                                class="form-check-inline visits me-1"
                                v-for="slot in bookedSlots"
                                :key="slot.appointmentid"
                              >
                                <label class="visit-btns">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :value="slot.appointmentid"
                                    v-model="selectedBookedSlots"
                                  />
                                  <span
                                    class="visit-rsn"
                                    data-bs-toggle="tooltip"
                                    :title="slot.appointmenttime"
                                  >{{ formatTime(slot.appointmenttime) }}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center">
                        <button @click="cancelSlots" class="btn btn-primary">Cancel</button>
                        </div>
                      </div>
                      <div v-if="timeslots.length > 0" class="row">
                        <div class="col-lg-12">
                          <h3
                            class="h3 text-center book-btn2 mt-3 px-5 py-1 mx-3 rounded"
                          >
                            Available Slots
                          </h3>
                          <div class="text-center mt-3">
                            <h4 class="h4 mb-2">Select Slots to Remove 
                              <button class="btn btn-link select-all-btn"
          :class="{ active: areAllAvailableSlotsSelected }"
           @click="selectAllAvailableSlots">
         {{ areAllavailableSlotsSelected ? 'Unselect All' : 'Select All' }}
        </button> </h4>
                            <div class="token-slot mt-2">
                              <div
                                class="form-check-inline visits me-1"
                                v-for="slot in timeslots"
                                :key="slot"
                              >
                                <label class="visit-btns">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :value="slot"
                                    v-model="selectedSlots"
                                  />
                                  <span
                                    class="visit-rsn"
                                    data-bs-toggle="tooltip"
                                    :title="slot"
                                    >{{ slot }}</span
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center">
                        <button @click="saveSlots([])" class="btn btn-primary">Remove</button>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <indexfooter></indexfooter>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import availabletiming from "@/assets/json/doctor/availabletiming.json";
import { ref, onMounted } from "vue";
import axios from "axios";
import Swal from 'sweetalert2';
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import jQuery from "jquery";
const $ = jQuery;

export default {
  data() {
    return {
      title: "Available Timings",
      text: "Home",
      text1: "Available Timings",
      availabletiming: availabletiming,
      startdate: "",
      clinics: [],
      selectedClinic: "",
      timeslots: [],
      allSlots: [],
      selectedSlots: [],
      bookedSlots: [],
      selectedBookedSlots: [],
      areAllBookedSlotsSelected: false,
      areAllavailableSlotsSelected: false,
      showDismissibleSuccess: false,
      success: '',
      error: '',
      showDismissibleAlert: false
      };
  },
  async mounted() {
    const currentDateTime = await this.fetchCurrentDateTime();
    this.startdate = currentDateTime.toISOString().split("T")[0];
    this.fetchClinics();
    this.initDateRangePicker();
    this.fetchBookedSlots();
  },
  methods: {
  search(){
    this.filterSlots();
    this.fetchBookedSlots();
  },
   async fetchCurrentDateTime() {
    try {
      const response = await axios.get('/doctors/datetime');
      const [month, date, day, time24, time12] = response.data;
      const datetimeString = `${date}T${time24}`;
      return new Date(datetimeString);
    } catch (error) {
      console.error('Error fetching current date and time:', error);
      return new Date();
    }
  },
    initDateRangePicker() {
      const vm = this;
      $(function () {
        $("#daterange").daterangepicker({
          singleDatePicker: true,
          showDropdowns: true,
          autoUpdateInput: false,
          locale: {
            format: "YYYY-MM-DD",
          },
          startDate: vm.startdate,
        }, function (start) {
          vm.startdate = start.format("YYYY-MM-DD");
        });
      });
    },
    onDateChange(event) {
      this.startdate = event.target.value;
      this.filterSlots();
      this.fetchBookedSlots();
    },
    prevDate() {
      const date = new Date(this.startdate);
      date.setDate(date.getDate() - 1);
      this.startdate = date.toISOString().split("T")[0];
      this.filterSlots();
      this.fetchBookedSlots();
    },
    nextDate() {
      const date = new Date(this.startdate);
      date.setDate(date.getDate() + 1);
      this.startdate = date.toISOString().split("T")[0];
      this.filterSlots();
      this.fetchBookedSlots();
    },
    async fetchClinics() {
      try {
        const doctorId = sessionStorage.getItem("userdbid");
        const currentDateTime = await this.fetchCurrentDateTime();
        const now = new Date(currentDateTime);
        const starttime = now.toISOString().split("T")[0];
        const endtime = new Date(currentDateTime);
        endtime.setMonth(endtime.getMonth() + 1);
        const formattedEndtime = endtime.toISOString().split("T")[0];

        const response = await axios.get(
          `/doctors/${doctorId}/slots`,
          {
            params: {
              starttime: starttime,
              endtime: formattedEndtime,
            },
          }
        );
        if (response.data && response.data.timeslots.length > 0) {
           const clinicMap = new Map();
      response.data.timeslots.forEach((slot) => {
        if (!clinicMap.has(slot.clinicid)) {
          clinicMap.set(slot.clinicid, slot.clinic);
        }
      });

      this.clinics = Array.from(clinicMap, ([clinicid, clinicname]) => ({
        clinicid,
        clinicname,
      }));
      this.selectedClinic = this.clinics[0].clinicid;
      this.selectedClinicName = this.clinics[0].clinicname;
      this.allSlots = response.data.timeslots;
      this.filterSlots();
    
        }
      } catch (error) {
        console.error("Error fetching clinics:", error);
      }
    },
    async filterSlots() {
   const currentDateTime = await this.fetchCurrentDateTime();
    const now = new Date(currentDateTime);
    const currentDate = now.toISOString().split("T")[0];
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    this.timeslots = this.allSlots
      .filter(slot => slot.clinicid === this.selectedClinic && slot.day === this.startdate)
      .flatMap(slot => slot.times)
      .filter(time => {
        if (!time || time.trim() === "") return false;
        if (this.startdate === currentDate) {
          const [hours, minutes] = time.split(':').map(Number);
          return hours > currentHours || (hours === currentHours && minutes > currentMinutes);
        }
        this.selectedBookedSlots=[];
        this.selectedSlots=[];
        this.areAllBookedSlotsSelected=false;
        this.areAllavailableSlotsSelected=false;
        return true;
      });
      
    },
    async proceedWithSaving(timesToRemove) {
      const doctorId = sessionStorage.getItem("userdbid");
      const concatenatedSlots = [...this.selectedSlots, ...timesToRemove].join(", ");
      
      const response = await axios.put(
        `/doctors/${doctorId}/slots/${this.selectedClinic}?date=${this.startdate}`,
        [concatenatedSlots],
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      this.success = 'Selected slots have been removed successfully!';
      this.showDismissibleSuccess = true,
      window.scrollTo({ top: 0, behavior: "smooth" });

      setTimeout(() => {
        this.showDismissibleSuccess = false
      }, 5000);

      //console.log('Save response:', response.data);
      this.selectedSlots = [];
      this.fetchClinics();
    },
    async saveSlots(timesToRemove = [], cancel = false) {
      try {
        //console.log("cancelled is", cancel)
        if (cancel) {
          await this.proceedWithSaving(timesToRemove);
          return; 
        }

        const result = await Swal.fire({
          title: 'Are you sure?',
          text: "Do you want to remove the selected slots?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, remove them!'
        });

        if (result.isConfirmed) {
          await this.proceedWithSaving(timesToRemove);
        }
      } catch (error) {
        console.error('Error saving slots:', error);
        this.error = 'Failed to Remove the slots';
        this.showDismissibleAlert = true,
        setTimeout(() => {
        this.showDismissibleAlert = false
      }, 5000);
      }
    },

    async fetchBookedSlots() {
      try {
        const doctorId = sessionStorage.getItem("userdbid");
    const response = await axios.get(`/doctors/${doctorId}/appointments`, {
      params: {
        filter_type: "upcoming",
      },
    });

    if (response.data && response.data.upcoming_appointments.length > 0) {
      const now = await this.fetchCurrentDateTime();
      const currentDate = now.toISOString().split("T")[0];
      const currentHours = now.getHours();
      const currentMinutes = now.getMinutes();
      this.bookedSlots = response.data.upcoming_appointments.filter(appointment => {
        if (appointment.appointmentdate === this.startdate &&
         appointment.clinicname === this.clinics.find(clinic => clinic.clinicid === this.selectedClinic)?.clinicname  && 
         appointment.appointmentstatus === 'E') {
          if (this.startdate === currentDate) {
            const [hours, minutes] = appointment.appointmenttime.split(':').map(Number);
            return hours > currentHours || (hours === currentHours && minutes > currentMinutes);
          }
          return true;
        }
        return false;
      });
    }
  } catch (error) {
    console.error('Error fetching booked slots:', error);
  }
},
    formatTime(time) {
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}`;
    },
    async cancelSlots() {
      try {
        // Ask for confirmation before proceeding
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: "Do you want to cancel the selected appointments?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, cancel them!',
          customClass: {
            icon: 'custom-swal-icon',  
            title: 'custom-swal-title', 
            htmlContainer: 'custom-swal-text' 
          }
        });

        if (result.isConfirmed) {
          const timesToRemove = [];

          for (const appointmentId of this.selectedBookedSlots) {
            const appointment = this.bookedSlots.find(slot => slot.appointmentid === appointmentId);
            if (appointment) {
              const response = await axios.put(
                `/patients/${appointment.patientid}/appointments/${appointmentId}`,
                {
                  cancelreason: 'Cancelled by doctor'
                },
                {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              );
              //console.log('Cancel response:', response.data);
              timesToRemove.push(this.formatTime(appointment.appointmenttime));
              //console.log("timestoRemove:", timesToRemove);
            }
          }

          await this.saveSlots(timesToRemove, true);

          this.selectedBookedSlots = [];
          this.fetchBookedSlots();
        }
      } catch (error) {
        console.error('Error canceling appointments:', error);
       this.error = 'Failed to canceling appointments';
        this.showDismissibleAlert = true,
        setTimeout(() => {
        this.showDismissibleAlert = false
      }, 5000);
      }
    },
selectAllBookedSlots() {
      if (this.selectedBookedSlots.length === this.bookedSlots.length) {
       
        this.selectedBookedSlots = [];
      } else {
        
        this.selectedBookedSlots = this.bookedSlots.map(
          (slot) => slot.appointmentid
        );
      }
      this.areAllBookedSlotsSelected = !this.areAllBookedSlotsSelected;
},
selectAllAvailableSlots() {
      if (this.selectedSlots.length === this.timeslots.length) {
        
        this.selectedSlots = [];
      } else {
        
        this.selectedSlots = [...this.timeslots];
      }
      this.areAllavailableSlotsSelected = !this.areAllavailableSlotsSelected;
    }
  },
  watch: {
    selectedClinic(newClinic) {
      this.filterSlots();
      this.fetchBookedSlots();
    }
  }
};
</script>

<style scoped>
.select-all-btn {
  border: 1px solid #007bff;
  background-color: transparent;
  color: #007bff;
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, text-decoration 0.3s;
  border-radius: 5px;
  text-decoration: none !important;
}

.select-all-btn:hover {
  background-color: #007bff; 
  color: white;
  text-decoration: none !important;
}
.select-all-btn.active {
  background-color: #007bff;
  color: white;
  text-decoration: none !important;
}

.select-all-btn.active:hover {
  background-color: transparent;
  color: #007bff;
  text-decoration: none !important;
}

.visit-btns input {
  display: none;
}
.visit-btns .visit-rsn {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
}
.visit-btns input:checked + .visit-rsn {
  background-color: #007bff;
  color: #fff;
}

.custom-select {
  font-size: 1em; 
  padding: 8px; 
  border: 2px solid #007bff;
  border-radius: 5px; 
  background-color: #f8f9fa; 
}

.custom-select option {
  font-size: 1em;
}
</style>
