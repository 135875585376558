<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Login Tab Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">  
                    <img src="@/assets/img/login-banner.png" class="img-fluid" alt="YourDoc Login" />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3>Login to <span>YourDoc</span></h3>
                      <!-- <div class="text-end" v-if="doctor" @click="toggleLogin(0)">
                        <router-link class="forgot-link" to="#">I am a patient</router-link>
                      </div>
                      <div class="text-end" v-else @click="toggleLogin(1)">
                        <router-link class="forgot-link" to="#">I am a doctor</router-link>
                      </div> -->
                    </div>
                    <p v-if="error">
                      <b-alert v-model="showDismissibleAlert" variant="danger" dismissible="">{{ error }}</b-alert>
                    </p>
                    <div class="text" v-if="info">
                      Mobile Number not confirmed yet.<br>
                      <router-link class="forgot-link" to="" @click="resendOTP">Resend confirmation code</router-link>
                    </div>
                    <form @submit.prevent="submitForm">
                      <div class="mb-3 form-focus" v-if="doctor">
                        <input type="email" class="form-control floating" v-model="email" />
                        <label class="focus-label" :class="{ 'has-input': email }">Email</label>
                      </div>
                      <div class="mb-3 form-focus" v-else>
                        <vue-tel-input
                        class="form-control floating"
                          mode='national'
                          @on-input="updateInput"
                          @focus="onFocus"
                          @blur="phoneTouched = true"
                          :defaultCountry='defaultCountry'
                          :autoFormat="autoFormat"
                          :only-countries="onlyCountries"
                          :inputOptions="inputOptions"
                          :dropdownOptions="dropdownOptions"
                          :validCharactersOnly='true'
                          ref="phonenumber"
                                              
                        />
                        <label class="focus-label" :class="{ 'has-input': isFocused || phone }">
                          Mobile Number
                        </label>
                        <!-- <div
                        v-if="phoneTouched && !validPhone()"
                        class="invalid-feedback"
                      >Please enter a valid phone number.</div> -->
                      </div>
                      <div class="mb-3 form-focus position-relative">
                        <input :type="showPassword ? 'text' : 'password'" class="form-control floating" v-model="pwd" />
                        <label class="focus-label" :class="{ 'has-input': pwd }">Password</label>
                        <button type="button" class="password-toggle" @click="togglePasswordVisibility">
                          <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="text-end">
                        <router-link
                          class="forgot-link"
                          @click="updateUserpool"
                          :to="doctor ? { name: 'forgot-password', query: { type: 'doctor' } } : { name: 'forgot-password' }"
                        >
                          Forgot Password ?
                        </router-link>
                      </div>
                      <b-button :disabled="working" class="btn btn-primary w-100 btn-lg login-btn" type="submit"><span
                          class="spinner-border spinner-border-sm me-2" role="status" v-if="working"></span>
                        Login
                      </b-button>
                      <!--<div class="login-or">
                        <span class="or-line"></span>
                        <span class="span-or">or</span>
                      </div>
                      <div class="row social-login">
                        <div class="col-6">
                          <a href="javascript:;" class="btn btn-facebook w-100"
                            ><i class="fab fa-facebook-f me-1"></i> Login</a
                          >
                        </div>
                        <div class="col-6">
                          <a href="javascript:;" class="btn btn-google w-100"
                            ><i class="fab fa-google me-1"></i> Login</a
                          >
                        </div>
                      </div>-->
                      <div class="text-center dont-have">
                        Don’t have an account?
                        <router-link to="register">Register</router-link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- /Login Tab Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <indexfooter />
    </div>
  </div>
</template>
<script>
import { DOCTOR_POOL, PATIENT_POOL} from '@/AWSResources';
import { signIn, resendSignUpCode } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import axios from "axios"

export default {
  data() {
    return {
      doctor: false,
      email: '',
      phone: '',
      pwd: '',
      error: null,
      info: null,
      working: false,
      showDismissibleAlert: false,
      showPassword: false,
      autoFormat: false,
      defaultCountry: 'IN',
      onlyCountries: [
          'IN', 'US', 'GB', 'CA', 'AU', 
          'AE', 'BH', 'KW', 'OM', 'QA', 'SA', 
          'SG', 'MY', 'NZ'
        ],
      dropdownOptions: {
        showFlags: true,
        showDialCodeInList:true,
        // showCountryName: true,
        showDialCodeInSelection: true,
         
      },
      inputOptions: {
        maxlength: "10",
        placeholder: "",
        required: true,
        autofocus: true,
        // styleClasses: 'form-control floating'

      },
      isFocused: true,
      isValidPhone: ''
    };
  },
  mounted(){
    this.toggleLogin(0);
  },
  methods: {
    updateInput(phone, phoneObject){
      this.phone = phoneObject.number // E.164 number convention
      this.isValidPhone = phoneObject.valid 
    },
    updateUserpool(){
      this.$store.dispatch('updateUserPool', "PATIENT_USERPOOL");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleLogin(typeid) {
      this.doctor = typeid === 1;
      const poolConfig = typeid === 1 ? DOCTOR_POOL : PATIENT_POOL;
      Amplify.configure(poolConfig);
     
    },
    async resendOTP() {
      try {
        if (!this.phone) {
          this.error = "Please enter your mobile number";
          this.showDismissibleAlert = true;
          return;
        }
        resendSignUpCode({
          username: this.phone
        });
        localStorage.setItem("confirmotpphone", this.phone);
        localStorage.setItem("signupphone", this.phone);
        this.$router.push("/signup-otp");
      } catch (e) {
        console.log(e);
      }
    },
    async submitForm() {
      this.working = true;
      try {
        const { isSignedIn, nextStep } = await signIn(
          { username: this.doctor?this.email:this.phone, password: this.pwd }
        );

        if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
          this.working = false;
          this.info = "You have not yet confirmed your email";
        }
      } catch (error) {
        this.working = false;
        this.showDismissibleAlert = true;
        if (error.name === "UserNotFoundException") {
          this.error = "Invalid username or password";
        } else if (error.name === "UserNotConfirmedException") {
          this.error = "You have not yet confirmed your email";

        } else {
          console.log('error signing in', error);
          this.error = error.message;

        }
      }
    }
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.password-toggle .fas {
  font-size: 1.2em;
  color: #6c757d;
}

.vue-tel-input input {
  border: none;
  box-shadow: none;
  outline: none; /* Remove any box-shadow if applicable */
}

.vue-tel-input {
  display: flex; /* Ensures the elements inside are aligned in a row */
  align-items: center; /* Vertically centers the input field with the flag */
}

.vue-tel-input input {
  border: none;
  box-shadow: none;
  outline: none;
  flex-grow: 1;
  padding-left: 8px; 
  height: 40px;
}

.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #bbb !important;
}

.vue-tel-input.form-control {
    padding-top: 24px;
}

::v-deep .vti__dropdown-list.below {
    top: 33px;
    z-index: 12;
}
</style>
