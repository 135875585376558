<template>
  <div class="main-wrapper">
     <clinicheader></clinicheader>
    <breadcrumb-clinic :title="title" :text="text" :text1="text1" />

    <div class="content content-space">
      <div class="container">
        <div class="back-link">
          <router-link to @click="$router.go(-1)">
            <i class="fa-solid fa-arrow-left-long"></i>
            {{ $t('back') }}
          </router-link>
        </div>
        <!-- <div class="row"> -->
        <div class="col-lg-12 col-md-15">
          <div class="card">
            <div class="card-body booking-card-body">
              <div class="booking-doctor-details">
                <div class="booking-doctor-left">
                  <div class="booking-doctor-img">
                    <router-link
                      :to="{
                                            name: 'doctor-profile',
                                            params: { id: doctorDetails.doctorid },
                                        }"
                    >
                      <!-- <img :src="profileImageUrl || defaultImageUrl" alt="User Image" /> -->
                      <img
                        :src="doctorImage || require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                        :alt="doctorDetails.doctorname"
                        class="img-fluid"
                      />
                    </router-link>
                  </div>
                  <div class="booking-doctor-info">
                    <h4>
                      <router-link
                        :to="{
                              name: 'doctor-profile',
                              params: { id: doctorDetails.doctorid },
                              }"
                      >{{ doctorDetails.doctorname }}</router-link>
                    </h4>
                    <p>
                      {{ doctorDetails.qualifications }},
                      {{ doctorDetails.specialization }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card booking-card">
                        <div class="filter-collapse">
                            <ul class="horizontal-list">
                                <li v-for="clinicname in clinics" :key="clinicname">
                                    <label class="custom_check d-inline-flex">
                                        &nbsp;
                                        <input type="checkbox" checked v-model="selectedClinics" :value="clinicname"
                                            disabled />
                                        <span class="checkmarkzz"
                                            :style="{ backgroundColor: getColor(clinicname) }"></span>
                                        {{ clinicname }}
                                    </label>
                                </li>
                            </ul>
                        </div>
          </div>-->
          <!-- <div style="margin-bottom: 30px;">
            <VueMultiselect
              v-model="selectedClinic"
              :options="clinicOptions"
              label="label"
              track-by="value"
              placeholder="Select clinic"
              multiple
            >
              <template slot="tag" slot-scope="{ option }">
                <span class="custom__tag">{{ option.label }}</span>
              </template>
            </VueMultiselect>
          </div> -->
          <div class="card booking-card">
            <div class="card-body time-slot-card-body">
              <div class="bookingrange btn" ref="bookingRange" style="margin-bottom: 20px;">
                <i class="feather-calendar" style="margin-right:4px"></i>
                <!-- <img src="@/assets/img/icons/today-icon.svg" alt="calendar-mage" /> -->
                <span></span>
                <i class="fas fa-chevron-down" style="margin-left:4px"></i>
              </div>
              <div v-for="item in dateRangeData" :key="item.Day">
                <div v-if="doctorSlots[item.FullDate] && doctorSlots[item.FullDate].length > 0">
                  <!-- <div class="call-view"> -->
                  <div class="flex-containerx">
                    <h4 class="responsive-date">
                      <!-- <i class="far fa-calendar" style="margin-right:5px"></i> -->
                      {{ item.Day }},
                      {{ item.Month }}
                    </h4>
                  </div>
                  <div class="rowx">
                    <!-- x ultra -->
                    <div>
                      <!-- oneday -->
                      <div class="time-slotzz time-slot-blkzz" style="margin-bottom: 40px;">
                        <div class="time-slot-list">
                          <!-- <template> -->
                          <ul>
                            <template
                              v-for="([timeSlot, clinic], index) in doctorSlots[item.FullDate]"
                              :key="timeSlot"
                            >
                              <div
                                v-if="index === 0 || clinic !== doctorSlots[item.FullDate][index - 1][1]"
                                class="clinic-heading"
                              >
                                <div class="buttons-container">
                                  <b-button
                                    type="button"
                                    class="btn"
                                    @click="prevDay"
                                    style="margin-right:5px"
                                  >
                                    <i class="feather-arrow-left-circle"></i>
                                    {{
                                    $t('prev') }}
                                  </b-button>
                                  <b-button type="button" class="btn-primary" @click="nextDay">
                                    {{ $t('next') }}
                                    <i class="feather-arrow-right-circle"></i>
                                  </b-button>
                                </div>
                                <h4>
                                  <i class="fas fa-hospital" style="margin-right:5px"></i>
                                  {{ clinic }}
                                </h4>
                              </div>
                              <li :style="{ maxWidth: '10rem' }">
                                <div>
                                  <a
                                    v-if="
                                                                        timeSlot &&
                                                                        timeSlot.length == 5 &&
                                                                        selectedClinics.size !== 0 &&
                                                                        (selectedClinics.size === 0 || selectedClinics.has(clinic))
                                                                    "
                                    :class="{
                                                                        timing: true,
                                                                        active:
                                                                            timeSlot + item.Day ===
                                                                            activeTimeSlot,
                                                                    }"
                                    href="javascript:void(0);"
                                    @click="
                                                                        selectTimeSlot(
                                                                            clinic,
                                                                            timeSlot,
                                                                            item.FullDate
                                                                        )
                                                                        "
                                    @mouseover="
                                                                            handleTimeSlotHover(timeSlot + item.Day)
                                                                            "
                                    @mouseleave="clearActiveTimeSlot"
                                    :style="{
                                                                                // color: activeTimeSlot === timeSlot + item.Day ? 'white' : '#64627C'
                                                                                
                                                                                color: activeTimeSlot === timeSlot + item.Day ? 'white' : '#04222e'
                                                                                // #303f9f
                                                                                , backgroundColor: getColor(clinic),
                                                                                // fontWeight: 'bold',
                                                                                // textShadow: activeTimeSlot === timeSlot + item.Day ? 'none' : '2px 2px 4px rgba(0, 0, 0, 0.5)',
                                                                                // textShadow: activeTimeSlot === timeSlot + item.Day ? 'none' : '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                                                            }"
                                  >
                                    <span>{{ formatTime(timeSlot) }}</span>
                                  </a>
                                </div>
                              </li>
                            </template>
                            <li v-for="i in numDummies" :key="'dummy-' + i" class="dummy"></li>
                          </ul>
                          <!-- </template> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- start of clinic timing -->
        <!-- <div class="col-lg-4 col-md-12" style="margin-top: 105px;">
                      <div>
                          <div class="card booking-card">
                              <div>
                                  <div class="mt-4" style="display: flex; justify-content: center; margin-left: 16px; margin-right: 16px;">
                                      <VueMultiselect v-model="selectedClinic" :options="clinicOptions" label="label"
                                          track-by="value" placeholder="Select clinic" multiple>
                                          <template slot="tag" slot-scope="{ option }">
                                              <span class="custom__tag">{{ option.label }}</span>
                                          </template>
                                      </VueMultiselect>
                                  </div>
                                  <div class="clinic-timing"
                                      style="margin-left: 20px; margin-right: 20px; margin-top: 20px; margin-bottom: 20px;">
                                      <template
                                          v-for="day in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']">
                                          <div v-if="hasTimings(fullDayName(day))" :key="day">
                                              <p class="timings">
                                                  <span class="day">{{ day }}</span> :<br>
                                                  <span class="time">{{ getTimings(fullDayName(day)) }}</span>
                                              </p>
                                          </div>
                                      </template>
                                  </div>
                              </div>
                          </div>
                      </div>
        </div>-->
        <!-- end of clinic timing -->
        <!-- </div> -->
        <!-- /Booking -->
      </div>
    </div>
    <indexfooter></indexfooter>
  </div>
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
// import Booking from "@/assets/json/booking/booking-2.json";
import "vue3-carousel/dist/carousel.css";
import { onMounted, defineComponent, ref, watch } from "vue";
import { getCurrentInstance } from "vue";
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import moment from "moment";
import axios from "axios";
import DateRangePicker from "daterangepicker";
import VueMultiselect from "vue-multiselect";
import defaultImageUrl from "@/assets/img/doctors/doctor-thumb-01.jpg";
import eventBus from "@/eventBus";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const generateDateRangeData = (startDate, endDate) => {
  const dateRangeData = [];
  const currentDate = moment(startDate);
  const lastDate = moment(endDate);

  while (currentDate.isSameOrBefore(lastDate, "day")) {
    dateRangeData.push({
      Day: currentDate.format("dddd"),
      Month: currentDate.format("MMM D"),
      FullDate: currentDate.format("YYYY-MM-DD")
    });

    currentDate.add(1, "days");
  }

  return dateRangeData;
};

export default defineComponent({
  props: {
    doctor: {
      type: Object,
      default: {}
    },
    doctortimmings: {
      type: Array
    }
  },
  // happy
  data() {
    return {
      clinicColors: ["#F3F9FF"],
      clinicColorMap: {},
      title: this.$t("booking_title"),
      text: this.$t("home"),
      text1: this.$t("booking"),
      showMorningTimeSlot: false,
      showAfternoonTimeSlot: false,
      showEveningTimeSlot: false,
      startDate: null,
      endDate: null,
      dateRangeData: [],
      timeslots: [],
      selectedIndex: 0,
      initialSlotsToShow: 5,
      doctorId: null,
      patientId: null,
      showAllSlots: false,
      selectedClinic: [],
      selectedTimeSlot: null,
      activeTimeSlot: null,
      doctorSlots: [],
      clinics: [],
      selectedClinics: new Set(),
      doctoridx: 0,
      doctortimmings: [],
      clinicOptions: [],
      settings: {
        itemsToShow: 1,
        snapAlign: "center"
      },
      // defaultImageUrl: defaultImageUrl,
      // profileImageUrl: "",
      doctorImage: this.$store.getters.getAppointmentDraft.doctorImage,
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center"
        },
        767: {
          itemsToShow: 3,
          snapAlign: "center"
        },
        991: {
          itemsToShow: 4,
          snapAlign: "center"
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start"
        }
      },
      serverDateTime: null
    };
  },
  watch: {
    selectedClinic(newSelectedClinic) {
      this.selectedClinics = new Set(
        newSelectedClinic.map(clinic => clinic.value)
      );
      if (newSelectedClinic.length === 0 && this.clinicOptions.length > 0) {
        this.selectedClinic = [this.clinicOptions[0]];
      }
    }
  },
  components: {
    VueMultiselect,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  computed: {
    // slotsresponsive
    numDummies() {
      if (
        !Array.isArray(this.dateRangeData) ||
        this.selectedIndex < 0 ||
        this.selectedIndex >= this.dateRangeData.length
      ) {
        return 0;
      }
      const currentDate = this.dateRangeData[this.selectedIndex]?.FullDate;
      if (!currentDate) {
        return 0; //
      }
      const totalItems = this.doctorSlots[currentDate]?.length || 0;
      const slotsPerRow = this.getSlotsPerRow();
      const remainder = totalItems % slotsPerRow;
      const dummyItems = remainder === 0 ? 0 : slotsPerRow - remainder;
      return dummyItems;
    }
  },
  methods: {
    // slotsresponsive
    // async fetchPatientProfileImage() {
    //     // const doctorid = sessionStorage.getItem("userdbid");
    //     const doctorIdx = this.$route.params.id;
    //     this.doctoridx = doctorIdx;
    //     try {
    //         const response = await axios.get(
    //             `doctors/doctorimg/${this.doctoridx}/download`,
    //             {
    //                 responseType: "arraybuffer"
    //             }
    //         );
    //         console.log("thisfinal doctor id", this.doctoridx);
    //         const objectURL = window.URL.createObjectURL(new Blob([response.data]));
    //         this.profileImageUrl = objectURL;
    //     } catch (error) {
    //         console.error("Error fetching patient profile image:", error);
    //     }
    // },
    getSlotsPerRow() {
      if (window.innerWidth >= 1024) {
        return 10;
      } else if (window.innerWidth >= 768) {
        return 6;
      } else {
        return 4;
      }
    },
    scrollWindow(amount) {
      window.scrollBy({
        top: amount,
        behavior: "smooth"
      });
    },
    fullDayName(abbreviatedDay) {
      const daysMap = {
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday"
      };
      return daysMap[abbreviatedDay];
    },
    hasTimings(day) {
      return this.doctortimmings.some(timing => timing.weekday === day);
    },
    // getTimings(day) {
    //     const timings = this.doctortimmings.filter(
    //         timing => {
    //             return timing.weekday === day && this.selectedClinics.has(timing.clinicname);
    //         }
    //     );
    //     return timings
    //         .map(timing => {
    //             const startTime = this.convertToAMPM(timing.starttime);
    //             const endTime = this.convertToAMPM(timing.endtime);
    //             return `${timing.clinicname}: ${startTime} - ${endTime}`;
    //         })
    //         .join(", ");
    // },
    getTimings(day) {
      if (this.selectedClinics.size === 0) {
        return this.doctortimmings
          .filter(timing => timing.weekday === day)
          .map(timing => {
            const startTime = this.convertToAMPM(timing.starttime);
            const endTime = this.convertToAMPM(timing.endtime);
            return `${timing.clinicname}: ${startTime} - ${endTime}`;
          })
          .join(", ");
      } else {
        return this.doctortimmings
          .filter(
            timing =>
              timing.weekday === day &&
              this.selectedClinics.has(timing.clinicname)
          )
          .map(timing => {
            const startTime = this.convertToAMPM(timing.starttime);
            const endTime = this.convertToAMPM(timing.endtime);
            return `${timing.clinicname}: ${startTime} - ${endTime}`;
          })
          .join(", ");
      }
    },
    convertToAMPM(timeString) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const ampm = hours >= 12 ? "PM" : "AM";
      const hour12 = hours % 12 || 12;
      return `${hour12}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    },
    getColor(clinic) {
      if (!this.clinicColorMap[clinic]) {
        this.clinicColorMap[clinic] = this.clinicColors[
          Object.keys(this.clinicColorMap).length % this.clinicColors.length
        ];

        this.$router.push({
          path: "/patient-details/" + doctorId
        });
      }
    },
    async fetchServerDateTime() {
      try {
        const response = await axios.get("/doctors/datetime");
        const serverData = response.data;
        this.serverDateTime = {
          fullDate: serverData[1], // "YYYY-MM-DD"
          fullTime: serverData[3] // "HH:mm:ss" 24-hour format
        };
        return this.serverDateTime;
      } catch (error) {
        console.error("Error fetching server date and time:", error);
        return null;
      }
    },
    async fetchDoctorData() {
      const doctorIdx = this.$route.params.id;
      this.doctoridx = doctorIdx;
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorIdx}`);
        if (!docDetails) {
          const response = await axios.get(`doctors/${doctorIdx}`);
          docDetails = response.data;

          sessionStorage.setItem(
            `doctorDetails${doctorIdx}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctor = docDetails;
      } catch (error) {
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request data:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
      }
    },
    async fetchScheduleData() {
      try {
        const response = await axios.get(
          `/doctors/${this.doctoridx}/scheduletiming`
        );
        this.doctortimmings = response.data;
        this.clinicOptions = Array.from(
          new Set(this.doctortimmings.map(item => item.clinicname))
        ).map(clinicname => ({
          label: clinicname,
          value: clinicname
        }));
        this.selectedClinic = [...this.clinicOptions];
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    },
    getColor(clinic) {
      if (!this.clinicColorMap[clinic]) {
        this.clinicColorMap[clinic] = this.clinicColors[
          Object.keys(this.clinicColorMap).length % this.clinicColors.length
        ];
      }
      return this.clinicColorMap[clinic];
    },
    nextDay() {
      // if (this.dateRangeData.length > this.selectedIndex + 1)
      //     this.selectedIndex++;
      const scrollAmount =
        window.innerHeight > 768
          ? window.innerHeight * 0.8
          : window.innerHeight * 1;
      this.scrollWindow(scrollAmount);
    },
    prevDay() {
      // if (this.selectedIndex > 0) this.selectedIndex--;s
      const scrollAmount =
        window.innerHeight > 768
          ? window.innerHeight * -0.8
          : window.innerHeight * -1;
      this.scrollWindow(scrollAmount);
    },
    formatTime(time) {
      if (!time) return "";
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    handleTimeSlotHover(timeSlot) {
      this.activeTimeSlot = timeSlot;
    },
    clearActiveTimeSlot() {
      this.activeTimeSlot = null;
    },
    selectTimeSlot(clinic, timeSlot, fullDate) {
      const usertype = sessionStorage.getItem("usertype");
      this.selectedDate = fullDate;
      this.selectedTimeSlot = timeSlot;

      const doctorId = this.$route.params.id;
      const patientId = this.$route.params.parentId;

      const selectedClinic = this.timeslots.find(
        slot => slot.clinic === clinic
      );

      const clinicId = selectedClinic ? selectedClinic.clinicid : null;

      if (this.selectedDate && this.selectedTimeSlot) {
        this.$store.dispatch("updateAppointmentDraft", {
          day: this.selectedDate,
          time: this.selectedTimeSlot,
          clinic: clinicId,
          clinicname: clinic
        });
        const from = this.$route.query.from;

        if (from === "ClinicDashboard") {
          this.$router.push({ path: "/booking-confirm/" });
        } 
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.$forceUpdate);
  },
  // async mounted() {
  //     // await this.fetchDoctorDetails();
  //     await this.fetchPatientProfileImage();
  //     this.$emit('doctor-image', this.profileImageUrl);
  //     eventBus.on('dimageUploaded', this.fetchPatientProfileImage);
  // },
  beforeDestroy() {
    window.removeEventListener("resize", this.$forceUpdate);
  },
  beforeMount() {
    this.fetchDoctorData();
    this.fetchScheduleData();
  },
  setup() {
    const vm = getCurrentInstance().appContext.config.globalProperties;
    const serverDateTime = ref(null);
    let dateRangePicker;
    const dateRangeData = ref([]);
    const timeslots = ref([]);
    const doctorDetails = ref({});
    const doctorSlots = ref(new Map());
    const clinics = ref(new Set());
    const selectedClinics = ref(new Set());
    const apiCalled = ref(false);
    const selectedClinic = ref([]);

    const clinicId = ref(null);

    async function fetchClinicId() {
      try {
        const doctorinfo = vm.$store.getters.getclinicDetails;
        clinicId.value = doctorinfo.clinicId;
      } catch (error) {
        console.error("Error fetching clinicId:", error);
      }
    }

    async function initializePage(startDate, endDate) {
      try {
        await fetchClinicId(); 
        const response = await axios.get(`/clinic/${vm.doctorId}/slots/${clinicId.value}`, {
          params: {
            starttime: startDate,
            endtime: endDate
          }
        });
        const firstAvailableDate = response.data.timeslots[0].day;
        vm.startDate = moment(firstAvailableDate).format("YYYY-MM-DD");
        vm.endDate = moment(firstAvailableDate)
          .add(30, "days")
          .format("YYYY-MM-DD");
        if (!apiCalled.value) {
          let docDetails = sessionStorage.getItem(
            `doctorDetails${vm.doctorId}`
          );
          if (!docDetails) {
            const doctorDetailsResponse = await axios.get(
              `/doctors/${vm.doctorId}`
            );
            docDetails = doctorDetailsResponse.data;
            sessionStorage.setItem(
              `doctorDetails${vm.doctorId}`,
              JSON.stringify(docDetails)
            );
          } else {
            docDetails = JSON.parse(docDetails);
          }

          doctorDetails.value = docDetails;

          apiCalled.value = true;
        }

        timeslots.value = response.data.timeslots;

        dateRangeData.value = generateDateRangeData(vm.startDate, vm.endDate);
        dateRangeData.value.forEach(obj => {
          const validSlots = filteredSlotsByDate(obj.FullDate);

          doctorSlots.value[obj.FullDate] = validSlots ? validSlots : [];
        });
      } catch (error) {
        console.error("Error fetching doctor slots:", error);
      }
    }
    // start of new filter
    function filteredSlotsByDate(selectedFullDate) {
      const currentDate = moment(
        serverDateTime.value.fullDate + " " + serverDateTime.value.fullTime,
        "YYYY-MM-DD HH:mm:ss"
      );
      let validTimeSlots = [];

      timeslots.value.forEach(slot => {
        if (moment(slot.day).isSame(selectedFullDate, "day")) {
          clinics.value.add(slot.clinic);

          if (moment(slot.day).isSame(currentDate, "day")) {
            validTimeSlots.push(
              ...slot.times
                .filter(timeSlot => {
                  if (timeSlot && timeSlot !== "") {
                    const slotTime = moment(
                      slot.day + " " + timeSlot,
                      "YYYY-MM-DD HH:mm"
                    );
                    return slotTime.isValid() && slotTime.isAfter(currentDate);
                  }
                  return false;
                })
                .map(timeSlot => [timeSlot, slot.clinic])
            );
          } else {
            validTimeSlots.push(
              ...slot.times
                .filter(timeSlot => timeSlot !== "")
                .map(timeSlot => [timeSlot, slot.clinic])
            );
          }
        }
      });

      // Sort by clinic name first, then by time
      validTimeSlots.sort((a, b) => {
        if (a[1] < b[1]) return -1;
        if (a[1] > b[1]) return 1;
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      });
      return validTimeSlots;
    }
    // end of new filter

    // start of old filter
    // function filteredSlotsByDate(selectedFullDate) {
    //     const currentDate = moment();

    //     let validTimeSlots = [];

    //     timeslots.value.forEach(slot => {
    //         if (moment(slot.day).isSame(selectedFullDate, "day")) {
    //             clinics.value.add(slot.clinic);

    //             if (moment(slot.day).isSame(currentDate, "day")) {
    //                 validTimeSlots.push(
    //                     ...slot.times
    //                         .filter(timeSlot => {
    //                             if (timeSlot && timeSlot != "") {
    //                                 const slotTime = moment(timeSlot, "HH:mm");
    //                                 if (slotTime.isValid() && slotTime.isAfter(moment())) {
    //                                     return true;
    //                                 } else {
    //                                     return false;
    //                                 }
    //                             } else {
    //                                 return false;
    //                             }
    //                         })
    //                         .map(timeSlot => [timeSlot, slot.clinic])
    //                 );
    //             } else {
    //                 validTimeSlots.push(
    //                     ...slot.times.map(timeSlot => [timeSlot, slot.clinic])
    //                 );
    //             }
    //         }
    //     });
    //     validTimeSlots.sort(function (a, b) {
    //         if (a[0] < b[0]) return -1;
    //         else if (a[0] > b[0]) return 1;
    //         else return 0;
    //     });
    //     console.log("Valid time slots:", validTimeSlots);
    //     return validTimeSlots;
    // }
    // end of old filter
    watch(selectedClinic, newSelectedClinics => {
      const selectedClinicNames = new Set(
        newSelectedClinics.map(clinic => clinic.label)
      );
      dateRangeData.value.forEach(obj => {
        const validSlots = filteredSlotsByDate(obj.FullDate).filter(slot =>
          selectedClinicNames.has(slot[1])
        );

        // Update doctorSlots for the current date
        doctorSlots.value[obj.FullDate] =
          validSlots.length > 0 ? validSlots : [];
      });
      Object.keys(doctorSlots.value).forEach(date => {
        if (!dateRangeData.value.find(obj => obj.FullDate === date)) {
          doctorSlots.value[date] = [];
        }
      });
    });

    onMounted(async () => {
      const { params } = vm.$route;
      if (params && params.id) {
        vm.doctorId = params.id;
      }

      if (document.querySelectorAll(".bookingrange").length > 0) {
        try {
          const response = await axios.get("/doctors/datetime");
          const serverData = response.data;
          serverDateTime.value = {
            fullDate: serverData[1], // "YYYY-MM-DD"
            fullTime: serverData[3] // "HH:mm:ss" 24-hour format
          };
        } catch (error) {
          console.error("Error fetching server date and time:", error);
          return null;
        }
        if (serverDateTime.value) {
          const start = moment(serverDateTime.value.fullDate);
          const end = moment(serverDateTime.value.fullDate).add(30, "days");

          function booking_range(start, end) {
            document.querySelector(".bookingrange span").innerHTML =
              start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
          }

          vm.startDate = start.format("YYYY-MM-DD");
          vm.endDate = end.format("YYYY-MM-DD");

          //console.log("Fetching doctor slots...");
          initializePage(vm.startDate, vm.endDate);

          dateRangePicker = new DateRangePicker(
            document.querySelector(".bookingrange"),
            {
              startDate: start,
              endDate: end,
              ranges: {
                "From today": [
                  moment(serverDateTime.value.fullDate),
                  moment(serverDateTime.value.fullDate).add(30, "days")
                ]
              }
            },
            (startDate, endDate) => {
              vm.startDate = startDate.format("YYYY-MM-DD");
              vm.endDate = endDate.format("YYYY-MM-DD");
              booking_range(startDate, endDate);
              vm.dateRangeData = generateDateRangeData(startDate, endDate);
              //("Fetching doctor slots...");
              initializePage(vm.startDate, vm.endDate);
            }
          );
          vm.doctorSlots = doctorSlots.value;
          //console.log("Doctor slots vm", vm.doctorSlots);
          //console.log("Doctor slots .value", doctorSlots.value);
          vm.clinics = clinics.value;
          selectedClinics.value = clinics.value;
          vm.selectedClinics = clinics.value;
          booking_range(start, end);

          document
            .querySelector(".bookingrange")
            .addEventListener("click", function() {
              const selectedRange = dateRangePicker.chosenLabel;
              //console.log("Selected Range:", selectedRange);
            });
        }
      } else {
        //console.log("Element .bookingrange not found.");
      }
    });
    return {
      dateRangeData,
      timeslots,
      doctorDetails,
      doctorSlots,
      clinics,
      selectedClinics,
      selectedClinic
    };
  }
});
</script>
<style scoped>
@media (max-width: 767px) {
  .clinic-heading {
    width: 100%;
    margin-top: 20px;
    /* margin-left: 20px; */
    text-align: center;
  }
}

@media (max-width: 767px) {
  .clinic-heading h6 {
    font-size: 0.9rem;
    /* margin-right: 50px; */
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .clinic-heading .buttons-container {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .clinic-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 70px;
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .clinic-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    margin-left: 40px;
    margin-bottom: 20px;
    margin-right: 80px;
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .bookingrange {
    width: 70%;
    text-align: left;
    font-size: 0.6rem;
  }
}

@media (min-width: 1024px) {
  .bookingrange {
    font-size: 1.3rem;
  }
}

.clinic-container {
  margin-bottom: 1rem;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

flex-containerz {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flex-containerx {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flex-containery {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.day {
  font-size: 10px;
}

.time {
  font-size: 10px;
  color: #808080;
}

.day.bold {
  font-weight: bold;
}

@media (min-width: 1024px) {
  .rowx {
    margin-bottom: 20px;
    margin-left: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .rowx {
    margin-bottom: 40px;
  }
}

/* @media (min-width: 853px) and (max-width: 1023px) {
    .rowx {
        margin-bottom: 40px;
        margin-left: 35px;
    }
} */

@media (max-width: 767px) {
  .rowx {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

.rowy {
  margin-right: 60px;
}

@media (max-width: 767px) {
  .responsive-date {
    font-size: 1.3rem;
    margin-top: 10px;
  }
}

@media (min-width: 1024px) {
  .responsive-date {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 1.8rem;
    margin-top: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .responsive-date {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 1.7rem;
  }
}

::v-deep .multiselect {
  background-color: #f0f0f0;
  /* Change to your desired background color */
}

::v-deep .multiselect__tag {
  background-color: #eeeeee;
  /* Change to your desired tag background color */
  color: #0f5132;
  /* Change to your desired tag text color */
}

.custom__tag {
  background-color: #0d6efd;
  /* Change to your desired custom tag background color */
  color: white;
  /* Change to your desired custom tag text color */
}

::v-deep .multiselect__placeholder {
  color: #6c757d;
  /* Change to your desired placeholder text color */
}
</style>