<template>
  <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Profile Sidebar -->
      <div class="profile-sidebar">
        <div class="widget-profile pro-widget-content">
          <div class="profile-info-widget">
            <a href="#" class="booking-doc-img">
              <img
                :src="require('@/assets/img/clinic/default-clinic.png')"
                class="img-fluid"
                alt="Doctor Image"
              />
            </a>
            <div class="profile-det-info">
              <h3>{{clinicDetails.clinicname}}</h3>

              <div class="patient-details">
                <span
                  class="qualification-tooltip"
                  :title="`${clinicDetails.area} ${clinicDetails.contactnumber}`"
                >{{ clinicDetails.area }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-widget">
          <nav class="dashboard-menu">
            <ul>
              <li :class="{ active: isActive('/clinic-dashboard') }">
                <router-link to="/clinic-dashboard">
                  <i class="fas fa-columns"></i>
                  <span class="ms-1">Dashboard</span>
                </router-link>
              </li>
              <!-- <li :class="{ active: isActive('/doctor-profile-settings') }">
                <router-link to="/doctor-profile-settings">
                  <i class="fas fa-user-cog"></i>
                  <span class="ms-1">Profile Settings</span>
                </router-link>
              </li> -->
              <li :class="{ active: isActive('/clinic-change-password') }">
                <router-link to="/clinic-change-password">
                  <i class="fas fa-lock"></i>
                  <span class="ms-1">Change Password</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/') }">
                <router-link to @click="signOutUser">
                  <i class="fas fa-sign-out-alt"></i>
                  <span class="ms-1">Logout</span>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- /Profile Sidebar -->
    </div>
  </div>
</template>
<script>
import { signOut } from "aws-amplify/auth";
import axios from "axios";
export default {
  data() {
    return {
      activeClass: "active",
      clinicDetails: []
    };
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
    signOutUser() {
      signOut()
        .then(data => {
          this.$router.push("/login");
        })
        .catch(err => console.log(err));
    },
    async fetchClinicDetails() {
      const clinicuserId = sessionStorage.getItem("userdbid");
      // console.log("doctor id is ", doctorId);
      try {
        // let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        // if (!docDetails) {
          const response = await axios.get(`/clinic/${clinicuserId}`);
          const Details = response.data;
          // sessionStorage.setItem(
          //   `doctorDetails${this.doctorId}`,
          //   JSON.stringify(docDetails)
          // );
        // } else {
        //   docDetails = JSON.parse(docDetails);
        // }
        this.clinicDetails = Details.doctors[0];
        //console.log("Doctor Details:", this.clinicDetails);
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    }
  },
  mounted() {
    this.fetchClinicDetails();
  }
};
</script>
