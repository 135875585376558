<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <!-- Privacy Policy -->
    <div class="terms-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="terms-content pb-0">
              <div class="terms-text">
									<p>Data Privacy and Protection regime in India is presently regulated by the
										Information Technology Act, 2000 (“IT Act 2000”) in conjunction with the
										Information Technology (Reasonable Security Practices and Procedures and
										Sensitive Personal Data or Information) Rules, 2011 (“SPDI Rules”).
									</p>
									<p>You acknowledge that although some Content may be provided by individuals or
										other users of the Services who are in the medical profession, the provision of
										such Content does not create a medical professional/patient relationship, does
										not constitute an opinion, medical advice, diagnosis or treatment nor is it a
										substitute for the same. The Content is provided solely to assist you with
										locating a Healthcare Provider for your medical or dental needs. “Content” means
										content, text, data, graphics, images, photographs, video, audio, information,
										suggestions, guidance, and other materials provided, made available or otherwise
										found through the Services, the Application and/or Site, including without
										limitation Content provided in direct response to your questions or postings.
										Your use of the Content is solely at your own risk.The Content that you obtain
										or receive from Health4U, and its employees, contractors, partners, sponsors,
										advertisers, licensors or otherwise through the Services are purely for
										informational, educational, scheduling and pricing purposes only.We may, but
										have no obligation to have Content posted through the Services reviewed by our
										editorial personnel. It is important to note, however, that the timeliness,
										accuracy, and completeness of any or all of the Content are not guaranteed. We
										will not be responsible for any errors or omissions or for the results obtained
										from the use of such Content.</p>
									<p>Healthcare Providers listed through the Services with whom you may book
										appointments enter into contracts with us and may pay us a fee for the services
										we provide to them through the Services. We will provide you with lists and/or
										profile of Healthcare Providers who you may find suitable to provide the
										healthcare services you need. Notwithstanding the foregoing, we do not: (a)
										recommend or endorse any Healthcare Providers and (b) make any representations
										or warranties with respect to these Healthcare Providers or the quality of the
										healthcare services they may provide.</p>
								</div>
								<div class="terms-text terms-list">
									<p><i class="fas fa-circle-check"></i> This Notice serves to inform you that your
										personal data is being processed by us or on our behalf when you use our
										services in relation to the YourDoc website and related applications
										(“Services”).</p>
									<p><i class="fas fa-circle-check"></i> Your personal data means any information
										relating directly or indirectly to you from which you are identified or are
										identifiable, and includes but is not limited to: Name, Age, Gender, Identity
										Card Number, Date of Birth, Ethnicity, Language, Nationality, and Contact
										information such as address, email, phone number</p>
									<p><i class="fas fa-circle-check"></i> Appointment details: such as the doctors,
										dentists or other healthcare providers (“Healthcare Providers”) you have
										visited, your reasons for visits, your dates of visit, your appointment history
										(such as cost of consultation), medical history and other medical and health
										information. Any additional health, medical or fitness information which is
										generated in the process of your usage of the Services. Additional personal or
										other information that may be relevant for us in order to provide the Services
										to you, or to consider your request for any of our other products.
									<ul><ul>
										<li>To connect you with the Healthcare Provider that you choose to consult.
										</li>
										<li>To identify your location.</li>
										<li>To serve you.</li>
										<li>To provide you with the relevant healthcare services.</li>
										<li>To attend to the relevant transaction if applicable.</li>
										<li>To facilitate our interactions with you.</li>
										<li>To contact you when necessary or requested, including to remind you of an
											upcoming appointment.</li>
										<li>For compliance with legal and regulatory obligations</li>
										<li>To perform core operational services (such as hosting, fulfilment, data
											storage.</li>
										<li>For internal record keeping.</li>
										<li>For internal investigations, audit, or security purposes.</li>
										<li>For the administration and promotion of any events or activities offered by
											us, including the promotion of such events or activities in media or other
											publications.</li>
										<li>To promote the Services and services provided by our related corporations,
											business partners, clients, and sponsors, including providing you with
											information about our other services.</li>
										<li>To investigate your complaints.</li>
										<li>To produce statistical reports, collectively or at individual data level,
											for corporate reporting, research works, and publications.</li>
										<li>To use statistical information that we collect in any way permitted by law,
											including from third parties in connection with their commercial and
											marketing efforts.</li>
										<li>Such other purposes directly related to the foregoing (collectively,
											“Purposes”).</li>
									</ul></ul>
									</p>
									<p>
										Generally, we handle your personal data for the purposes set out in this Notice.
										Any one or more of the listed purposes may apply to your personal data,
										depending on the actual situation. The Purposes above do not purport to be an
										exhaustive listing, although an effort is made to set out as many salient
										purposes as may be applicable. It is obligatory for you to provide your personal
										data to enable us to carry out the above-mentioned purposes, failing which we will not be able to fulfil the Purposes.
									</p>
									<p><i class="fas fa-circle-check"></i>Generally, your personal data was, is being is
										to be collected:
									<ul><ul>
										<li>For internal record keeping.</li>
										<li>For internal investigations, audit, or security purposes.</li>
										<li>For the administration and promotion of any events or activities offered by
											us, including the promotion of such events or activities in media or other
											publications.</li>
										<li>To promote the Services and services provided by our related corporations,
											business partners, clients, and sponsors, including providing you with
											information about our other services.</li>
										<li>To investigate your complaints.</li>
										<li>To produce statistical reports, collectively or at individual data level,
											for corporate reporting, research works, and publications.</li>
										<li>To use statistical information that we collect in any way permitted by law,
											including from third parties in connection with their commercial and
											marketing efforts.</li>
										<li>Such other purposes directly related to the foregoing (collectively,
											“Purposes”).</li>
									</ul></ul>
									</p>
									<p><i class="fas fa-circle-check"></i>In terms of data protection mechanisms:
									<ul><ul>
										<li>Access Control</li>
										<li>Access to your information is restricted to authorized personnel only.</li>
										<li>Data Encryption</li>
										<li>We use encryption protocols to prevent interception by unauthorized parties
											to protect your data both in transmission and at rest.</li>
										<li>Backup and Recovery</li>
										<li>We conduct regular data backups to protect against data loss in case of any
											incidents.</li>
										<li>Data Masking/Anonymization</li>
										<li>Methods on data masking/anonymization such as randomization and encryptions
											are applied to conceal sensitive information.</li>
										<li>Audit Logging</li>
										<li>Logging is implemented to monitor and track access to sensitive data, system
											activities, and configurations to enhance security.</li>
										<li>Security Awareness Training</li>
										<li>We conduct regular training to our employees to enhance the knowledge and
											vigilance of employees in safeguarding sensitive information and ensuring
											the overall security of the organization.</li>
										<li>Incident Response Plan</li>
										<li>In the event of a data loss incident or data breaches, proper procedures
											will be in place to respond promptly. Affected parties, including relevant
											stakeholders and regulatory bodies, will be notified in accordance with
											legal and contractual obligations.</li>
										<li>Data Retention and Disposal</li>
										<ul>
											<li>Data is retained for specific and legitimate purposes, including the
												provision of services, legal compliance, and internal analysis.</li>
											<li>Backup data is also retained for a reasonable period to facilitate
												system restoration and business continuity.</li>
											<li>Procedures are in place to ensure disposal of data is securely and
												permanently deleted.</li>
										</ul>
										<li>You are responsible for ensuring that the information you provide us is
											accurate, complete, not misleading and is kept up to date. If you fail to
											supply us with any of your personal data which is not stated as being
											voluntarily provided to us, we may refuse to process your personal data for
											any of the Purposes, and you will not be able to use the Services.</li>
										<li>We may require your assistance if the personal data relating to other
											persons is required to process your personal data for the Purposes and you
											hereby agree to use your best endeavours to assist us when required.</li>
										<li>Upon your request, you shall have the right to the deletion of your personal
											data and/or to request access to and to request correction of your personal
											data.</li>
										<li>We disclose or may disclose your personal data for the Purposes to our
											related corporations, your employers (where applicable), our business
											partners and sponsors, our logistics partners, our agents and independent
											contractors, our service providers, clients, Healthcare Providers, insurance
											companies, governmental departments and/or agencies, regulatory and/or
											statutory bodies, and any such third party requested or authorized by you or
											as may be required in law.</li>
										<li>We reserve the right to amend this Notice at any time and will place notice
											of such amendments on the Site or via any other mode that we view suitable.
										</li>
										<li>Nothing in this Notice shall limit your rights or our rights under the Act.
										</li>
										<li>In the event of any inconsistency between the English version and any other
											languages of this Notice, the English version shall prevail over any other
											languages.</li>
									</ul></ul>
									</p>
								</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Privacy Policy -->
  </div>
  <indexfooter></indexfooter>
</template>

<script>
export default {
  data() {
    return {
      title: "Privacy Policy",
      text: "Home",
      text1: "Privacy Policy",
    };
  },
};
</script>
