<template>
    <div class="main-wrapper">
        <clinicheader></clinicheader>
        <breadcrumb-clinic :title="title" :text="text" :text1="text1" />
        <div class="content">
            <div class="container">
                <div class="row">
                    <span style="display: flex; justify-content: flex-end;">
                        <label class="custom_check">
                            <input type="checkbox" id="active-appointments" v-model="isActive"
                                @change="toggleActiveStatus" checked />
                            <span class="checkmark"></span>
                            Include Cancelled
                        </label>
                    </span>
                    <doctorsidebar2></doctorsidebar2>

                    <div class="col-md-7 col-lg-8 col-xl-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="col-md-12 col-lg-12 col-xl-12">
                                            <div>
                                                <b-alert v-model="showError" dismissible variant="danger">{{ error }}
                                                </b-alert>
                                            </div>
                                            <form @submit.prevent="submitForm">
                                                <div class="row align-items-center inner-banner" style="margin-left: 1px; margin-right: 1px;">
                          <div class="search-box-one aos">
                            <div class="search-input search-map-lineapphistory">
                              <i class="feather-phone bficon" style="margin-left: 1px;"></i>
                               <div class="form-group mb-0 d-flex align-items-center">
        <!-- Country Code Dropdown -->
          <select v-model="patient.countryCode" class="country-code-select">
        <option v-for="country in allowedCountries" :value="country.dialCode" :key="country.Code">
          ({{ country.dialCode }}) {{ country.code }}
        </option>
      </select>
                                <input v-model="patient.contactNumber" type="text" class="form-control contact-number-input"
                                  placeholder="Contact number" maxlength="12"
                                  :class="{ 'is-invalid': phoneTouched && !validatePhoneNumber() }"
                                  @blur="phoneTouched = true" style="padding-left: 0.5rem;"/>
                              </div>
                            </div>
                                                        <div class="search-input search-map-linex">
                                                            <i class="feather-mail" style="margin-left: 8px;"></i>
                                                            <div class="form-group mb-0" style="margin-left: 8px;">
                                                                <input v-model="patient.email" type="text"
                                                                    class="form-control" placeholder="Patient email"
                                                                    maxlength="45"
                                                                    :class="{ 'is-invalid': emailTouched && !validateEmail() }"
                                                                    @blur="emailTouched = true" />
                                                            </div>
                                                        </div>
                                                        <div class="search-input search-map-linex">
                                                            <i class="feather-user" style="margin-left: 8px;"></i>
                                                            <div class="form-group mb-0" style="margin-left: 8px;">
                                                                <input v-model="patient.name" type="text"
                                                                    class="form-control" placeholder="Patient name"
                                                                    :class="{ 'is-invalid': firstnameTouched && !validatefirstname() }"
                                                                    @blur="firstnameTouched = true" maxlength="45" />
                                                            </div>
                                                        </div>
                                                        <div class="search-input">
                                                            <i class="feather-calendar" style="margin-left: 8px;"></i>
                                                            <div class="form-group mb-0" style="margin-left: 8px;">
                                                                <datepicker v-model="appointmentdate"
                                                                    placeholder="Appt date"
                                                                    class="form-control datetimepicker"
                                                                    :editable="false" :clearable="false"
                                                                    :input-format="dateFormat" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-search-btnx col-12 text-center"
                                                    style="margin-top: 20px; margin-bottom: 35px;">
                                                    <b-button class="btnxr" @click="resetForm">Reset</b-button>
                                                    <b-button class="btn" type="submit"
                                                        style="margin-left: 50px;">Search</b-button>
                                                </div>
                                            </form>
                                            <div v-if="searchPerformed" class="col-md-16 col-lg-17 col-xl-11 mx-auto">
                                                <div class="align-container">
                                                    <div class="left-align">
                                                        <i class="fa fa-long-arrow-left" aria-hidden="true"
                                                            @click="scrollTableLeft"></i>
                                                    </div>
                                                    <div class="right-align">
                                                        <i class="fa fa-long-arrow-right" aria-hidden="true"
                                                            @click="scrollTableRight"></i>
                                                    </div>
                                                </div>
                                                <div class="tab-pane show active" id="today-appointments">
                                                    <div class="card card-table mb-0">
                                                        <div class="card-body">
                                                            <div class="table-responsive" ref="tableContainer">
                                                                <table class="table table-hover table-center mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Patient Information</th>
                                                                            <th>Appt Date & Time</th>
                                                                            <th>Clinic Name</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="appointment in pastAppointmentfilter "
                                                                            :key="appointment.appointmentid">
                                                                            <td>
                                                                                <h2 class="table-avatar">
                                                                                    <router-link
                                                                                        :to="'patient-profile/' + patient.patientid"
                                                                                        class="avatar avatar-sm me-2">
                                                                                        <img class="avatar-img rounded-circle"
                                                                                            :src="getPatientImage(appointment)
                                                                                                " alt="User Image" />
                                                                                    </router-link>
                                                                                    <router-link
                                                                                        :to="'patient-profile/' + appointment.patientid">
                                                                                        {{ appointment.firstname }} {{
                                                                                            appointment.lastname }}
                                                                                        <span>{{ appointment.email
                                                                                            }}</span>
                                                                                    </router-link>
                                                                                </h2>
                                                                            </td>
                                                                            <td>{{
                                                                                formatDate(appointment.appointmentdate)
                                                                            }} {{
                                                                                    formatTime(appointment.appointmenttime)
                                                                                }}</td>
                                                                            <td>
                                                                                <h2><router-link
                                                                                        :to="'patient-profile/' + appointment.patientid">{{
                                                                                            appointment.clinicname
                                                                                        }}<span>{{
                                                                                            appointment.locationname
                                                                                        }}</span></router-link>
                                                                                </h2>
                                                                            </td>
                                                                            <td>
                                                                                <div class="appointment-action">
                                                                                    <a href="javascript:void(0)"
                                                                                        class="btn btn-sm bg-info-light"
                                                                                        data-bs-toggle="modal"
                                                                                        @click="viewAppointmentDetails(appointment)"
                                                                                        data-bs-target="#appt_details">
                                                                                        <i class="far fa-eye"></i> View
                                                                                    </a>
                                                                                     </div>
                                          <div class="status-label mt-2">
                 <template v-if="appointment.appointmentstatus === 'A'">
      <span class="badge rounded-pill bg-warning-light">Absent</span>
    </template>

    <template v-else-if="appointment.appointmentstatus === 'C'">
      <span class="badge rounded-pill bg-danger-light">Cancelled</span>
    </template>
   

    <template v-else-if="appointment.appointmentstatus === 'V' ">
      <span class="badge rounded-pill bg-success-light">Visited</span>
    </template>

    <template v-else-if="appointment.appointmentstatus === 'E' ">
      <span class="badge rounded-pill bg-warning-light">Absent</span>
    </template>
                   </div>
                                        
                                      </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade custom-modal" id="appt_details">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Appointment Details</h5>
                    <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
                </div>
                <div class="modal-body">
                    <ul class="info-details">
                        <li>
                            <div class="details-header">
                                <div class="row">
                                    <div class="col-md-6">
                        <li>
                            <span class="title">Appointment ID:</span>
                           <span class="text">{{ selectedAppointment.slno }}</span>
                        </li>
                        <li>
                            <span class="title">Appointment Date & Time:</span>
                            <span class="text">{{ formatDate(selectedAppointment.appointmentdate) }} {{
                                formatTime(selectedAppointment.appointmenttime) }}</span>
                        </li>

                </div>
               
            </div>
        </div>
        </li>
        <li>
      <span class="title">Status:</span>
      <span class="text">                                          <div class="status-label mt-2">
                 <template v-if="selectedAppointment.appointmentstatus === 'A'">
      <span class="badge rounded-pill bg-warning-light">Absent</span>
    </template>

    <template v-else-if="selectedAppointment.appointmentstatus === 'C'">
      <span class="badge rounded-pill bg-danger-light">Cancelled</span>
    </template>
   

    <template v-else-if="selectedAppointment.appointmentstatus === 'V' ">
      <span class="badge rounded-pill bg-success-light">Visited</span>
    </template>

    <template v-else-if="selectedAppointment.appointmentstatus === 'E' ">
      <span class="badge rounded-pill bg-warning-light">Absent</span>
    </template>
                   </div></span>
    </li>
        <li>
            <span class="title">Confirm Date:</span>
            <span class="text">{{ formatDate(selectedAppointment.createddate) }}</span>
        </li>
        <li>
            <span class="title">Appointment Reason:</span>
            <span class="text">{{ selectedAppointment.reason }}</span>
        </li>
        </ul>
    </div>
    </div>
    </div>
    </div>
    <clinicfooter />
</template>

<script>
import axios from "axios";
import appointment from "@/assets/json/doctor/appointment.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
    data() {
        return {
             allowedCountries: [
          { name: 'India', code: 'IN', dialCode: '+91' },
          { name: 'United States', code: 'US', dialCode: '+1' },
          { name: 'United Kingdom', code: 'GB', dialCode: '+44' },
          { name: 'Canada', code: 'CA', dialCode: '+1' },
          { name: 'Australia', code: 'AU', dialCode: '+61' },
          { name: 'United Arab Emirates', code: 'AE', dialCode: '+971' },
          { name: 'Bahrain', code: 'BH', dialCode: '+973' },
          { name: 'Kuwait', code: 'KW', dialCode: '+965' },
          { name: 'Oman', code: 'OM', dialCode: '+968' },
          { name: 'Qatar', code: 'QA', dialCode: '+974' },
          { name: 'Saudi Arabia', code: 'SA', dialCode: '+966' },
          { name: 'Singapore', code: 'SG', dialCode: '+65' },
          { name: 'Malaysia', code: 'MY', dialCode: '+60' },
          { name: 'New Zealand', code: 'NZ', dialCode: '+64' },
        ],
        patient: {
          countryCode: '+91',
                contactNumber: '',
                email: '',
                name: '',
            },
            dateFormatx: 'yyyy-MM-dd',
            doctorid: null,
            patientid: null,
            searchResults: [],
            schedules: [],
            showError: false,
            error: null,
            firstnameTouched: false,
            lastnameTouched: false,
            emailTouched: false,
            phoneTouched: false,
            searchPerformed: true,
            title: "Appointments History",
            text: "Clinic Dashboard",
            text1: "Appointments History",
            appointment: appointment,
            upcomingAppointments: [],
            pastAppointmentfilter: [],
            selectedAppointment: {},
            isActive: true,
            appointmentdate: null,
            doctorinfo: this.$store.getters.getclinicDetails
        };
    },
    methods: {
        scrollTableRight() {
            const tableContainer = this.$refs.tableContainer;
            tableContainer.scrollBy({
                left: 200,
                behavior: 'smooth'
            });
        },

        scrollTableLeft() {
            const tableContainer = this.$refs.tableContainer;
            tableContainer.scrollBy({
                left: -200,
                behavior: 'smooth'
            });
        },
        resetForm() {
            this.patient.countryCode = '+91';
            this.patient.contactNumber = '';
            this.patient.email = '';
            this.patient.name = '';
            this.appointmentdate = null;
            this.phoneTouched = false;
            this.emailTouched = false;
            this.firstnameTouched = false;
            if (this.schedules.length > 0) {
                this.selectedClinic = this.schedules[0].clinicid;
            }
            this.searchPerformed = false;
        },
        validatefirstname() {
            const firstnameRegex = /^([A-Za-z\s]*)$/
            return firstnameRegex.test(this.patient.name);
        },
        validateEmail() {
            const emailRegex = /^$|^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.[a-zA-Z][a-zA-Z0-9-]{0,45}[a-zA-Z])$/;
            return emailRegex.test(this.patient.email);
        },
        validatePhoneNumber() {
            const phoneNumberRegex = /^(\s*|\d{10,12})$/;
            return phoneNumberRegex.test(this.patient.contactNumber);
        },
        focusInput() {
            this.$refs.dateInput.focus();
        },
        toggleActiveStatus() {
            this.isActive = !this.isActive;
            this.toggle();
        },
        toggle() {
            this.isActive = !this.isActive;
            this.filterPastAppointments();
        },

        formatDate(date) {
            return new Date(date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
            });
        },
        formatDatex(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        async searchPatientAppointments() {
            const doctorId = this.doctorinfo.doctorId
            const clinicId = this.doctorinfo.clinicId
            //console.log("This is clinic id", clinicId);
            this.doctorid = doctorId;
            let datestring
            if (this.appointmentdate != null) {
                datestring = new Date(this.appointmentdate).toLocaleDateString('en-CA').substring(0, 10);
            }
            try {
                const contactNumber=(this.patient.contactNumber)?`${this.patient.countryCode}${this.patient.contactNumber}`:"";
                //console.log("Doctor id is hey", doctorId);
                const response = await axios.get(`/clinic/${clinicId}/pastappointments/${doctorId}`, {
                    params: {
                        name: this.patient.name,
                        contactnumber: contactNumber,
                        email: this.patient.email,
                        date: datestring
                    }
                });
                //console.log("Response from API:", response.data);
                 if (response.data && Array.isArray(response.data.appointmentlist) && response.data.appointmentlist.length > 0) {
                    this.pastAppointmentfilter = response.data.appointmentlist;
                    return true;
                } else {
                    this.pastAppointmentfilter = [];
                    return false;

                }
            } catch (error) {
                console.error("Error searching patient appointments:", error);
            }
        },

        filterPastAppointments() {
            this.pastAppointments = this.pastAppointmentfilter.filter(appointment => {
                return this.isPastAppointment(appointment);
            });
            if (!this.isActive) {
                this.pastAppointmentfilter = this.pastAppointments.filter(
                    appointment => {
                        return appointment.appointmentstatus !== "C";
                    }
                );
            } else {
                this.pastAppointmentfilter = [...this.pastAppointments];
            }
            //console.log("Filtered appointments:", this.filteredAppointments);
        },
        isPastAppointment(appointment) {
            const currentDateTime = new Date();
            const appointmentDateTime = new Date(
                `${appointment.appointmentdate}T${appointment.appointmenttime}`
            );
            return appointmentDateTime < currentDateTime;
        },
        formatTime(time) {
            return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        },
        getPatientImage(appointment) {
            return (
                appointment.patientimage || require("@/assets/img/patients/patient.jpg")
            );
        },
        fetchUpcomingAppointments() {
            const doctorId = this.doctorinfo.doctorId
            this.doctorid = doctorId;
            axios
                .get(`/doctors/${doctorId}/appointments`)
                .then(response => {
                    this.upcomingAppointments = response.data.all_appointments;
                    this.filterPastAppointments();
                })
                .catch(error => {
                    console.error("Error fetching upcoming appointments", error);
                });
        },
        viewAppointmentDetails(appointment) {
            this.selectedAppointment = appointment;
        },
        cancelAppointment(appointment) {
            const {
                appointmentid,
                appointmentby,
                appointmentdate,
                appointmenttime
            } = appointment;
            Swal.fire({
                title: "Are you sure?",
                text: `Do you want to cancel the appointment on ${this.formatDate(
                    appointmentdate
                )} at ${this.formatTime(appointmenttime)}?`,
                input: "text",
                inputLabel: "Reason for cancellation",
                inputPlaceholder: "Enter reason here...",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, cancel it!"
            }).then(result => {
                if (result.isConfirmed) {
                    const cancelReason = result.value;
                    const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointmentid}`;
                    axios
                        .put(cancelApiUrl, { cancelreason: cancelReason })
                        .then(response => {
                            console.log("Appointment cancelled successfully");//, response.data);
                            this.fetchUpcomingAppointments();
                        })
                        .catch(error => {
                            console.error("Error cancelling appointment", error);
                        });
                }
            });
        },
        
        async submitForm() {
            this.showError = false;
            let invalids = [];
            if (!this.patient.contactNumber && !this.patient.email && !this.patient.name && !this.appointmentdate) {
                this.error = "Please enter contact number or email or  appt date or, name";
                this.showError = true;
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }
            if (this.firstnameTouched && !this.validatefirstname()) {
                invalids.push("valid first name");
            }
            if (this.emailTouched && !this.validateEmail()) {
                invalids.push(" valid email");
            }
            if (this.phoneTouched && !this.validatePhoneNumber()) {
                invalids.push("valid Phone number");
            }
            if (invalids.length > 0) {
                this.error = "Please enter " + invalids.join(", ");
                this.showError = true;
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }
            await this.searchPatientAppointments();
            if (this.pastAppointmentfilter.length === 0) {
                this.error = "No records found";
                this.showError = true;
                if (window.innerWidth === screen.width && window.innerHeight === screen.height) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            }
            else {
                this.searchPerformed = true;
                if (window.innerWidth > 767) {
                    window.scrollTo({ top: 200, behavior: "smooth" });
                }
            }
        },
    },
    beforeMount() {
    this.searchPatientAppointments();
  },  
    name: "appointments"
};
</script>
<style scoped>
 .contact-number-input {
    width: 300px; 
    padding-left: 0.5rem;
  }
  .clinic-select {
    width: 150px; 
    padding: 5px;
  }
.country-code-select {
  border: 1px solid #ced4da; 
  border-radius: 4px;
  
  font-size: 14px; 
  color: #495057; 
  height: 32px;
  width: 62px;
  margin-left: 20px;
}
.modal-content .btn {
    cursor: auto;
}

.left-align {
    text-align: left;
    font-size: 24px;
    margin-left: 10px;
    cursor: pointer;
}

.align-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right-align {
    text-align: right;
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
}
</style>