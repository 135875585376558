<template>
  <!-- Home Banner -->
  <section class="banner-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="banner-content aos" data-aos="fade-up">
            <h1>
              {{ $t("home_t1") }}
              <span>{{ $t("home_t1_1") }}</span>
              {{ $t("home_t1_2") }}
              <span>{{ $t("home_t1_3") }}</span>
            </h1>
            <img src="@/assets/img/icons/header-icon.svg" class="header-icon" alt="header-icon" />
            <p>{{ $t("home_t2") }}</p>
          </div>
          <div class="search-box-one aos" data-aos="fade-up">
            <form @submit.prevent="submitForm">
              <div>
                <b-alert v-model="showAlert" variant="danger" dismissible>{{ alertMessage }}</b-alert>
              </div>
              <div class="search-input search-line">
                <i class="feather-search bficon"></i>

                <div class="form-group mb-0" style="position: relative;">
                  <input
                    v-model="searchQuery.searchText"
                    type="text"
                    class="form-control"
                    :placeholder="$t('home_srchdoc_placeholder1')"
                    @input="onInput"
                    @blur="hideSuggestions"
                    @focus="DisplaydefaultList"
                  />
                  <!-- Suggestions Dropdown -->
                  <ul v-if="showSuggestions && !defaultList" class="suggestions-list">
                    <li
                      v-for="item in filteredSuggestions"
                      :key="item.id"
                      @click="selectSuggestion(item)"
                    >
                      {{ item.Title || item.name }}
                      <small
                        v-if="item.Title"
                        class="suggestion-type"
                      >{{ $t("home_srchdoc_suggestion_speciality") }}</small>
                      <small
                        v-else-if="item.type === 'Doctor'"
                        class="suggestion-type"
                      >{{ $t("home_srchdoc_suggestion_doctor") }}</small>
                      <small
                        v-else
                        class="suggestion-type"
                      >{{ $t("home_srchdoc_suggestion_clinic") }}</small>
                    </li>
                  </ul>

                  <!-- Hard-coded List -->
                  <ul v-if="defaultList" class="suggestions-list">
                    <li
                      v-for="item in defaultSuggestion"
                      :key="item.id"
                      @click="selectSuggestion(item)"
                    >
                      {{ item.name }}
                      <small
                        v-if="item.type === 'Doctor'"
                        class="suggestion-type"
                      >Top Searched Doctor</small>
                      <small
                        v-else-if="item.type === 'Clinic'"
                        class="suggestion-type"
                      >Top Searched Clinic</small>
                      <small v-else class="suggestion-type">Top Searched Specaility</small>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="search-input search-map-line">
                <i class="feather-map-pin"></i>
                <div class="form-group mb-0">
                  <input
                    v-model="searchQuery.area"
                    type="text"
                    class="form-control"
                    :placeholder="$t('home_srchdoc_placeholder2')"
                    @input="filterCities"
                    @blur="hideCitySuggestions"
                    @focus="DisplaycityList"
                  />
                  <!-- City Suggestions Dropdown -->
                  <ul v-if="showCitySuggestions && !defaultcityList" class="suggestions-list">
                    <li
                      v-for="city in filteredCities"
                      :key="city.id"
                      @click="selectCitySuggestion(city)"
                    >{{ city.City }}</li>
                  </ul>

                  <!-- Hard-coded List -->
                  <ul v-if="defaultcityList" class="suggestions-list">
                    <li
                      v-for="city in defaultcitySuggestion"
                      :key="city.id"
                      @click="selectCitySuggestion(city)"
                    >{{ city.City }}</li>
                  </ul>
                </div>
              </div>

              <div class="form-search-btn">
                <b-button class="btn" type="submit">{{ $t("home_srchdoc_searchbtn") }}</b-button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="banner-img aos" data-aos="fade-up">
            <img src="@/assets/img/banner-health.png" class="img-fluid" alt="Image" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Home Banner -->

  <!-- Specialities Section -->
  <section class="specialities-section-one">
    <div class="container">
      <div class="row">
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="section-header-one section-header-slider">
            <h2 class="section-title">{{ $t("home_specialist_title") }}</h2>
          </div>
        </div>
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="owl-nav slide-nav-1 text-end nav-control"></div>
        </div>
      </div>
      <div class="owl-carousel specialities-slider-one owl-theme aos" data-aos="fade-up">
        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="item in IndexBanner" :key="item.id">
            <div class="item slide-content-container">
              <a href="javascript:void(0)" @click="searchby(item.Title)">
                <div class="specialities-item">
                  <div class="specialities-img">
                    <span>
                      <img
                        :src="require(`@/assets/img/specialities/${item.Image}`)"
                        alt="specialities"
                      />
                    </span>
                  </div>
                  <p>{{ item.Title }}</p>
                </div>
              </a>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
      
    </div>
  </section>
  <!-- /Specialities Section -->

  <!-- Symptoms Section -->
  <section class="specialities-section-one">
    <div class="container">
      <div class="row">
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="section-header-one section-header-slider">
            <h2 class="section-title">{{ $t("home_symptoms_title") }}</h2>
          </div>
        </div>
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="owl-nav slide-nav-1 text-end nav-control"></div>
        </div>
      </div>
      <div class="owl-carousel specialities-slider-one owl-theme aos" data-aos="fade-up">
        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="symptom in Symptoms" :key="symptom.id">
            <div class="item slide-content-container">
              <a href="javascript:void(0)" @click="searchby(symptom.Search)">
                <div class="specialities-item">
                  <div class="specialities-img">
                    <span>
                      <img :src="require(`@/assets/img/Symptoms/${symptom.Image}`)" alt="symptoms" />
                    </span>
                  </div>
                  <p>{{ symptom.Title }}</p>
                </div>
              </a>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
  <!-- /Symptoms Section -->

</template>
<script>
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexBanner from "@/assets/json/indexbanner.json";
import symptoms from "@/assets/json/symptoms.json";
import specializations from "@/assets/json/specialization_settings_doctor.json";
import cities from "@/assets/json/cities.json";
import "vue3-carousel/dist/carousel.css";
import { ref } from "vue";
import debounce from "lodash.debounce";

const currentDate = ref(new Date());

export default {
  data() {
    return {
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      IndexBanner: IndexBanner,
      Symptoms: symptoms,
      searchQuery: {
        searchText: "",
        area: "Mangalore"
      },
      settings: {
        itemsToShow: 1,
        snapAlign: "center"
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center"
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center"
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center"
        },
        1024: {
          itemsToShow: 6,
          snapAlign: "start"
        }
      },
      specializations: specializations,
      filteredSuggestions: [],
      showSuggestions: false,
      cities: cities,
      filteredCities: [],
      showCitySuggestions: false,
      doctors: [],
      clinics: [],
      defaultList: false,
      defaultSuggestion: [
        { id: 1, name: " General Medicine", type: "Specaility" },
        { id: 2, name: " Cardiology", type: "Specaility" },
        { id: 3, name: " Dentist ", type: "Specaility" },
        { id: 4, name: " Orthopedic", type: "Specaility" },
        { id: 5, name: " ENT", type: "Specaility" }
      ],
      defaultcityList: false,
      defaultcitySuggestion: [
        { id: 1, City: " Mangalore" },
        { id: 2, City: " Udupi" },
        { id: 3, City: " Kasaragod " },
        { id: 4, City: " Kundapura" },
        { id: 5, City: " Surathkal" }
      ],
      showAlert: false,
      alertMessage: "",
      hideSuggestionsTimeout: null
    };
  },
  mounted() {
    this.fetchDoctorNames();
    this.$nextTick(() => {
      AOS.init();
    });
  },
  methods: {
    searchby(title) {
      const searchParams = {
        filter: title.trim(),
      };
      this.$router.push({
        name: "search",
        query: searchParams
      });

    },
    submitForm() {
      let { searchText, area } = this.searchQuery;
      searchText = searchText.trim()
        // searchText.trim().length === 0 ? "General" : searchText.trim();
      area =  area.trim();
          //area.trim().length === 0 ? "Mangalore" : area.trim();
      const searchParams = {
        filter: searchText,
        city: area,
      };

      this.$router.push({
        name: "search",
        query: searchParams
      });
    },
    async fetchDoctorNames() {
      try {
        const response = await axios.get("/doctors/namelist");
        this.doctors = response.data.doctors.map((doctor, index) => ({
          id: index,
          name: doctor.name,
          type: "Doctor"
        }));
        this.clinics = response.data.clinics.map((clinic, index) => ({
          id: index,
          name: clinic.name,
          type: "Clinic"
        }));
      } catch (error) {
        console.error("Error fetching doctor and clinic names:", error);
      }
    },
    filterSuggestions() {
      const query = this.searchQuery.searchText.toLowerCase();
      if (query.length > 0) {
        this.filteredSuggestions = [];
        const filteredSpecialities = this.specializations.filter(item =>
          item.Title.toLowerCase().includes(query)
        );
        const filteredDoctors = this.doctors.filter(
          doctor => doctor.name && doctor.name.toLowerCase().includes(query)
        );
        const filteredClinics = this.clinics.filter(
          clinic => clinic.name && clinic.name.toLowerCase().includes(query)
        );

        this.filteredSuggestions = [
          ...filteredSpecialities,
          ...filteredDoctors,
          ...filteredClinics
        ];
        this.showSuggestions = true;
        this.defaultList = false;
      } else {
        this.filteredSuggestions = [];
        this.showSuggestions = false;
        this.defaultList = true;
      }
    },
    onInput: debounce(function() {
      this.filterSuggestions();
    }, 300),
    selectSuggestion(item) {
      this.searchQuery.searchText = item.Title || item.name;
      this.showSuggestions = false;
      this.defaultList = false;
      this.searchKey = item.Title || item.name;
      this.City = this.searchQuery.area;
       const searchParams = {
        filter: this.searchKey.trim(),
        city: this.City.trim()  
      };
     // this.$router.push({
       // name: "search",
        //query: searchParams
     // });
    },
    filterCities() {
      if (this.searchQuery.area.length > 0) {
        this.filteredCities = this.cities.filter(city =>
          city.City.toLowerCase().includes(this.searchQuery.area.toLowerCase())
        );
        this.showCitySuggestions = true;
        this.defaultcityList = false;
      } else {
        this.showCitySuggestions = false;
        this.defaultcityList = true;
      }
    },
    selectCitySuggestion(city) {
      this.searchQuery.area = city.City;
      this.showCitySuggestions = false;
      this.defaultcityList = false;
    },
    DisplaydefaultList() {
      this.defaultList = true;
      this.showSuggestions = false;
    },
    DisplaycityList() {
      this.defaultcityList = true;
      this.showCitySuggestions = false;
    },
    hideSuggestions() {
      //console.log("check timeout is ", this.hideSuggestionsTimeout)
      this.hideSuggestionsTimeout = setTimeout(() => {
        this.showSuggestions = false;
        this.defaultList = false;
      }, 400); // Timeout to allow click event to register
    },
    hideCitySuggestions() {
      setTimeout(() => {
        this.showCitySuggestions = false;
        this.defaultcityList = false;
      }, 300);
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  }
};
</script>
<style>
.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 10000;
  max-height: 120px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.suggestion-type {
  font-size: 12px;
  color: #999;
}
</style>
