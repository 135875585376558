<template>
    <div class="main-wrapper">
        <layoutheader></layoutheader>
        <!-- <breadcrumb :title="title" :text="text" :text1="text1" /> -->
        <div class="page-wrapper">
            <div class="content">
                <div class="container" style="margin-top:40px">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12 text-center">
                            <div class="cancellation-info">
                                <div class="cancellation-content text-center">
                                    <h3 v-if="!displayToken">Check-in Details</h3>
                                    <h3 v-if="displayToken" style="margin-bottom: 35px">Token number is generated</h3>
                                    <b-alert v-model="showSuccessDeny" variant="danger">{{
                                        denyMessage }}
                                    </b-alert>
                                    <b-alert v-model="showSuccessAlert" variant="success" dismissible>{{
                                        successMessage
                                    }}</b-alert>
                                    <template v-if="displayToken">
                                        <div class="icon-container">

                                            <i class="fa-solid fa-circle" style="color: #63E6BE;"></i>
                                            <span class="icon-number">{{ tokenid }}</span>

                                        </div>
                                    </template>
                                    <template v-if="!otpSent">
                                        <div class="appointment-details">
                                            <!-- clarance -->
                                            <div class="detail-item">
                                                <span class="label">
                                                    <strong>Patient name:</strong>
                                                </span>
                                                <span class="value">{{ appointmentDetails.patient_name }}</span>
                                            </div>
                                            <div class="detail-item">
                                                <span class="label">
                                                    <strong>Doctor:</strong>
                                                </span>
                                                <span class="value">{{ appointmentDetails.doctorname
                                                    }}</span>
                                            </div>
                                            <div class="detail-item">
                                                <span class="label">
                                                    <strong>Appointment date:</strong>
                                                </span>
                                                <span class="value">{{ formatedDate(appointmentDetails.appointmentdate)
                                                    }}</span>
                                            </div>
                                            <div class="detail-item">
                                                <span class="label">
                                                    <strong>Appointment time:</strong>
                                                </span>
                                                <span class="value">{{ formatedTime(appointmentDetails.appointmenttime)
                                                    }}</span>
                                            </div>
                                            <div class="detail-item">
                                                <span class="label">
                                                    <strong>Clinic:</strong>
                                                </span>
                                                <span class="value">{{ appointmentDetails.clinicname }}</span>
                                            </div>
                                        </div>
                                        <div v-if="appointmentDetails.appointmentstatus !== 'C'">
                                            <button v-if="!displayToken" @click="generateToken"
                                                class="btn btn-secondary">Confirm
                                                Check-in</button>
                                            <!-- style="margin-right: 10px" -->
                                            <!-- <button @click="generateToken" class="btn btn-primary submit-btn">Confirm
                                                Check-in</button> -->
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <indexfooter></indexfooter>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            title: "Check-in Details",
            text: "Home",
            text1: "Check-in Details",
            patientId: null,
            appointmentId: null,
            appointmentDetails: [],
            reason: "",
            otp: "",
            loading: false,
            otpSent: false,
            error: null,
            showError: false,
            countDown: 30,
            email: "",
            phone: "",
            maskedEmail: "",
            slno: 16,
            patient_id: 11,
            detailed: true,
            doctorid: null,
            showSuccessAlert: false,
            successMessage: "",
            showSuccessDeny: false,
            denyMessage: "",
            displayToken: false,
            tokenDetails: {},
            tokenid: 0,

        };
    },

    methods: {
        formatedTime(time) {
            return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        },
        formatedDate(date) {
            return new Date(date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
            });
        },

        async generateToken() {
            try {
                const response = await axios.post(`patients/${this.patient_id}/appointments/${this.slno}/tokens`);
                this.appointmentDetails = response.data;
                setTimeout(() => {
                    this.showSuccessAlert = true;
                    this.successMessage = "Token generated successfully.";
                    setTimeout(() => {
                        this.showSuccessAlert = false;
                        this.successMessage = "";
                    }, 10000);
                }, 0);
                this.fetchTokens();
                this.fetchAppointmentDetails();
            } catch (error) {
                console.error("Error generating tokens:", error);
                setTimeout(() => {
                    this.showSuccessDeny = true;
                    this.denyMessage = "Tokens can only be generated 30 minutes before the appointment time.";
                    setTimeout(() => {
                        this.showSuccessDeny = false;
                        this.denyMessage = "";
                    }, 10000);
                }, 0);
            }
        },
        async fetchTokens() {
            try {
                const response = await axios.get(`patients/${this.patient_id}/appointments/${this.slno}/tokens`);
                this.tokenDetails = response.data;
                this.tokenid = this.tokenDetails[0].tokenid
                this.displayToken = true;
            } catch (error) {
                console.error("Error fetching tokens:", error);
                this.displayToken = false;
            }
        },
        async fetchAppointmentDetails() {
            try {
                const response = await axios.get(`patients/${this.patient_id}/appointments/${this.slno}`,
                    {
                        params: {
                            patient_id: this.patient_id,
                            detailed: this.detailed
                        }
                    });
                this.appointmentDetails = response.data;
            } catch (error) {
                console.error("Error fetching appointment details:", error);
            }
        },
    },
    mounted() {
        this.fetchTokens();
        this.fetchAppointmentDetails();
    }
};
</script>

<style scoped>
.appointment-cancellation-section {
    padding: 60px 0;
}

.cancellation-content {
    margin-top: 20px;
    background: #f8f9fa;
    padding: 30px;
    border-radius: 5px;
}

.appointment-details {
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detail-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    margin: 10px 0;
}

.label {
    flex: 1;
    text-align: left;
}

.value {
    flex: 1;
    text-align: right;
}

.form-group {
    margin-bottom: 20px;
}

.account-content {
    background: #f8f9fa;
    padding: 30px;
    border-radius: 5px;
    position: relative;
}

.reset-btn .btn {
    margin: 0 10px;
}

.reset-btn {
    display: flex;
    justify-content: center;
}

.icon-container {
    position: relative;
    display: inline-block;
    width: 13vw;
    height: 13vw;

}

.icon-container i {
    font-size: 13vw;
}

.icon-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 6vw;
    font-weight: bold;
}

@media (max-width: 600px) {
    .icon-container {
        width: 30vw;
        height: 30vw;
    }

    .icon-container i {
        font-size: 30vw;
    }

    .icon-number {
        font-size: 12vw;
    }
}
</style>