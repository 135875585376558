
<template>
  <!-- Monday Slot -->
  <div id="slot_tuesday" class="tab-pane fade">
    <h4 class="card-title d-flex justify-content-between">
      <span>Time Slots</span>
    </h4>
    <!-- Outer loop for each clinic -->
    <div class="schedule-header" v-for="(clinicData, clinicIndex) in groupByClinic(scheduleData)" :key="clinicIndex">
      <h4 class="card-title d-flex justify-content-between">
        <span>{{ clinicData[0].clinicname }}</span>
        <router-link class="edit-link" data-bs-toggle="modal" @click="openEditModal(clinicData)" to="#edit_time_slot">
          <i class="fa fa-edit me-1"></i>Edit
        </router-link>
      </h4>

      <!-- Slot List -->
      <div class="doc-times">
        <!-- Inner loop for each slot in the clinic -->
        <div v-for="(slot, index) in clinicData" :key="index" class="doc-slot-list">
          {{ formatTime(slot.starttime) }} - {{ formatTime(slot.endtime) }}
        </div>
      </div>
      <!-- /Slot List -->

      <!-- Add a horizontal line between clinics -->
    </div>
    <!-- /Outer loop -->

    <!-- Add Clinic Button -->
    <!-- <router-link  class="btn btn-primary mt-3 editlink" @click="openAddClinicModal" to="#add_time_slot">Add Clinic</router-link>
     -->
     <a v-if="shouldShowAddClinicButton" class="edit-link" data-bs-toggle="modal" href="#add_time_slot" @click="openAddClinicModal('Tuesday')"><i class="fa fa-plus-circle"></i>Add Clinic</a>
  </div>
  <!-- /Monday Slot -->
</template>

<script>
export default {

  props: {
    scheduleData: Array,
  },
  computed: {
    shouldShowAddClinicButton() {      
      return this.groupByClinic(this.scheduleData).length < 3;
    },
  },
  methods: {
    formatTime(time) {
    if (!time) {
      return '';
    }

    const [hours, minutes] = time.split(':');
    const formattedHours = hours % 12 || 12;
    const period = hours < 12 ? 'AM' : 'PM';
    return `${formattedHours}:${minutes} ${period}`;
  },
    openEditModal(clinicData) {
      this.$emit('open-edit-modal', clinicData);
    },
    openAddClinicModal(weekday) {
      this.$emit('open-add-clinic-modal', weekday);
    },
    groupByClinic(data) {
      if (!data || !Array.isArray(data)) {
        return [];
      }

  const groupedData = {};
  data.forEach(slot => {
    const clinicId = slot.clinicid;
    if (!groupedData[clinicId]) {
      groupedData[clinicId] = [];
    }
    groupedData[clinicId].push(slot);
  });

  return Object.values(groupedData);
},
    
  },
};
</script>
