<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <!--variant in [primary, secondary, warning, danger, success, info, light, dark]-->
                    <b-alert v-model="showDismissibleAlert" variant="danger" >{{error}}</b-alert>
                    <b-alert v-model="showDismissibleSuccess" variant="success" >{{error}}</b-alert>
                    <!-- Change Password Form -->
                    <form @submit.prevent="handleUpdatePassword">
                      <div class="mb-3">
                        <label class="mb-2">Old Password</label>
                        <input type="password" class="form-control"  v-model="oldpwd"/>
                      </div>
                      <div class="mb-3">
                        <label class="mb-2">New Password</label>
                        <input type="password" class="form-control"  v-model="newpwd"/>
                      </div>
                      <div class="mb-3">
                        <label class="mb-2">Confirm Password</label>
                        <input type="password" class="form-control"  v-model="confirmpwd"/>
                      </div>
                      <div class="submit-section">
                        <b-button type="submit" class="btn btn-primary submit-btn">
                          Save Changes
                        </b-button>
                      </div>
                    </form>
                    <!-- /Change Password Form -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <doctorfooter />
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { updatePassword } from 'aws-amplify/auth';

export default {
  data() {
    return {
      title: "Change Password",
      text: "Home",
      text1: "Change Password",
      oldpwd: '',
      newpwd: '',
      confirmpwd: '',
      error: null,
      showDismissibleAlert: false,
      showDismissibleSuccess: false,
    };
  },
  methods: {
    showmessage(message, type) {
      this.error = message;
      this.showDismissibleAlert = (type === "danger");
      this.showDismissibleSuccess = !this.showDismissibleAlert;
    },
    min8: function(value) {
      if (value && value.length >= 8) {
        return true;
      } else {
        this.showmessage('Password should have atleast 8 characters.', 'danger');
        return false;
      }
    },
    matchingPasswords: function() {
      if (this.newpwd === this.confirmpwd) {
        return true;
      } else {
        this.showmessage('Passwords do not match.', 'danger');
        return false;
      }
    },
    async handleUpdatePassword() {
      if (!this.min8(this.newpwd)) return;
      if (!this.matchingPasswords()) return;
      this.showDismissibleAlert = false;
      try {
        await updatePassword({ oldPassword: this.oldpwd, newPassword: this.newpwd });
        this.showmessage("Password updated successfully", 'success');
        this.oldpwd = '';
        this.newpwd = '';
        this.confirmpwd = '';
      } catch (err) {
        console.log(err);
        this.showmessage(err.message, 'danger');
      }
    },
  },
};
</script>
