<template>
  <div class="tab-pane fade" id="medical">
    <div class="text-end">
      <a
        href="javascript:void(0)"
        class="add-new-btn"
        data-bs-toggle="modal"
        data-bs-target="#add_medical_records"
        >Add Medical Records</a
      >
    </div>
    <div class="card card-table mb-0">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Report Name</th>
                <!-- <th>Attachment</th> -->
                <th>Orderedby</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in medical_records" :key="item.id">
                <td>
                  {{item.reportid}}
                </td>
                <td>{{ formatDate(item.testdate) }}</td>
                <td>{{ item.testname }}</td>
                <!-- <td>
                  <a href="javascript:void(0)">{{ item.attachment }}</a>
                </td> -->
                
                  <!-- <h2 class="table-avatar">
                    <router-link to="doctor-profile" class="avatar avatar-sm me-2">
                      <img
                        class="avatar-img rounded-circle"
                        
                        alt="User Image"
                      />
                    </router-link> -->
                    
                <td>{{ item.orderby_doctor }}</td>
                  <!-- </h2> -->
                <td class="text-end">
                  <div class="table-action">
                    <a
                      href="javascript:void(0);"
                      :title="item.testname"
                      class="btn btn-primary btn-sm me-2"
                      @click="viewRecord(item.reportid, item.patientid, item.testname)"
                    >
                      <i class="fa fa-eye"></i> View
                    </a>
                    <a
                    href="javascript:void(0);"
                    title="Download attachment"
                    class="btn btn-primary btn-sm me-2"
                    @click="downloadRecord(item.reportid, item.patientid, item.testname)"
                  >
                    <i class="fa fa-download" ></i> Download
                  </a>
                  <a href="javascript:void(0);" class="btn btn-sm bg-danger-light"
                    @click="deleteRecord(item.reportid,item.testname)">
                    <i class="far fa-trash-alt"></i> Delete
                  </a>
                    <router-link
                      to="edit-prescription"
                      :class="item.class"
                      data-bs-toggle="modal"
                      data-bs-target="#add_medical_records"
                    >
                      <i :class="item.class3"></i>{{ item.edit }}
                    </router-link>

                    <a href="javascript:void(0);" :class="item.class1">
                      <i :class="item.class4"></i> {{ item.delete }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dmedicalrecords from "@/assets/json/doctor/dmedicalrecords.json";
import axios from "axios";
import Swal from "sweetalert2";
export default {

  props: {
    patientId: {
      type: String,
      required: true,
    },
    doctorId: {
      type: String,
      required: true,
    },
  },
  
  data() {
    return {
      dmedicalrecords: dmedicalrecords,
      medical_records: [],
    };
  },
   mounted() {
    this.fetchMedicalRecords();
  },
  methods:{
    fetchMedicalRecords() {
      
      axios.get(`/patients/reports/${this.patientId}/${this.doctorId}`)
        .then(response => {
          this.medical_records = response.data;
        })
        .catch(error => {
          console.error('Error fetching medical records:', error);
        });
    },
     viewRecord(reportId, patientId, reportName) {
      axios({
        method: "get",
        url: `/patients/${patientId}/report/${reportId}/download`,
        responseType: "blob"
      })
        .then(response => {
          const fileExtension = this.getFileExtension(reportId);

          if (fileExtension === 'pdf') {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

            // Open the PDF in a new tab
            const newWindow = window.open();
            newWindow.document.write(`<html><head><title>${reportName}</title></head><body style="margin:0"><embed width="100%" height="100%" src="${fileURL}" type="application/pdf" /></body></html>`);
          } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'image/jpeg' }));
            window.open(fileURL, "_blank");
          } else {
            // If it's not a PDF or image, fall back to downloading the file
            this.downloadRecord(reportId, patientId, reportName);
          }
        })
        .catch(error => {
          console.error("Error viewing medical record:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to view the record!"
          });
        });
    },
    deleteRecord(reportId, reportName) {
      Swal.fire({
        title: "Confirm Deletion",
        text: `Are you sure you want to delete the record "${reportName}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`patients/deletereport/${reportId}`)
            .then(response => {
              //console.log(response.data.message);
              this.fetchMedicalRecords();
            })
            .catch(error => {
              console.error("Error deleting medical record:", error);
            });
        }
      });
    },
    downloadRecord(reportId, patientId, reportName) {
      axios({
        method: "get",
        url: `/patients/${patientId}/report/${reportId}/download`,
        responseType: "blob"
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const fileExtension = this.getFileExtension(reportId);
          const fullReportName = `${reportName}.${fileExtension}`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fullReportName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error("Error downloading medical record:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to download the record!"
          });
        });
    },
    getFileExtension(reportId) {
      const record = this.medical_records.find(
        item => item.reportid === reportId
      );
      if (record && record.testfile_path) {
        return record.testfile_path.split(".").pop();
      }
      return "txt";
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
    },
  }
};
</script>
