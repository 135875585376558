<template>
  <div
    class="modal fade custom-modal custom-medicalrecord-modal"
    id="patient_accesss_modal"
    style="display: none"
    data-select2-id="patient_accesss_modal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Reports Access Management</h5>
          <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
        </div>
        <b-alert v-model="showSuccessAlert" variant="success" dismissible>{{ successMessage }}</b-alert>
        <b-alert v-model="showErrorAlert" variant="danger" dismissible>{{ errorMessage }}</b-alert>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col">
                  <label class="mb-2 report-id">Report ID:</label>
                  <span class="report-id">{{ reportid }}</span>
                </div>
              </div>
              <label class="mb-2">Select Doctor</label>
              <multiselect
                v-model="selectedDoctor"
                :options="filteredDoctors"
                track-by="id"
                label="name"
                placeholder="Select doctor"
                :searchable="true"
                :close-on-select="false"
                @select="selectDoctor"
                :key="selectedDoctor ? selectedDoctor.id : 'multiselectKey'"
              ></multiselect>
            </div>
          </div>
          <div class="row mt-3" v-if="showPermissionButton">
            <div class="col-12">
              <b-button @click="givePermission" variant="primary">Give Permission</b-button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <h5>Selected Doctors:</h5>
              <ul class="list-group">
                <li class="list-group-item" v-for="(doctor) in selectedDoctors" :key="doctor.id">
                  <div class="row align-items-center">
                    <div class="col">{{ doctor.name }}</div>
                    <div class="col-auto">
                      <b-button @click="removePermission(doctor)" variant="danger">Remove Permission</b-button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import { ref, computed } from "vue";

export default {
  components: {
    Multiselect
  },
  props: {
    reportid: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      doctors: [],
      selectedDoctor: null,
      selectedDoctors: [],
      showPermissionButton: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: "",
      errorMessage: ""
    };
  },
  created() {
    this.fetchDoctors();
    this.fetchReportAccess();
  },
  watch: {
    reportid(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchReportAccess();
        if (newVal !== null) {
         // console.log("Report ID received:", newVal);
        }
      }
    }
  },
  computed: {
    filteredDoctors() {
      return this.doctors.filter(
        doctor => !this.selectedDoctors.some(selected => selected.id === doctor.id)
      );
  },
  },
  methods: {
    fetchReportAccess() {
      this.selectedDoctors = [];
      axios
        .get(`/patients/${this.reportid}/reportaccess`)
        .then(response => {
          this.selectedDoctors = response.data.map(item => ({
            id: item.PatientReportsAccess.doctorid,
            name: item.doctorname
          }));
        })
        .catch(error => {
          console.error("Error fetching report access:", error);
        });
    },
    fetchDoctors() {
      axios
        .get("/doctors")
        .then(response => {
          this.doctors = response.data.map(doctor => ({
            id: doctor.doctorid,
            name: doctor.doctorname
          }));
        })
        .catch(error => {
          console.error("Error fetching doctors:", error);
        });
    },
    selectDoctor() {
      this.showPermissionButton = true;
    },
    showSuccessAlertWithTimer(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 5000); 
    },

    showErrorAlertWithTimer(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 5000); 
    },
    givePermission() {
      if (this.selectedDoctor && this.reportid) {
        const accessDetails = {
          doctorid: this.selectedDoctor.id
        };

        axios
          .post(`/patients/${this.reportid}/reportaccess`, accessDetails)
          .then(response => {
            if (
              response.status === 200 &&
              response.data.message === "Access granted successfully"
            ) {
              console.log("Access granted successfully"); //, response.data);
              this.selectedDoctors.push(this.selectedDoctor);
              this.selectedDoctor = null;
              this.showPermissionButton = false;
              this.showSuccessAlert = true;
              this.showSuccessAlertWithTimer("Permission granted successfully");
            } else {
              console.error("Error granting access:", response.data.message);
            }
          })
          .catch(error => {
            console.error("Error granting access:", error);
            this.showErrorAlert = true;
            this.showErrorAlertWithTimer("Unable to grant permission");
          });
      }
    },

    removePermission(doctor) {
      if (this.reportid && doctor) {
        const accessDetails = {
          doctorid: doctor.id
        };

        axios
          .delete(`/patients/${this.reportid}/reportaccess`, {
            data: accessDetails
          })
          .then(response => {
            if (
              response.status === 200 &&
              response.data.message === "Access has been removed  successfully"
            ) {
              this.showSuccessAlert = true;
              this.showSuccessAlertWithTimer("Permission removed successfully");
              console.log("Access removed successfully"); //, response.data);

              const index = this.selectedDoctors.findIndex(
                d => d.id === doctor.id
              );
              if (index !== -1) {
                this.selectedDoctors.splice(index, 1);
              }
            } else {
              console.error("Error removing access:", response.data.message);
            }
          })
          .catch(error => {
            this.showErrorAlert = true;
            this.showErrorAlertWithTimer("Unable to remove permission");
            console.error("Error removing access:", error);
          });
      }
    }
  }
};
</script>

<style scoped>
.report-id {
  color: black;
}
</style>
