<template>
  
  <!-- About Us -->
  <section class="about-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="about-img-info">
            <div class="row">
              <div class="col-md-6">
                <div class="about-inner-img">
                  <div class="about-img">
                    <img src="@/assets/img/about-img1.jpg" class="img-fluid" alt="Image" />
                  </div>
                  <div class="about-img">
                    <img src="@/assets/img/about-img2.jpg" class="img-fluid" alt="Image" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="about-inner-img">
                  <div class="about-box">
                    <h4>Easy and Convenient</h4>
                  </div>
                  <div class="about-img">
                    <img src="@/assets/img/about-img3.jpg" class="img-fluid" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="section-inner-header about-inner-header">
            <h6>A better patient experience</h6>
            <h2>Listing your clinic with YourDoc enhances <span>visibility</span>, and attracts more patients</h2>
          </div>
          <div class="about-content">
            <div class="about-content-details">
              <p>
                YourDoc makes it easier for patients to find, book, and return to your practice.
                The doctors’ dashboard provides convenient access to patient records and appointment management, anytime, anywhere, with internet connectivity.
              </p>
              <p>
                Simplify appointments by effortlessly planning your day, minimize last-minute cancellations.
              </p>
            </div>
            <div class="about-contact">
              <div class="about-contact-icon">
                <span><img src="@/assets/img/icons/phone-icon.svg" alt="Image" /></span>
              </div>
              <div class="about-contact-text">
                <p>Call us today</p>
                <h4>+91 824 6637145</h4>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /About Us -->
  <!-- Why Choose Us -->
  <section class="why-choose-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-inner-header text-center">
            <h2>Why Choose YourDoc</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-md-6 d-flex"
          v-for="item in WhyChoose"
          :key="item.id"
        >
          <div class="card why-choose-card w-100">
            <div class="card-body">
              <div class="why-choose-icon">
                <span
                  ><img :src="require(`@/assets/img/icons/${item.Image}`)" alt="Image"
                /></span>
              </div>
              <div class="why-choose-content">
                <h4>{{ item.Heading }}</h4>
                <p>
                  {{ item.Detail }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Why Choose Us -->

  <!-- Way Section -->
  <section class="way-section">
    <div class="container">
      <div class="way-bg">
        <div class="way-shapes-img">
          <div class="way-shapes-left">
            <img src="@/assets/img/shape-06.png" alt="Image" />
          </div>
          <div class="way-shapes-right">
            <img src="@/assets/img/shape-07.png" alt="Image" />
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col-lg-7 col-md-12">
            <div class="section-inner-header way-inner-header mb-0">
              <h2>Ready to Promote Your Practice With Us?</h2>
              <p>
                Get in touch with us and we will set you up within 24 hours.
              </p>
              <router-link to="/contact-us" class="btn btn-primary"
                >Contact With Us</router-link
              >
            </div>
          </div>
          <div class="col-lg-5 col-md-12">
            <div class="way-img">
              <img src="@/assets/img/way-img.png" class="img-fluid" alt="Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Way Choose Us -->
  <!-- Pricing -->
  <section class="pricing-section">
      <div class="container">
          <div class="row">
              <div class="col-md-12 text-center">
                  <div class="section-inner-header pricing-inner-header">
                      <h2>Our Pricing Plan</h2>
                      
                  </div>
              </div>
          </div>
          <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 d-flex">
                  <div class="card pricing-card w-100">
                      <div class="card-body">
                          <div class="pricing-header">
                              <div class="pricing-header-info">
                                  <div class="pricing-icon">
                                      <span>
                                          <img src="@/assets/img/icons/price-icon1.svg" alt="icon">
                                      </span>
                                  </div>
                                  <div class="pricing-title">
                                    <p>For light bookings</p>
                                    <h4>Basic</h4>
                                  </div>
                              </div>
                              <div class="pricing-header-text">
                                  <p>A simple pay for what you use plan if you expect fewer than 100 bookings per month. </p>
                              </div>
                          </div>
                          <div class="pricing-info">
                              <div class="pricing-amount">
                                <h2>₹30 <span>/appointment</span></h2>
                                <h6>What’s included</h6>
                              </div>
                              <div class="pricing-list">
                                  <ul>
                                    <li>Patients search; make an appointment</li>
                                    <li>View all upcoming appointments</li>
                                    <li>Manage your schedule and slots</li>
                                    <li>View patient profile</li>
                                  </ul>
                              </div>
                              <div class="pricing-btn">
                                <router-link to="/contact-us" class="btn btn-primary">Contact Us</router-link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 d-flex">
                  <div class="card pricing-card w-100">
                      <div class="card-body">
                          <div class="pricing-header">
                              <div class="pricing-header-info">
                                  <div class="pricing-icon">
                                      <span>
                                          <img src="@/assets/img/icons/price-icon2.svg" alt="icon">
                                      </span>
                                  </div>
                                  <div class="pricing-title">
                                      <p>For busy clinics</p>
                                      <h4>Monthly Fixed</h4>
                                  </div>
                              </div>
                              <div class="pricing-header-text">
                                  <p>Fixed monthly charge without the head ache of keeping a count of appointments. </p>
                              </div>
                          </div>
                          <div class="pricing-info">
                              <div class="pricing-amount">
                                <h2>₹2999 <span>/month</span></h2>
                                <h6>What’s included</h6>
                              </div>
                              <div class="pricing-list">
                                  <ul>
                                    <li>Everything in Basic; Plus</li>
                                    <li>Profile updates on your behalf</li>
                                    <li>Manage patients precriptions; records</li>
                                    <li>Patient and appointment reports</li>
                                  </ul>
                              </div>
                              <div class="pricing-btn">
                                <router-link to="/contact-us" class="btn btn-primary">Contact Us</router-link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 d-flex">
                  <div class="card pricing-card w-100">
                      <div class="card-body">
                          <div class="pricing-header">
                              <div class="pricing-header-info">
                                  <div class="pricing-icon">
                                      <span>
                                          <img src="@/assets/img/icons/price-icon3.svg" alt="icon">
                                      </span>
                                  </div>
                                  <div class="pricing-title">
                                      <p>Best Value</p>
                                      <h4>Yearly Fixed</h4>
                                  </div>
                              </div>
                              <div class="pricing-header-text">
                                  <p>The best value for money with 20% discount compared to monthly fixed. </p>
                              </div>
                          </div>
                          <div class="pricing-info">
                              <div class="pricing-amount">
                                <h2>₹29,990 <span>/year</span></h2>
                                <h6>What’s included</h6>
                              </div>
                              <div class="pricing-list">
                                  <ul>
                                    <li>Everything in Basic; Plus</li>
                                    <li>Profile updates on your behalf</li>
                                    <li>Manage patients precriptions; records</li>
                                    <li>Patient and appointment reports</li>
                                  </ul>
                              </div>
                              <div class="pricing-btn">
                                <router-link to="/contact-us" class="btn btn-primary">Contact Us</router-link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <!-- /Pricing -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexDoctor from "@/assets/json/indexdoctor.json";
import "vue3-carousel/dist/carousel.css";

const docDash = [
    {
        "id": 1,
        "Heading": "Easy to find",
        "Detail": "Smart and simple search and booking brings the right patient to you",
        "Image": "choose-01.svg"
    },
    {
        "id": 2,
        "Heading": "Appointment Reminders",
        "Detail": "Avoid no shows as patients get reminded about upcoming appointment",
        "Image": "choose-02.svg"
    },
    {
        "id": 1,
        "Heading": "Repeat patients",
        "Detail": "Your practice is automatically saved to your patient dashboard for quick access",
        "Image": "choose-04.svg"
    },
    {
        "id": 1,
        "Heading": "Outpatient View",
        "Detail": "A detailed outpatient view helps you keep track and provide better healthcare",
        "Image": "choose-03.svg"
    },
]

export default {
  data() {
    return {
      WhyChoose: docDash,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
