<template>
  <div class="login-body">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="login-content-info">
        <div class="container">
          <!-- Login Email Otp -->
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="account-content">
                <div class="login-shapes">
                  <div class="shape-img-left">
                    <img src="@/assets/img/shape-01.png" alt="shape-image" />
                  </div>
                  <div class="shape-img-right">
                    <img src="@/assets/img/shape-02.png" alt="shape-image" />
                  </div>
                </div>
                <div class="account-info">
                  <div class="login-back">
                    <router-link to="" @click="$router.go(-1)"
                      ><i class="fa-solid fa-arrow-left-long" ></i> Back</router-link
                    >
                  </div>
                  <div class="login-verify-img">
                    <img src="@/assets/img/icons/email-icon.svg" alt="email-icon" />
                  </div>
                  <div class="login-title">
                    <h3>OTP Verification</h3>
                    <p class="mb-0">
                      OTP sent to <i class="fa-brands fa-whatsapp" style="color: #25D366;"></i>&nbsp;WhatsApp <strong>{{ maskephone }}</strong>
                    </p>
                  </div>
                  <p v-if="error">
                    <ul>
                      <li>{{ error }}</li>
                    </ul>
                  </p>
                  <form
                    method="get"
                    class="digit-group login-form-control"
                    data-group-name="digits"
                    data-autosubmit="false"
                    autocomplete="off"
                    @submit.prevent="handleSignUpConfirmation"
                  >
                  <div class="otp-boxe">
                      <div class="mb-3e">
                        <v-otp-input length="6" v-model="otp"
                           :loading="loading"
                           autofocus >
                        </v-otp-input>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="otp-info">
                        <div class="otp-code">
                          <p>Didn't receive OTP code?</p>
                          <v-btn @click="resendCode" :disabled="countDown>0" variant="outlined">Resend Code</v-btn>
                        </div>
                        <div class="otp-sec">
                          <p><i class="feather-clock"></i>  {{ countDown }} secs</p>
                        </div>
                      </div>
                    </div>
                    <div class="text-end">
                      <router-link class="forgot-link" to="register"
                        >Change the signup details?</router-link
                      >
                    </div>
                    <div class="reset-btn">
                      
                        <b-button :disabled="otp.length < 6 || loading"
                          class="btn btn-primary w-100 btn-lg login-btn"
                          type="submit"
                        ><span
                          class="spinner-border spinner-border-sm me-2"
                          role="status" v-if="loading"
                        ></span
                        >
                          Submit
                        </b-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- /Login Email Otp -->
        </div>
      </div>
      <!-- /Page Content -->
      <cursor></cursor>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref, onMounted } from "vue";
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';

export default {
  data() {
    return {
      countDown: 30,
      error: null,
      otp: '',
      loading: false,
      maskephone: localStorage.getItem("confirmotpphone"),
      username: localStorage.getItem("signupphone"),
    } 
  },
  methods: {
    countDownTimer () {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
    },
    async sendWelcomeEmail() {
      const patientid = localStorage.getItem("signuppatientid")
      await axios.post(`/patients/${patientid}/welcome`, {});
    },
    
    async handleSignUpConfirmation() {
      this.loading = true;
      this.error = null;
      try {
        const { isSignUpComplete, nextStep } = await confirmSignUp({
          username: this.username,
          'confirmationCode': this.otp,
        }, this.otp);
        console.log(isSignUpComplete);
        this.sendWelcomeEmail();
        this.$router.push("/signup-success");
        
      } catch (e) {
        this.loading = false;
        console.log("Error confirming sign-up", e);
        this.error = e.message || "Something went wrong. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    async resendCode() {
      try {
        resendSignUpCode({
          username: this.username
        });
        this.countDown=30;
        this.countDownTimer();
      } catch (e) {
        console.log(e);
      }
    }
  },
  created () {
      this.countDownTimer()
  },
};
</script>
