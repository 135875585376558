<script setup>
import { toRefs } from "vue";
import { useAuthenticator } from "@aws-amplify/ui-vue";
// `route` `user` and `signOut` all are reactive.
const { user } = toRefs(useAuthenticator());
</script>
<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>

    <!-- Page Content -->
    <div class="doctor-content">
      <div class="container">
        <!-- Paitent -->
        <div class="row">
          <div class="col-md-12">
            <div class="back-link">
              <router-link :to="`/booking/${doctorId}`">
                <i class="fa-solid fa-arrow-left-long"></i> {{ $t('back') }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 co l-md-12">
            <div class="paitent-header">
              <h4 class="paitent-title">{{ $t('patient_details') }}</h4>
            </div>
            <div class="paitent-appointment">
              <div v-if="user">
                <div class="forms-block">
                  <label class="form-group-title">{{ $t('appointment_for') }}</label>
                  <label class="custom_radio me-4">
                    <input
                      type="radio"
                      name="appointment"
                      checked
                      v-model="patientType"
                      value="self"
                    />
                    <span class="checkmark"></span> {{ $t('myself') }}
                  </label>
                  <label class="custom_radio">
                    <input type="radio" name="appointment" v-model="patientType" value="dependent" />
                    <span class="checkmark"></span> {{ $t('dependent') }}
                  </label>
                </div>
                <div class="forms-block" v-if="patientType==='dependent'">
                  <div class="text-end">
                    <a
                      href="#modal_form"
                      data-bs-toggle="modal"
                      class="btn btn-primary btn-sm"
                      tabindex="0"
                    >{{ $t('add_dependent') }}</a>
                  </div>
                  <div class="form-group-flex">
                    <label class="form-group-title">{{ $t('choose_dependent') }}</label>
                    <!-- <a href="javascript:void(0);" class="btn">
                      <i class="feather-plus"></i> Add
                    </a>-->
                  </div>
                  <div class="paitent-dependent-select">
                    <model-list-select
                      :list="dependents"
                      v-model="dependentList"
                      option-value="patientid"
                      option-text="fullName"
                      :placeholder="$t('select_dependent')"
                    />
                  </div>
                </div>
                <!--<div class="forms-block">
                  <label class="form-group-title">Do you have insurance?</label>
                  <label class="custom_radio me-4">
                    <input type="radio" name="insurance" />
                    <span class="checkmark"></span> Yes
                  </label>
                  <label class="custom_radio">
                    <input type="radio" name="insurance" checked />
                    <span class="checkmark"></span> No
                  </label>
                </div>-->
              </div>
              <div v-else>
                <div>
                  <b-alert v-model="showError" dismissible variant="danger">{{ error }}</b-alert>
                </div>
                <div class="forms-block row">
                  <div class="col-md-6">
                    <label class="form-group-title">
                      {{ $t('first_name') }}
                      <span class="text-danger" style="font-size: 16px;">*</span>
                    </label>
                    <b-form-input
                      type="text"
                      v-model="patient.firstName"
                      required
                      :class="{ 'is-invalid': firstnameTouched && !validatefirstname() }"
                      @blur="firstnameTouched = true"
                      maxlength="45"
                    />
                    <div
                      v-if="firstnameTouched && !validatefirstname()"
                      class="error-message"
                    >{{ $t('invalid_first_name') }}</div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-group-title">{{ $t('last_name') }}</label>
                    <b-form-input
                      type="text"
                      v-model="patient.lastName"
                      :class="{ 'is-invalid': lastnameTouched && !validatelastname() }"
                      @blur="lastnameTouched = true"
                      maxlength="45"
                    />
                    <div
                      v-if="lastnameTouched && !validatelastname()"
                      class="error-message"
                    >{{ $t('invalid_last_name') }}</div>
                  </div>
                </div>
                <div class="forms-block">
                  <label class="form-group-title">
                    {{ $t('gender') }}
                    <span class="text-danger" style="font-size: 16px;">*</span>
                  </label>
                  <div class="form-check">
                    <input
                      v-model="patient.gender"
                      type="radio"
                      id="M"
                      value="M"
                      class="form-check-input"
                      required
                    />
                    <label for="M" class="form-check-label">{{ $t('male') }}</label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="patient.gender"
                      type="radio"
                      id="F"
                      value="F"
                      class="form-check-input"
                      required
                    />
                    <label for="F" class="form-check-label">{{ $t('female') }}</label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="patient.gender"
                      type="radio"
                      id="O"
                      value="O"
                      class="form-check-input"
                      required
                    />
                    <label for="O" class="form-check-label">{{ $t('other') }}</label>
                  </div>
                </div>
                <!-- <div class="forms-block">
                  <label class="form-group-title">
                    {{ $t('mobile_number') }}
                    <span class="text-danger" style="font-size: 16px;">*</span>
                  </label>
                  <div class="col-md-6">
                    <vue-tel-input
                       class="form-control floating"
                        mode='national'
                        @on-input="updateInput"
                        @blur="phoneTouched = true"
                        :defaultCountry='defaultCountry'
                        :autoFormat="autoFormat"
                        :only-countries="onlyCountries"
                        :inputOptions="inputOptions"
                        :dropdownOptions="dropdownOptions"
                        ref="phonenumber"
                                             
                      />
                  <div 
                    v-if="phoneTouched && !validatePhoneNumber()" 
                    class="error-message"
                  >{{ $t('invalid_phone') }}</div>
                  </div>
                </div> -->
                <div class="forms-block mb-3">
                  <label class="form-group-title mb-2">
                    {{ $t('mobile_number') }}
                    <span class="text-danger" style="font-size: 16px;">*</span>
                  </label>
                  <div class="d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control me-2"
                      style="max-width: 250px;"
                      v-model="patient.phone"
                      :readonly="true"
                    />
                    <button
                      type="button"
                      class="btn btn-sm d-flex align-items-center edit-btn"
                      @click="editPhone"
                    >
                      <i class="fas fa-edit me-1"></i> Edit
                    </button>
                  </div>
                  
                </div>
                <div class="forms-block">
                  <label class="form-group-title">
                    {{ $t('email') }} <span class="optional-text">(Optional)</span>
                    <!-- <span class="text-danger" style="font-size: 16px;">*</span> -->
                  </label>
                  <div class="col-md-8">
                    <b-form-input
                      type="email"
                      v-model="patient.email"
                      :class="{ 'is-invalid': emailTouched && !validateEmail() }"
                      @blur="emailTouched = true"
                      maxlength="45"
                    />
                    <div
                      v-if="emailTouched && !validateEmail()"
                      class="error-message"
                    >{{ $t('invalid_email') }}</div>
                  </div>
                </div>
              </div>
              <div class="forms-block">
                <label class="form-group-title">{{ $t('reason') }}<span class="optional-text">(Optional)</span></label>
                <input
                  v-if="storedReason !== ''"
                  v-model="reason"
                  type="text"
                  class="form-control"
                  :placeholder="$t('reason_for_appointment')"
                  required
                  maxlength="100"
                />
                <input
                  v-else
                  type="text"
                  class="form-control"
                  :placeholder="$t('reason_for_appointment')"
                  required
                  maxlength="100"
                />
              </div>
              <div class="forms-block">
                <label class="form-group-title">{{ $t('age') }} <span class="optional-text">(Optional)</span></label>
                <input
                  v-if="storedAge !== ''"
                  v-model="age"
                  type="text"
                  class="form-control"
                  :placeholder="$t('enter_age')"
                  required
                  maxlength="5"
                />
                <input
                  v-else
                  type="text"
                  class="form-control"
                  :placeholder="$t('enter_age')"
                  required
                  maxlength="5"
                />
              </div>
              <div class="forms-block mb-0">
                <div class="booking-btn">
                  <b-button
                    type="button"
                    class="btn btn-primary prime-btn justify-content-center align-items-center"
                    @click="updateStoreAndNavigate"
                  >
                    {{ $t('next') }}
                    <i class="feather-arrow-right-circle"></i>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="booking-header">
              <h4 class="booking-title">{{ $t('booking_summary') }}</h4>
            </div>
            <div class="card booking-card">
              <div class="card-body booking-card-body">
                <div class="booking-doctor-details">
                  <div class="booking-doctor-left">
                    <div class="booking-doctor-img">
                      <img
                        :src="doctorImage || require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                        :alt="doctor.doctorname"
                      />
                    </div>
                    <div class="booking-doctor-info">
                      <h4>Dr. {{ doctor.doctorname }}</h4>
                      <p>{{ doctor.qualifications }}, {{ doctor.specialization }}</p>
                      <p>{{ doctor.area }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card booking-card">
              <div class="card-body booking-card-body booking-list-body">
                <div class="booking-list">
                  <div class="booking-date-list">
                    <ul>
                      <li>
                        {{ $t('booking_date') }}:
                        <span>{{ appointmentday }}</span>
                      </li>
                      <li>
                        {{ $t('booking_time') }}:
                        <span>{{ appointmenttime}}</span>
                      </li>
                      <li>
                        {{ $t('clinic') }}:
                        <a
                          target="_blank"
                          :href="'https://www.google.com/maps/search/?api=1&ll=12.9141%2C74.8560&query_place_id=&z=13&query='+clinicname"
                        >
                          <span>
                            <i class="feather-map-pin"></i>
                            {{clinicname}}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="booking-doctor-right">
                    <p>
                      <router-link :to="{path: '/booking/'+ doctorId }">{{ $t('edit') }}</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="card booking-card">
              <div class="card-body booking-card-body">
                <div class="booking-doctor-details">
                  <div class="booking-device">
                    <div class="booking-device-img">
                      <img
                        src="@/assets/img/icons/device-message.svg"
                        alt="device-message-image"
                      />
                    </div>
                    <div class="booking-doctor-info">
                      <h3>We can help you</h3>
                      <p class="device-text">
                        Call us +1 888-888-8888 (or) chat with our customer support team.
                      </p>
                      <router-link to="chat" class="btn">Chat With Us</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card booking-card mb-0">
              <div class="card-body booking-card-body">
                <div class="booking-doctor-details">
                  <div class="booking-device">
                    <div class="booking-device-img">
                      <img src="@/assets/img/icons/smart-phone.svg" alt="smart-phone" />
                    </div>
                    <div class="booking-doctor-info">
                      <h3>Get the App</h3>
                      <p class="device-text">
                        Download our app for better experience and for more feature
                      </p>
                      <div class="app-images">
                        <a href="javascript:void(0);">
                          <img src="@/assets/img/google-img.svg" alt="google-image" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="@/assets/img/app-img.svg" alt="app-image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
        <!-- /Paitent -->
      </div>
    </div>
    <!-- /Page Content -->
      <cursor></cursor>
      <indexfooter />
  </div>
  <actions :dependent="dependents" :selectedDependent="selectedDependent" :fetchDependents="fetchDependents"></actions>
</template>
<script>
import axios from "axios";
import { toRefs } from "vue";
import { useStore } from "vuex";
import { router } from "@/router";
import Swal from "sweetalert2";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
import { getCurrentUser } from "aws-amplify/auth";
import { ModelListSelect } from "vue-search-select";
import { useAuthenticator } from "@aws-amplify/ui-vue";
import moment from "moment";
export default {
  data() {
    return {
      // Select: ["Select", "Dependent 01", "Dependent 02", "Dependent 03", "Dependent 04"],
      doctorId: null,
      patientid: null,
      appointmentby: null,
      doctor: {},
      dependents: [],
      dependentList: [],
      selectedDependent: [],
      appointmentday:
        this.$store.getters.getAppointmentDraft.day,
      appointmenttime:
        this.$store.getters.getAppointmentDraft.time,
      clinicname: this.$store.getters.getAppointmentDraft.clinicname,
      doctorImage: this.$store.getters.getAppointmentDraft.doctorImage,
      patient: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        gender: ""
      },
      reason: "",
      age: "",
      firstnameTouched: false,
      lastnameTouched: false,
      emailTouched: false,
      phoneTouched: false,
      patientType: "self",
      isLoggedin: false,
      error: null,
      showError: false,
      autoFormat: false,
      serverDateTime: null,
      currentMoment: null,
      defaultCountry: 'IN',
      onlyCountries: [
          'IN', 'US', 'GB', 'CA', 'AU', 
          'AE', 'BH', 'KW', 'OM', 'QA', 'SA', 
          'SG', 'MY', 'NZ'
        ],
      dropdownOptions: {
        showFlags: true,
        showDialCodeInList:true,
        // showCountryName: true,
        showDialCodeInSelection: true,
         
      },
      inputOptions: {
        maxlength: "10",
        placeholder: "",
        required: true,

      },
      isValidPhone:''
    };
  },
  components: {
    ModelListSelect
  },
  computed: {
    storedReason() {
      return this.$store.getters.getAppointmentDraft.reason;
    },
    storedAge() {
      return this.$store.getters.getAppointmentDraft.age;
    }
  },
  methods: {
    editPhone() {
      this.$router.push({ name: 'phone-number', params: { doctorId: this.doctorId } });
    },
     async fetchServerDateTime() {
      try {
        const response = await axios.get("/doctors/datetime");
        const serverData = response.data;
        this.serverDateTime = {
          fullDate: serverData[1], // "YYYY-MM-DD"
          fullTime: serverData[3] // "HH:mm:ss" 24-hour format
        };
        this.currentMoment = response.data;
      } catch (error) {
        console.error("Error fetching server date and time:", error);
        return null;
      }
    },
    updateInput(phone, phoneObject){
      this.patient.phone = phoneObject.number // E.164 number convention
      this.isValidPhone = phoneObject.valid 

    },
    async fetchPatientAge() {
      try {
        const response = await axios.get(`/patients/${this.patientid}/age`);
        //console.log("try:", response);
        const ageFromAPI = response.data.age;
        if (ageFromAPI !== null) {
          this.age = ageFromAPI;
          this.$store.dispatch("updateAppointmentDraft", { age: this.age });
         // console.log("age:", this.age);
        }
      } catch (error) {
        console.error("Error fetching patient age:", error);
        //console.error("Patientid: ", this.patientid);
        //console.log("catch:", response);
      }
    },
    async currentAuthenticatedUser() {
      try {
        const { username, userId, signInDetails } = await getCurrentUser();
        //console.log(`The username: ${username}`);
        //console.log(`The userId: ${userId}`);
        sessionStorage.setItem("useridpid", userId);
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    validatefirstname() {
      const firstnameRegex = /^[a-zA-Z\s]{1,25}$/;
      return firstnameRegex.test(this.patient.firstName);
    },
    validatelastname() {
      const lastnameRegex = /^[a-zA-Z]{1,25}$/;
      const lastname = this.patient.lastName;
      return lastname === null || lastname === '' || lastnameRegex.test(lastname);
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return this.patient.email === null || this.patient.email === '' || emailRegex.test(this.patient.email);
    },
    validatePhoneNumber() {
      const phoneNumberRegex = /^\+\d{10,14}$/;
      return phoneNumberRegex.test(this.patient.phone) && this.isValidPhone;
    },
    async updateStoreAndNavigate() {
      const isUser = await this.currentAuthenticatedUser();
      if (!isUser) {
        this.showError = false;
        let invalids = [];
        if (!this.patient.firstName||!this.validatefirstname()) {
          invalids.push("first name");
        }
        if (!this.validatelastname()) {
          invalids.push("last name");
        }
        if (!this.patient.gender) {
          invalids.push("gender");
        }
        if (this.patient.email && !this.validateEmail()) {
          invalids.push("valid email");
        }
        // if (!this.validatePhoneNumber()) {
        //   invalids.push("valid phone number");
        // }
        if (invalids.length > 0) {
          this.error = "Please enter " + invalids.join(", ");
          this.showError = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        //TODO insert patient record
        // this.patientid = 1;
        this.submitForm(isUser);
      } else {
        this.isLoggedin = true;
        if (this.patientType === "self")
          (this.patientid = sessionStorage.getItem("userdbid")),
            (this.appointmentby = this.patientid);
        else
          (this.appointmentby = sessionStorage.getItem("userdbid")),
            (this.patientid = this.dependentList.patientid);
        this.submitForm(isUser);
      }
    },
    formatDate(date) {
      if (!date) return "";
      const parsedDate = moment(date, "YYYY-MM-DD");
      if (!parsedDate.isValid()) {
        console.error("Invalid date format:", date);
        return "";
      }
      return parsedDate.toDate().toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
      });
    },
    formatTime(time) {
      if (!time) return "";
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    async fetchDoctorData() {
      const apiUrl = `doctors/${this.doctorId}`;

      try {
        let docDetails = sessionStorage.getItem(
          `doctorDetails${this.doctorId}`
        );
        if (!docDetails) {
          const response = await axios.get(apiUrl);
          docDetails = response.data;
          sessionStorage.setItem(
            `doctorDetails${this.doctorId}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctor = docDetails;
      } catch (error) {
        console.error("Error fetching doctor data:", error);
      }
    },
    fetchDependents() {
      const checkUserdbid = () => {
        const patientId = sessionStorage.getItem("userdbid");
        if (patientId) {
          //console.log("userdbid is ", patientId);
          axios.get(`/patients/${patientId}/dependents`)
            .then(response => {
              this.dependents = response.data.map(dependent => {
                dependent.fullName = `${dependent.firstname} ${dependent.lastname}`;
                return dependent;
              });
            })
            .catch(error => {
              console.error("Error fetching dependents:", error);
            });
        } else {
          setTimeout(checkUserdbid, 500);  // Retry after 500ms if not set
        }
      };
      checkUserdbid();
    },

    async fetchPatientDBId() {
      const idpid = sessionStorage.getItem("useridpid");
      try {
        //console.log("Fetching db ID with idpid:", idpid);
        const response = await axios.get(`/auth/identifier/${idpid}`);

        //console.log("Response from get_patient_id API:", response);

        if (response.data && response.data.dbid) {
          sessionStorage.setItem("userdbid", response.data.dbid);
          const usertype = response.data.profile;
          sessionStorage.setItem("usertype", usertype);
          this.patientid = response.data.dbid;
          return response.data.dbid;
        } else {
          console.error("DB ID not found in the response.");
        }
      } catch (error) {
        console.error("Error fetching db ID:", error);
      }
      return null;
    },
    async sendOtp(email, phone) {
      try {
        const apiUrl = "otp";
        const requestData = {
          mobileNumber: phone,
          email: email,
          locale: this.$i18n.locale
        };
        const response = await axios.post(apiUrl, requestData);
        this.$router.push({ name: "email-otp" });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
    async submitForm(isUser) {
      if (isUser && !this.patientid) {
        this.patientid = await this.fetchPatientDBId();
      }
      //console.log(this.patientid);
      this.$store.dispatch("updateAppointmentDraft", {
        appointmentby: this.appointmentby,
        patientid: this.patientid,
        reason: this.reason,
        age: this.age
      });
      if (isUser) {
        this.$router.push({ name: "booking-confirm" });
      } else {
        const patientDetails = {
          firstName: this.patient.firstName,
          lastName: this.patient.lastName,
          phone: this.patient.phone,
          email: this.patient.email,
          gender: this.patient.gender
          //reason: this.reason,
        };
        this.$store.dispatch("updatePatientDetails", patientDetails);
        await this.sendOtp(this.patient.email, this.patient.phone);
      }
    }
  },
  name: "patient-details",
  async created() {
    this.doctorId = this.$route.params.doctorId;
    this.reason = this.storedReason;
    this.age = this.storedAge;
    await this.fetchServerDateTime();
    this.appointmentday = this.formatDate(this.$store.getters.getAppointmentDraft.day);
    this.appointmenttime = this.formatTime(this.$store.getters.getAppointmentDraft.time);
    this.fetchDoctorData();
  },
  mounted() {
    this.fetchDependents();
    const phoneNumber = this.$store.getters.getMobileNumber
     if (phoneNumber) {
        this.patient.phone = phoneNumber
    }
    const firstName = this.$store.getters.getfirstName;
    const lastName = this.$store.getters.getlastName;
    const gender = this.$store.getters.getGender;
    const phone = this.$store.getters.getMobileNumber;
    const email = this.$store.getters.getEmail;

    //console.log("stored phone", this.patient.phone)

    if (firstName !== null) this.patient.firstName = firstName;
    if (lastName !== null) this.patient.lastName = lastName;
    if (gender !== null) this.patient.gender = gender;
    if (phone !== null) this.patient.phone = phone;
    if (email !== null) this.patient.email = email;
}
};
</script>

<style scoped>
.is-invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #bbb !important;
}

.edit-btn {
  color: #007bff; /* Adjust this to match your theme */
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.edit-btn:hover {
  color: #0056b3; /* Slightly darker for hover effect */
  text-decoration: underline;
}

.edit-btn i {
  font-size: 14px;
}
</style>
