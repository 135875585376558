<template>
  <div class="profile-sidebar">
    <div class="widget-profile pro-widget-content">
      <div class="profile-info-widget">
        <a href="javascript:;" class="booking-doc-img">
          <img :src="profileImageUrl || defaultImageUrl" alt="User Image" />
        </a>
        <div class="profile-det-info">
          <h3>{{patientDetails.fullname}}</h3>
          <div class="patient-details">
            <h5 v-if="patientDetails.dateof_birth && patientDetails.age">
              <i class="fas fa-birthday-cake"></i>
              {{formatedDate(patientDetails.dateof_birth)}}, {{patientDetails.age}} years
            </h5>
            <h5 v-if="patientDetails.area" class="mb-0">
              <i class="fas fa-map-marker-alt"></i>
              {{patientDetails.area}}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-widget">
      <nav class="dashboard-menu">
        <ul>
          <li :class="currentPath == 'patient-dashboard' ? 'active' : 'notactive'">
            <router-link to="/patient-dashboard">
              <i class="fas fa-columns"></i>
              <span class="ms-1">Dashboard</span>
            </router-link>
          </li>
          <li :class="currentPath == 'favourites' ? 'active' : 'notactive'">
            <router-link to="/favourites">
              <i class="fas fa-bookmark"></i>
              <span class="ms-1">My Favourite Doctors</span>
            </router-link>
          </li>
          <li :class="currentPath == 'dependent' ? 'active' : 'notactive'">
            <router-link to="/dependent">
              <i class="fas fa-users"></i>
              <span class="ms-1">Dependent</span>
            </router-link>
          </li>
          <li :class="currentPath == 'medical-records' ? 'active' : 'notactive'">
            <router-link to="/medical-records">
              <i class="fas fa-file-medical"></i>
              <span class="ms-1">Medical Records</span>
            </router-link>
          </li>
          <li :class="currentPath == 'chat' ? 'active' : 'notactive'">
            <router-link to="/chat">
              <i class="fas fa-comments"></i>
              <span class="ms-1">Notifications</span>
              <small class="unread-msg" v-if="notificationCount">{{ notificationCount }}</small>
            </router-link>
          </li>
          <li :class="currentPath == 'profile-settings' ? 'active' : 'notactive'">
            <router-link to="/profile-settings">
              <i class="fas fa-user-cog"></i>
              <span class="ms-1">Profile Settings</span>
            </router-link>
          </li>
          <li :class="currentPath == 'change-password' ? 'active' : 'notactive'">
            <router-link to="/change-password">
              <i class="fas fa-lock"></i>
              <span class="ms-1">Change Password</span>
            </router-link>
          </li>
          <li :class="currentPath == 'index' ? 'active' : 'notactive'">
            <router-link to @click="signOutUser">
              <i class="fas fa-sign-out-alt"></i>
              <span class="ms-1">Logout</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { signOut } from "aws-amplify/auth";
import axios from "axios";
import defaultImageUrl from "@/assets/img/patients/patient.jpg";
import eventBus from "@/eventBus";
export default {
  data() {
    return {
      activeClass: "active",
      patientDetails: {
        fullname: ""
      },
      defaultImageUrl: defaultImageUrl,
      profileImageUrl: "",
      notificationCount: 0

    };
  },
  computed: {
    currentPath() {
      return this.$route.name;
    }
  },
  methods: {
    signOutUser() {
      signOut()
        .then(data => {
          this.$router.push("/");
        })
        .catch(err => console.log(err));
    },
    formatedDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    async fetchPatientProfileImage() {
      const patientId = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(
          `/patients/${patientId}/profile-image`,
          {
            responseType: "arraybuffer"
          }
        );

        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
      }
    },
    fetchPatientDetails() {
      const patientId = sessionStorage.getItem("userdbid");
      axios
        .get(`/patients/${patientId}`)
        .then(response => {
          this.patientDetails = {
            ...response.data,
            fullname: response.data.firstname + " " + response.data.lastname
          };
        })
        .catch(error => {
          console.error("Error fetching patient data:", error);
        });
    },
    async fetchChats() {
      const patientId = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(`patients/${patientId}/notifications`);
        // this.notificationCount = response.data ? response.data.length : 0;
        this.notificationCount = response.data
        ? response.data.filter(notification => notification.notificationflag === "U").length
        : 0;  
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    }
  },
  mounted() {
    this.fetchPatientDetails();
    this.fetchChats();
    this.fetchPatientProfileImage();
    eventBus.on('imageUploaded', this.fetchPatientProfileImage);
  },
  beforeUnmount() { 
    eventBus.off('imageUploaded', this.fetchPatientProfileImage);
  }
};
</script>
