<template>
  <!-- Profile Widget -->
  <div class="card widget-profile pat-widget-profile">
    <div class="card-body">
      <div class="pro-widget-content">
        <div class="profile-info-widget">
          <a href="javascript:;" class="booking-doc-img">
            <img src="@/assets/img/patients/patient.jpg" alt="User Image" />
          </a>
          <div class="profile-det-info">
            <h3>{{ patientDetails.firstname }} {{patientDetails.lastname}}</h3>
            <div class="patient-details">
              <!--h5><b>Patient ID :</b> {{ patientid }}</h5-->
              <h5 class="mb-0">
                <i class="fas fa-map-marker-alt"></i>
                {{ patientDetails.address }}, {{patientDetails.area}}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="patient-info">
        <ul>
          <li>
            Phone
            <span>
              <i class="fas fa-phone-alt"></i>
              <a :href="'tel:' + patientDetails.contactnumbers">{{ patientDetails.contactnumbers }}</a>
            </span>
          </li>
          <li>
            Age
            <span>{{ patientDetails.age }} Years</span>
          </li>
          <li>
            Gender
            <span>{{ patientDetails.gender === 'M' ? 'Male' : 'Female' }}</span>
          </li>
          <!--li>Blood Group <span>{{ patientDetails.bloodGroup }}</span></li-->
        </ul>
      </div>
    </div>
  </div>
  <!-- /Profile Widget -->
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    patientid: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      patientDetails: {
        firstname: "",
        lastname: "",
        contactnumbers: "",
        address: "",
        area: "",
        age: "",
        gender: ""
      }
    };
  },
  beforeMount() {
    this.fetchPatientDetails();
  },

  methods: {
    async fetchPatientDetails() {
      try {
        const response = await axios.get(`/patients/${this.patientid}`);
        this.patientDetails = response.data;
        //console.log(this.patientDetails);
      } catch (error) {
        //console.log(patientid);
        console.error("Error fetching patient details:", error);
      }
    }
  }
};
</script>
