<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar :doctorDetails="doctorDetails"></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">


            <div class="row">
              <div class="col-md-12">
                <div class="appointment-tab">
                  <!-- Appointment Tab -->

                  <!-- <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                    <h3 style="margin-right:auto; margin-left:8px">REPORT</h3>

                  </ul> -->
                  <!-- /Appointment Tab -->
                  <div class="date-range-selection-wrapper">
                    <div class="date-range-selection">
                      <!-- <i class="fa-solid fa-calendar-days calendar-icon"></i> -->
                      <div class="daterange-container">

                        <div id="daterange" @click="focusInput" class="daterange-input">
                          {{ startdatex }}
                        </div>
                        <span class="daterange-to">to</span>
                        <div id="daterangeok" @click="focusInputok" class="daterange-input">
                          {{ enddatex }}
                        </div>
                      </div>
                      <div class="dropdown-container" style="margin-left: 10px;">
                        <div class="custom-select-wrapper">
                          <select v-model="selectedClinic" class="custom-select-modernx" @change="clinicframechange()">
                            <option value="AL">All</option>
                            <option v-for="clinic in clinics" :key="clinic.id" :value="clinic.id">{{ clinic.name }}
                            </option>
                          </select>
                        </div>
                        <!-- <div class="custom-select-wrapper">
                          <select v-model="selectedYear" class="custom-select-modern" @change="yearframechange()">
                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                          </select>
                        </div>
                        <div class="custom-select-wrapper">
                          <select v-model="selectedMonth" class="custom-select-modern" @change="monthframechange()">
                            <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}
                            </option>
                          </select>
                        </div> -->
                        <div class="custom-select-wrapper">
                          <select v-model="selectedStatus" class="custom-select-modern" @change="statusframechange()">
                            <option v-for="(stau, index) in status" :key="index" :value="stau.value">
                              {{ stau.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- <button @click="generateReport()" class="generate-button">Generate</button> -->
                    </div>
                    <div class="export-buttons">
                      <div class="download-report">
                        <p class="download-text">Download report</p>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="PDF"
                          @click="exportAppointments('pdf')">
                          <img src="@/assets/img/pdf.svg" alt="Export to PDF" class="export-iconx" />
                        </a>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
                          @click="exportAppointments('excel')">
                          <img src="@/assets/img/excel.svg" alt="Export to Excel" class="export-iconx" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content">
                    <!-- monthly -->
                    <!-- Upcoming Appointment Tab -->

                    <div class="tab-pane show active" id="upcoming-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <tbody>
                                <!-- visited -->
                                <template v-for="allAppointment in allappointmentstatus" :key="allAppointment.id">
                                  <tr v-if="selectedStatus === 'V' || selectedStatus === 'AL'">
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-calendar-week fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          {{ selectedClinicName }}
                                          <span v-if="allAppointment.appointment_date !== undefined">{{
                                            formatedDate(allAppointment.appointment_date) }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-mars fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Male
                                          <span v-if="allAppointment.malecount_visited !== undefined">{{
                                            allAppointment.malecount_visited }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-venus fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Female
                                          <span v-if="allAppointment.femalecount_visited !== undefined">{{
                                            allAppointment.femalecount_visited }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-transgender fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Other
                                          <span v-if="allAppointment.othercount_visited !== undefined">{{
                                            allAppointment.othercount_visited }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-person fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Total
                                          <span v-if="allAppointment.totalcount_visited !== undefined">{{
                                            allAppointment.totalcount_visited }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td class="text-end">
                                      <div class="table-action">
                                        <span v-if="selectedStatus === 'V' || selectedStatus === 'AL'">
                                          <span class="badge bg-primary text-white ms-2"
                                            style="font-size: 1rem; padding: 0.5rem 1rem;">Visited</span>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                                <!-- absent -->
                                <template v-for="allAppointment in allappointmentstatus" :key="allAppointment.id">
                                  <tr v-if="selectedStatus === 'A' || selectedStatus === 'AL'">
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-calendar-week fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          {{ selectedClinicName }}
                                          <span v-if="allAppointment.appointment_date !== undefined">{{
                                            formatedDate(allAppointment.appointment_date) }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-mars fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Male
                                          <span v-if="allAppointment.malecount_absent !== undefined">{{
                                            allAppointment.malecount_absent }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-venus fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Female
                                          <span v-if="allAppointment.femalecount_absent !== undefined">{{
                                            allAppointment.femalecount_absent }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-transgender fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Other
                                          <span v-if="allAppointment.othercount_absent !== undefined">{{
                                            allAppointment.othercount_absent }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-person fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Total
                                          <span v-if="allAppointment.totalcount_absent !== undefined">{{
                                            allAppointment.totalcount_absent }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td class="text-end">
                                      <div class="table-action">
                                        <span v-if="selectedStatus === 'A' || selectedStatus === 'AL'">
                                          <span class="badge bg-warning text-white ms-2"
                                            style="font-size: 1rem; padding: 0.5rem 1rem;">Absent</span>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                                <!-- cancelled -->
                                <template v-for="allAppointment in allappointmentstatus" :key="allAppointment.id">
                                  <tr v-if="selectedStatus === 'C' || selectedStatus === 'AL'">
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-calendar-week fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          {{ selectedClinicName }}
                                          <span v-if="allAppointment.appointment_date !== undefined">{{
                                            formatedDate(allAppointment.appointment_date) }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-mars fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Male
                                          <span v-if="allAppointment.malecount_cancelled !== undefined">{{
                                            allAppointment.malecount_cancelled }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-venus fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Female
                                          <span v-if="allAppointment.femalecount_cancelled !== undefined">{{
                                            allAppointment.femalecount_cancelled }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-transgender fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Other
                                          <span v-if="allAppointment.othercount_cancelled !== undefined">{{
                                            allAppointment.othercount_cancelled }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-person fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Total
                                          <span v-if="allAppointment.totalcount_cancelled !== undefined">{{
                                            allAppointment.totalcount_cancelled }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td class="text-end">
                                      <div class="table-action">
                                        <span v-if="selectedStatus === 'C' || selectedStatus === 'AL'">
                                          <span class="badge bg-danger text-white ms-2"
                                            style="font-size: 1rem; padding: 0.5rem 1rem;">Cancelled</span>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                                <!-- confirmed -->
                                <template v-for="allAppointment in allappointmentstatus" :key="allAppointment.id">
                                  <tr v-if="selectedStatus === 'E' || selectedStatus === 'AL'">
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-calendar-week fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          {{ selectedClinicName }}
                                          <span v-if="allAppointment.appointment_date !== undefined">{{
                                            formatedDate(allAppointment.appointment_date) }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-mars fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Male
                                          <span v-if="allAppointment.malecount_confirmed !== undefined">{{
                                            allAppointment.malecount_confirmed }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-venus fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Female
                                          <span v-if="allAppointment.femalecount_confirmed !== undefined">{{
                                            allAppointment.femalecount_confirmed }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-transgender fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Other
                                          <span v-if="allAppointment.othercount_confirmed !== undefined">{{
                                            allAppointment.othercount_confirmed }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td>
                                      <h2 class="table-avatar">
                                        <router-link :to="'doctors-report'" class="avatar avatar-sm me-2">
                                          <i class="fa-solid fa-person fa-xl"></i>
                                        </router-link>
                                        <router-link :to="'doctors-report'">
                                          Total
                                          <span v-if="allAppointment.totalcount_confirmed !== undefined">{{
                                            allAppointment.totalcount_confirmed }}</span>
                                        </router-link>
                                      </h2>
                                    </td>
                                    <td class="text-end">
                                      <div class="table-action">
                                        <span v-if="selectedStatus === 'E' || selectedStatus === 'AL'">
                                          <span class="badge text-white ms-2"
                                            style="font-size: 1rem; padding: 0.5rem 1rem; background-color: #005700;">Confirmed</span>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
  <!-- /Main Wrapper -->
  <!-- Appointment Details Modal -->

  <doctorfooter />
  <!-- /Appointment Details Modal -->
</template>
<script>
import axios from "axios";
import { format, addMonths, getYear, getMonth } from "date-fns";
import doctorupcoming from "@/assets/json/doctor/doctorupcoming.json";
import doctortoday from "@/assets/json/doctor/doctortoday.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import DateRangePicker from "daterangepicker";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { ref } from "vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import jQuery from "jquery";
const $ = jQuery;

export default {
  components: {
    CircleProgress,
    DateRangePicker,
  },
  data() {
    const currentDate = new Date();
    const startdatex = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1)).toISOString().split('T')[0];
    const enddatex = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)).toISOString().split('T')[0];
    return {
      dateRange: {
        start: '',
        end: ''
      },
      timeframechanged: false,
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear().toString(),
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      selectedStatus: 'V',
      selectedClinic: '',
      status: [
        { label: "Visited", value: "V" },
        { label: "Absent", value: "A" },
        { label: "Confirmed", value: "E" },
        { label: "Cancelled", value: "C" },
        { label: "All", value: "AL" },
      ],
      clinics: [],
      years: [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1],
      title: "Reports",
      text: "Home",
      text1: "Reports",
      doctorDetails: [],
      DoctorId: null,
      statDetails: [],
      formattedDate: "",
      allappointmentstatus: [],
      allappointments: [],
      upcomingAppointments: [],
      upcomingAppointmentsfilter: [],
      weeklyAppointmentsfilter: [],
      currentAppointments: [],
      chooseAppointments: [],
      AllAppointmentsfilter: [],
      allAppointment: null,
      currentAppointmentsfilter: [],
      selectedAppointment: {},
      filteredAppointments: [],
      selectedDate: null,
      doctorupcoming: doctorupcoming,
      doctortoday: doctortoday,
      selectedTab: "choose",
      isActive: true,
      dummyStartTime: '2023-05-01',
      dummyEndTime: '2023-05-15',
      startDate: null,
      endDate: null,
      startdatex: startdatex,
      enddatex: enddatex,
      startdate: 'null',
      enddate: 'null',
      datetime: null,
      currentDate: currentDate,
      AllAppointmentsfilterexcel: [],
      AllAppointmentsfilterpdf: [],
      selectedClinicName: '',
    };
  },

  methods: {
    handleDateUpdate({ from, to }) {
      //console.log('Selected date range:', from, to);
    },
    timeframechange() {
      this.timeframechanged = true;
    },
    async yearframechange() {
      this.loading = true;
      this.startdatex = format(
        new Date(this.selectedYear, this.selectedMonth - 1, 1),
        "yyyy-MM-dd"
      );
      this.enddatex = format(
        new Date(this.selectedYear, this.selectedMonth, 0),
        "yyyy-MM-dd"
      );
      await this.fetchAllAppointments(this.startdatex, this.enddatex);
    },
    async monthframechange() {
      this.loading = true;
      this.startdatex = format(
        new Date(this.selectedYear, this.selectedMonth - 1, 1),
        "yyyy-MM-dd"
      );
      this.enddatex = format(
        new Date(this.selectedYear, this.selectedMonth, 0),
        "yyyy-MM-dd"
      );
      await this.fetchAllAppointments(this.startdatex, this.enddatex);
    },
    statusframechange() {
      this.fetchAllAppointments();
    },
    getClinicNameById(clinicId) {
      const clinic = this.clinics.find(clinic => clinic.id === clinicId);
      return clinic ? clinic.name : null;
    },
    clinicframechange() {
      this.selectedClinicName = this.getClinicNameById(this.selectedClinic);
      this.fetchAllAppointments();
    },
    initDateRangePicker() {
      const vm = this;
      $(function () {
        $("#daterange").daterangepicker({
          singleDatePicker: true,
          showDropdowns: true,
          autoUpdateInput: false,
          locale: {
            format: "YYYY-MM-DD",
          },
          startDate: vm.startdatex,
        }, function (start) {
          vm.startdatex = start.format("YYYY-MM-DD");
          vm.fetchAllAppointments();
        });
      });
    },
    initDateRangePickerok() {
      const vm = this;
      $(function () {
        $("#daterangeok").daterangepicker({
          singleDatePicker: true,
          showDropdowns: true,
          autoUpdateInput: false,
          locale: {
            format: "YYYY-MM-DD",
          },
          endDate: vm.enddatex,

        }, function (end) {
          vm.enddatex = end.format("YYYY-MM-DD");
          vm.fetchAllAppointments();
        });
      });
    },
    async fetchDatetime() {
      try {
        const response = await axios.get('/doctors/datetime');
        this.datetime = response.data[1];
        this.currentDate = new Date(this.datetime);
      } catch (error) {
        console.error('Error fetching datetime:', error);
      }
    },
    async fetchClinicData() {
      try {
        const response = await axios.get("/doctors/clinichospital");
        this.clinicData = response.data;
        this.clinics = response.data.map(clinic => ({
          id: clinic.clinicid,
          name: clinic.clinicname
        }));
        if (this.clinics.length > 0) {
          this.selectedClinic = this.clinics[0].id;
          this.selectedClinicName = this.clinics[0].name;
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },
    selectChooseAppointments() {
      if (this.dateRange.start && this.dateRange.end) {
        this.startDate = this.dateRange.start;
        this.endDate = this.dateRange.end;
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.toggle();
    },
    toggle() {
      this.isActive = !this.isActive;
      if (this.selectedDate) {
        this.filterAppointmentsByDate(this.selectedDate);
      }
      this.filterUpcomingAppointments();
    },

    formatShortDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      });
    },

    selectSpecificDay(date) {
      this.selectedDate = new Date(date);
      this.selectedTab = "specific";
      this.filterAppointmentsByDate(this.selectedDate);
    },
    selectChooseAppointments() {
      this.selectedTab = "choose";
      this.fetchAllAppointments();
    },
    selectTodayAppointments() {
      this.selectedTab = "today";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatedDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatTime(time) {
      if (!time || typeof time !== 'string') {
        console.error('Invalid time provided:', time);
        return 'Invalid Time';
      }
      const formattedTime = time.padStart(5, '0');
      return new Date(`2022-01-01T${formattedTime}`).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      });
    },
    getPatientImage(appointment) {
      return (
        appointment.patientimage || require("@/assets/img/patients/patient.jpg")
      );
    },
    async fetchDoctorDetails() {
      const doctorId = sessionStorage.getItem("userdbid");
      this.DoctorId = doctorId;
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${doctorId}`);
          docDetails = response.data;
          sessionStorage.setItem(
            `doctorDetails${doctorId}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctorDetails = docDetails;
        this.fetchDoctorStatCount(doctorId);
        this.formatDate();
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    async fetchDoctorStatCount(doctorId) {
      try {
        const response = await axios.get(`/doctors/${doctorId}/doctorstats`);
        this.statDetails = response.data;
      } catch (error) {
        console.error("Error fetching doctors count:", error);
      }
    },
    fetchUpcomingAppointments() {
      const doctorId = sessionStorage.getItem("userdbid");
      axios
        .get(`/doctors/${doctorId}/appointments?filter_type=upcoming`)
        .then(response => {
          this.upcomingAppointments = response.data.upcoming_appointments;
          this.currentAppointments = response.data.current_day_appointments;

          if (this.selectedDate && this.selectedTab === "specific") {
            this.filterAppointmentsByDate(this.selectedDate);
          } else if (this.selectedTab === "upcoming") {
            this.filterUpcomingAppointments();
          }
          this.filterUpcomingAppointments();
          this.filterWeeklyAppointments();
        })
        .catch(error => {
          console.error("Error fetching upcoming appointments", error);
        });
    },
    async fetchAllAppointments() {
      if (!this.datetime) {
        await this.fetchDatetime();
      }
      const doctorId = sessionStorage.getItem("userdbid");
      if (this.selectedTab === "choose") {
        this.startDate = this.startdatex;
        this.endDate = this.enddatex;

      }
      this.startdate = String(this.startDate);
      this.enddate = String(this.endDate);
      try {
        const response = await axios.get(`/doctors/${doctorId}/appointmentcount`, {
          params: { 
            clinicid:this.selectedClinic,
            custom_clinic_type: this.selectedClinic,
            filter_type: 'all',
            startDate: this.startdate,
            endDate: this.enddate,
          },
        });
        this.allappointmentstatus = response.data.all_appointments;
        if (this.selectedDate && this.selectedTab === "specific") {
          this.filterAppointmentsByDate(this.selectedDate);
        }
        else if (this.selectedTab === "weekly") {
          this.filterWeeklyAppointments();
        }
        else if (this.selectedTab === "upcoming") {
          this.filterUpcomingAppointments();
        } else if (this.selectedTab === "today") {
          this.filterCurrentAppointments();
        }
      } catch (error) {
        console.error("Error fetching all appointments", error);
      }
    },

    viewAppointmentDetails(appointment) {
      this.selectedAppointment = appointment;
    },
    exportAppointments(format) {
      if (format === "pdf") {
        this.AllAppointmentsfilterpdf = this.allappointmentstatus;
        this.exportToPDF(this.AllAppointmentsfilterpdf);
      } else if (format === "excel") {
        this.AllAppointmentsfilterexcel = this.allappointmentstatus;
        this.exportToExcel(this.AllAppointmentsfilterexcel);
      }
    },
    exportToPDF(Appointments) {

      let appointments = [];
      let appointmentsx = [];
      let appointmentsy = [];
      let appointmentsz = [];
      let allAppointments = [];
      const doc = new jsPDF();
      const doctorName = this.doctorDetails.doctorname;
      const clinicnamex = this.selectedClinicName;
      const startdatexcel = this.startdate;
      const enddatexcel = this.enddate;
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
      const formattedTime = currentDate.toLocaleTimeString();
      const currentDateTimex = `${formattedDate} ${formattedTime}`;
      const formattedDoctorName = `Doctor name: ${doctorName}`;
      const clinicNamey = `Clinic name: ${clinicnamex}`;
      const rangey = `Date range: ${startdatexcel} to ${enddatexcel}`;
      const currentDateTime = `Date: ${currentDateTimex}`;
      const statusLabels = {
        A: "Absent",
        V: "Visited",
        C: "Cancelled",
        E: "Confirmed"
      };
      if (this.selectedStatus === 'V' || this.selectedStatus === 'AL') {
        appointments = this.AllAppointmentsfilterpdf.map(appointment => [
          appointment.appointment_date,
          appointment.male_count,
          appointment.female_count,
          appointment.other_count,
          appointment.total_count,
          "Visited"
        ]);
        allAppointments = allAppointments.concat(appointments);
      }
      if (this.selectedStatus === 'A' || this.selectedStatus === 'AL') {
        appointmentsx = this.AllAppointmentsfilterpdf.map(appointment => [
          appointment.appointment_date,
          appointment.male_count,
          appointment.female_count,
          appointment.other_count,
          appointment.total_count,
          "Absent",
        ]);
        allAppointments = allAppointments.concat(appointmentsx);
      }
      if (this.selectedStatus === 'E' || this.selectedStatus === 'AL') {
        appointmentsy = this.AllAppointmentsfilterpdf.map(appointment => [
          appointment.appointment_date,
          appointment.male_count,
          appointment.female_count,
          appointment.other_count,
          appointment.total_count,
          "Confirmed",
        ]);
        allAppointments = allAppointments.concat(appointmentsy);
      }
      if (this.selectedStatus === 'C' || this.selectedStatus === 'AL') {
        appointmentsz = this.AllAppointmentsfilterpdf.map(appointment => [
          appointment.appointment_date,
          appointment.male_count,
          appointment.female_count,
          appointment.other_count,
          appointment.total_count,
          "Cancelled",
        ]);
        allAppointments = allAppointments.concat(appointmentsz);
      }
      let heading;
      if (this.selectedTab === "choose") {
        heading = "Appointment report";
      } else if (this.selectedTab === "upcoming") {
        heading = "Upcomming Appointments";
      } else {
        heading = "Appointment report"
      }

      const gap = 10;
      const gapx = 25;
      const yPos = gap;
      const textWidth =
        (doc.getStringUnitWidth(heading) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const xPos = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(heading, xPos, yPos);
      const doctorNameFontSize = 10;
      const headingFontSize = 16;

      doc.setFontSize(doctorNameFontSize);
      doc.text(formattedDoctorName, 15, gapx);
      const infoFontSize = 10;
      const infoGap = gapx + 10;

      doc.setFontSize(infoFontSize);
      doc.text(clinicNamey, 15, infoGap);

      const rangeGap = infoGap + 10;
      doc.text(rangey, 15, rangeGap);
      doc.setFontSize(doctorNameFontSize);
      const pageWidth = doc.internal.pageSize.width;
      const currentDateTimeWidth = (doc.getStringUnitWidth(currentDateTime) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightAlignedXPos = pageWidth - currentDateTimeWidth - 15;
      doc.text(currentDateTime, rightAlignedXPos, gapx);

      doc.autoTable({
        head: [["Appt date", "Male", "Female", "Other", "Total", "Status",]],
        body: allAppointments,
        startY: rangeGap + 6
      });
      doc.save("doc-appointments-report.pdf");
    },
    exportToExcel(Appointments) {
      const doctorName = this.doctorDetails.doctorname;
      const clinicnamex = this.selectedClinicName;
      const startdatexcel = this.startdate;
      const enddatexcel = this.enddate;
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Appointments");
      const currentDateTime = new Date().toLocaleString();
      worksheet.addRow([`Doctor name: ${doctorName}`]);
      worksheet.addRow([`Clinic name: ${clinicnamex}`]);
      worksheet.addRow([`Start date: ${startdatexcel} to End date:${enddatexcel}`]);
      worksheet.addRow([`Date: ${currentDateTime}`]);
      worksheet.mergeCells('A1:G1');
      worksheet.mergeCells('A2:G2');
      worksheet.mergeCells('A3:G3');
      worksheet.mergeCells('A4:G4');
      worksheet.addRow([
        "Appt date", "Male", "Female", "Other", "Total", "Status"
      ]);
      worksheet.columns = [
        { key: "appointmentDate", width: 20 },
        { key: "malecount", width: 15 },
        { key: "femalecount", width: 15 },
        { key: "othercount", width: 15 },
        { key: "totalcount", width: 15 },
        { key: "appointmentstatus", width: 15 }
      ];
      Appointments.forEach(appointment => {
        if (this.selectedStatus === 'V' || this.selectedStatus === 'AL') {
          worksheet.addRow({
            appointmentDate: appointment.appointment_date,
            malecount: appointment.male_count,
            femalecount: appointment.female_count,
            othercount: appointment.other_count,
            totalcount: appointment.total_count,
            appointmentstatus: 'Visited'
          });
        }
        if (this.selectedStatus === 'A' || this.selectedStatus === 'AL') {
          worksheet.addRow({
            appointmentDate: appointment.appointment_date,
            malecount: appointment.male_count,
            femalecount: appointment.female_count,
            othercount: appointment.other_count,
            totalcount: appointment.total_count,
            appointmentstatus: 'Absent'
          });
        }
        if (this.selectedStatus === 'E' || this.selectedStatus === 'AL') {
          worksheet.addRow({
            appointmentDate: appointment.appointment_date,
            malecount: appointment.male_count,
            femalecount: appointment.female_count,
            othercount: appointment.other_count,
            totalcount: appointment.total_count,
            appointmentstatus: 'Confirmed'
          });
        }
        if (this.selectedStatus === 'C' || this.selectedStatus === 'AL') {
          worksheet.addRow({
            appointmentDate: appointment.appointment_date,
            malecount: appointment.male_count,
            femalecount: appointment.female_count,
            othercount: appointment.other_count,
            totalcount: appointment.total_count,
            appointmentstatus: 'Cancelled'
          });
        }
      });
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "doc-appointments-report.xlsx";
        link.click();
      });
    }
  },
  async created() {
    await this.fetchDatetime();
    await this.fetchAllAppointments();
  },
  async mounted() {
    this.initDateRangePicker();
    this.initDateRangePickerok();
    await this.fetchDatetime();
    await this.fetchClinicData();
    await this.fetchAllAppointments();
  },

  async beforeMount() {
    this.fetchDoctorDetails();
    this.fetchUpcomingAppointments();
    await this.fetchDatetime();
    await this.fetchClinicData();
    await this.fetchAllAppointments();
    const percent = ref(75);
    setInterval(() => {
      if (percent.value === 25) {
        percent.value = 75;
      } else {
        percent.value = 25;
      }
    }, 1000);
  },
  name: "doctor-dashboard"
};
</script>
<style scoped>
.custom-select-wrapper {
  position: relative;
  display: inline-block;
}

.custom-select-modern {
  appearance: none;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: pointer;
  width: 100px;
  color: #000;
}

.custom-select-modern:focus {
  border-color: #245dd6;
  outline: none;
}

.custom-select-modernx {
  appearance: none;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: pointer;
  width: 150px;
  color: #000;
}

.custom-select-modernx:focus {
  border-color: #245dd6;
  outline: none;
}

.custom-select-wrapper::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #555;
}

.date-range-selection-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.date-range-selection {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 5px;
}

.daterange-container {
  display: flex;
  align-items: center;
  padding: 2px;
}

@media (max-width: 768px) {
  .daterange-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.calendar-icon {
  font-size: 20px;
  color: #4caf50;
  margin-right: 1px;
}

.daterange-input {
  padding: 5px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: pointer;
  color: #000;
}

.daterange-input:hover {
  background-color: #e0e0e0;
}

.daterange-input:focus {
  outline: none;
  background-color: #e0f7e0;
}

.daterange-to {
  margin: 0 10px;
  font-size: 16px;
  color: #333;
}

.dropdown-container {
  margin-left: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.custom-select {
  padding: 1px;
  border: none;
  border-radius: 4px;
}

.generate-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
  transition: background-color 0.3s;
}

.generate-button:hover {
  background-color: #1d7eda;
}

.export-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 75px;
}

.download-text {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  opacity: 0.5;
}

.download-report {
  display: flex;
  align-items: center;
}

.export-iconx {
  width: 40px;
  height: 30px;
  margin-left: 10px;
  cursor: pointer;
  align-items: top;
}


.export-iconx:hover {
  opacity: 0.7;
}


@media (max-width: 768px) {
  .export-iconx {
    margin-left: 10px;
    width: 25px;
    height: 45px;
  }
}

@media (max-width: 767px) {
  .export-buttons {
    margin-top: 10px;
  }
}


.modal-content .btn {
  cursor: auto;
}
</style>
