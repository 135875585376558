<template>
  <!-- Work Section -->
  <section class="work-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 work-img-info aos" data-aos="fade-up">
          <div class="work-img">
            <img src="@/assets/img/work-img.png" class="img-fluid" alt="Image" />
          </div>
        </div>
        <div class="col-lg-8 col-md-12 work-details">
          <div class="section-header-one aos" data-aos="fade-up">
            <h5>{{ $t("work_how_it_works") }}</h5>
            <h2 class="section-title">{{ $t("work_easy_steps") }}</h2>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <div class="work-info">
                <div class="work-icon">
                  <span><img src="@/assets/img/icons/work-01.svg" alt="Image" /></span>
                </div>
                <div class="work-content">
                  <h5>{{ $t("work_search_doctor") }}</h5>
                  <p>{{ $t("work_search_doctor_desc") }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <div class="work-info">
                <div class="work-icon">
                  <span><img src="@/assets/img/icons/work-02.svg" alt="Image" /></span>
                </div>
                <div class="work-content">
                  <h5>{{ $t("work_check_profile") }}</h5>
                  <p>{{ $t("work_check_profile_desc") }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <div class="work-info">
                <div class="work-icon">
                  <span><img src="@/assets/img/icons/work-03.svg" alt="Image" /></span>
                </div>
                <div class="work-content">
                  <h5>{{ $t("work_schedule_appointment") }}</h5>
                  <p>{{ $t("work_schedule_appointment_desc") }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <div class="work-info">
                <div class="work-icon">
                  <span><img src="@/assets/img/icons/work-04.svg" alt="Image" /></span>
                </div>
                <div class="work-content">
                  <h5>{{ $t("work_get_solution") }}</h5>
                  <p>{{ $t("work_get_solution_desc") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Work Section -->

</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexArticles from "@/assets/json/indexarticles.json";
export default {
  data() {
    return {
      IndexArticles: IndexArticles,
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
