<template>
  <!-- Edit Time Slot Modal -->
  <div class="modal fade custom-modal" id="edit_time_slot">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Time Slots</h5>
          <!-- <div>
            <h3>Debug Output</h3>
            <pre>{{ editedSelectedDayData }}</pre>
          </div> -->
          <b-button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData"
          ></b-button>
        </div>
        <div class="modal-body">
          <p v-if="errorvalidation">
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
              {{ errorvalidation }}
            </b-alert>
          </p>
          <form>
            <div class="hours-info">
              <div v-for="(timeslot, index) in editedSelectedDayData" :key="index">
                <div class="row hours-cont">
                  <div class="col-12 col-md-10">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Start Time</label>
                          <input
                            class="form-control"
                            :value="formattedStartTime(timeslot)"
                            @input="updateStartTime(timeslot, $event)"
                            type="time"
                            :class="{ 'is-invalid': isInvalidTime(index, 'start') }"
                          />
                          <div
                            v-if="isInvalidTime(index, 'start')"
                            class="invalid-feedback"
                          >{{ isInvalidTime(index, 'start') === 'invalid' ? 'Invalid time' : 'Overlapping time' }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">End Time</label>
                          <input
                            class="form-control"
                            :value="formattedEndTime(timeslot)"
                            @input="updateEndTime(timeslot, $event)"
                            type="time"
                            :class="{ 'is-invalid': isInvalidTime(index, 'end') }"
                          />
                          <div
                            v-if="isInvalidTime(index, 'end')"
                            class="invalid-feedback"
                          >{{ isInvalidTime(index, 'end') === 'invalid' ? 'Invalid time' : 'Overlapping time' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-2">
                    <label class="d-md-block d-sm-none d-none">&nbsp;</label>
                    <a
                      href="javascript:void(0);"
                      class="btn btn-danger trash"
                      @click="deleteslot(index)"
                    >
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="add-more mb-3">
              <a href="javascript:void(0);" class="add-hours" @click="editslot()">
                <i class="fa fa-plus-circle"></i> Add More
              </a>
            </div>
            <div class="submit-section text-center">
              <b-button
                type="submit"
                class="btn btn-primary submit-btn"
                @click.prevent="saveTimeSlots"
              >Save</b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Time Slot Modal -->
</template>


<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    selectedDayData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editedSelectedDayData: [],
      showDismissibleAlert: false,
      errorvalidation: '',
    };
  },
  watch: {
    selectedDayData: {
      handler(newVal) {
        this.editedSelectedDayData = JSON.parse(JSON.stringify(newVal));
      },
      immediate: true
    }
  },
  methods: {
    formattedStartTime(timeslot) {
      return timeslot.starttime || "";
    },
    formattedEndTime(timeslot) {
      return timeslot.endtime || "";
    },
    updateStartTime(timeslot, event) {
      timeslot.starttime = event.target.value;
    },
    updateEndTime(timeslot, event) {
      timeslot.endtime = event.target.value;
    },
    editslot() {
      const existingSlot = this.editedSelectedDayData[0];

      if (existingSlot) {
        const newSlot = {
          doctorid: existingSlot.doctorid,
          clinicid: existingSlot.clinicid,
          clinicname: existingSlot.clinicname,
          starttime: "",
          endtime: "",
          weekday: existingSlot.weekday
        };

        this.editedSelectedDayData.push(newSlot);
      }
    },
    deleteslot(index) {
      //console.log("Deleting slot at index:", index);
      this.editedSelectedDayData.splice(index, 1);
    },
    saveTimeSlots() {
      const allSlotsDeleted = this.editedSelectedDayData.length === 0;

      if (allSlotsDeleted) {
        const dataToSend = [
          {
            doctorid: this.selectedDayData[0].doctorid,
            clinicid: this.selectedDayData[0].clinicid,
            starttime: null,
            endtime: null,
            weekday: this.selectedDayData[0].weekday
          }
        ];

        axios
          .post("/doctors/scheduletiming", dataToSend)
          .then(response => {
            Swal.fire("Success", "Slots saved successfully", "success");
            this.$emit("slots-edited");
            const closeButton = document.querySelector("#edit_time_slot .btn-close");
            if (closeButton) {
              closeButton.click();
            }
          })
          .catch(error => {
            this.showDismissibleAlert = true;
            this.errorvalidation = "Error saving data";
          });
      } else {
        const hasInvalidSlots = this.editedSelectedDayData.some(
          (slot, index) => {
            return (
              this.isInvalidTime(index, "start") ||
              this.isInvalidTime(index, "end")
            );
          }
        );

        if (hasInvalidSlots) {
          this.showDismissibleAlert = true;
          this.errorvalidation = "Invalid or overlapping time slots";
          return;
        }
        axios
          .post("/doctors/scheduletiming", this.editedSelectedDayData)
          .then(response => {
            Swal.fire("Success", "Slots saved successfully", "success");
            this.$emit("slots-edited");
            const closeButton = document.querySelector("#edit_time_slot .btn-close");
            if (closeButton) {
              closeButton.click();
            }
          })
          .catch(error => {
            this.showDismissibleAlert = true;
            this.errorvalidation = "Error saving data";
          });
      }
    },
    deleteeditslot() {
      this.slot.splice(1);
    },
    isInvalidTime(index, type) {
      const currentSlot = this.editedSelectedDayData[index];

      const startTime = new Date(`1970-01-01T${currentSlot.starttime}`);
      const endTime = new Date(`1970-01-01T${currentSlot.endtime}`);

      if (type === "start" && startTime >= endTime) {
        9;
        return "invalid";
      }

      for (let i = 0; i < this.editedSelectedDayData.length; i++) {
        if (i !== index) {
          const slot = this.editedSelectedDayData[i];

          const slotStartTime = new Date(`1970-01-01T${slot.starttime}`);
          const slotEndTime = new Date(`1970-01-01T${slot.endtime}`);

          if (
            this.checkOverlap(startTime, endTime, slotStartTime, slotEndTime)
          ) {
            return "overlap";
          }
        }
      }

      return false;
    },
    checkOverlap(start1, end1, start2, end2) {
      return start1 < end2 && end1 > start2;
    },
    resetData() {
      this.editedSelectedDayData = JSON.parse(
        JSON.stringify(this.selectedDayData)
      );
    }
  },
  mounted() {
    this.editedSelectedDayData = JSON.parse(
      JSON.stringify(this.selectedDayData)
    );
  }
};
</script>
