<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <clinicheader v-if="!isModalOpen &&  !ischeckinModalOpen"></clinicheader>
    <breadcrumb-clinic :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <router-link to="/clinic-dashboard">
            <i class="fa-solid fa-arrow-left-long"></i> Back
          </router-link>

          <doctorsidebar2></doctorsidebar2>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-md-12">
                <div class="card dash-card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar2">
                            <div class="circle-graph2">
                              <circle-progress :percent="65" />
                              <img src="@/assets/img/icon-02.png" class="img-fluid" alt="Patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Appointments Today</h6>
                            <h3>{{ statDetails.todayAppointments }}</h3>
                            <p class="text-muted">{{ formattedDate }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget">
                          <div class="circle-bar circle-bar3">
                            <div class="circle-graph3">
                              <circle-progress :percent="50" />
                              <img src="@/assets/img/icon-03.png" class="img-fluid" alt="Patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Upcoming Appointments</h6>
                            <h3>{{ statDetails.upcomingAppointment }}</h3>
                            <p class="text-muted">{{ formattedDate }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-4">
                        <div class="dash-widget dct-border-rht">
                          <div class="circle-bar circle-bar1">
                            <div class="circle-graph1">
                              <circle-progress :percent="75" />
                              <img src="@/assets/img/icon-01.png" class="img-fluid" alt="patient" />
                            </div>
                          </div>
                          <div class="dash-widget-info">
                            <h6>Total Patients</h6>
                            <h3>{{ statDetails.totalPatients }}</h3>
                            <p class="text-muted">Till Today</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h4 class="mb-4">Patient Appointments</h4>
                <div class="d-flex justify-content-between mb-3">
                  <router-link to="/add-clinic-appointment" class="btn btn-primary">Add Appointment</router-link>
                  <div>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="PDF"
                      @click="exportAppointments('pdf')"
                    >
                      <img src="@/assets/img/pdf.svg" alt="Export to PDF" class="export-icon" />
                    </a>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Excel"
                      @click="exportAppointments('excel')"
                    >
                      <img src="@/assets/img/excel.svg" alt="Export to Excel" class="export-icon" />
                    </a>
                  </div>
                </div>

                <div class="appointment-tab">
                  <!-- Appointment Tab -->
                  <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        href="#today-appointments"
                        data-bs-toggle="tab"
                        @click="selectTodayAppointments"
                      >
                        Today
                        <span class="badge custom-badge ms-1">
                          {{ todayCount
                          }}
                        </span>
                      </a>
                    </li>
                    <li class="nav-item" v-for="(day, index) in nextFiveDays" :key="index">
                      <a
                        class="nav-link"
                        href="#specific-appointments"
                        @click="selectSpecificDay(day)"
                        data-bs-toggle="tab"
                      >
                        {{ formatShortDate(day) }}
                        <span class="badge custom-badge ms-1">
                          {{
                          nextFiveDaysCount[index] }}
                        </span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#upcoming-appointments"
                        @click="selectUpcomingAppointments"
                        data-bs-toggle="tab"
                      >
                        All Upcoming
                        <span class="badge custom-badge ms-1">
                          {{ upcomingCount
                          }}
                        </span>
                      </a>
                    </li>
                  </ul>
                  <!-- /Appointment Tab -->
                  <div class="align-container" v-if="selectedTab === 'today'">
                    <div class="left-align">
                      <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLeftx"></i>
                    </div>
                    <div class="right-align">
                      <i
                        class="fa fa-long-arrow-right"
                        aria-hidden="true"
                        @click="scrollTableRightx"
                      ></i>
                    </div>
                  </div>
                  <div class="align-container" v-if="selectedTab === 'specific'">
                    <div class="left-align">
                      <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLefty"></i>
                    </div>
                    <div class="right-align">
                      <i
                        class="fa fa-long-arrow-right"
                        aria-hidden="true"
                        @click="scrollTableRighty"
                      ></i>
                    </div>
                  </div>
                  <div class="align-container" v-if="selectedTab === 'upcoming'">
                    <div class="left-align">
                      <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLeft"></i>
                    </div>
                    <div class="right-align">
                      <i
                        class="fa fa-long-arrow-right"
                        aria-hidden="true"
                        @click="scrollTableRight"
                      ></i>
                    </div>
                  </div>

                  <div class="tab-content">
                    <!-- Upcoming Appointment Tab -->
                    <div class="tab-pane" id="upcoming-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive" ref="tableContainer">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr></tr>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in upcomingAppointmentsfilter"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span
                                          v-if="appointment.patientage"
                                        >{{ appointment.patientid }} (Age: {{appointment.patientage}})</span>
                                        <span v-else>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <!-- <td>{{ item.Type }}</td> -->
                                  <!-- <td class="text-center">{{ item.PaidAmount }}</td> -->
                                  <td class="text-end">
                                    <div class="table-action">
                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a
                                        v-if="appointment.appointmentstatus === 'E' && !appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <a
                                        v-else-if="appointment.appointmentstatus === 'E' && appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-warning-light"
                                        @click="markasAbsent(appointment.patientid, appointment.appointmentid)"
                                      >
                                        <i class="fas fa-user-slash"></i> Mark as Absent
                                      </a>
                                      <span v-else-if="appointment.prescripid > 0">
                                        <span
                                          class="badge bg-success-light text-white badge-lg"
                                        >Visited</span>
                                      </span>

                                      <span v-else-if="appointment.appointmentstatus === 'A'">
                                        <span
                                          class="badge bg-warning-light text-dark badge-lg"
                                        >Absent</span>
                                      </span>
                                      <span v-else-if="appointment.appointmentstatus === 'C'">
                                        <span class="badge bg-danger text-white">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{{ appointment.reason }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Upcoming Appointment Tab -->
                    <!-- Today Appointment Tab -->
                    <div v-if="selectedTab === 'today'" class="mb-3">
                      <div class="form-check form-switch">
                        <label class="form-check-label" for="sort-switch">Sort by Token Number</label>
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="sort-switch"
                          v-model="isSorted"
                          @change="toggleSorting"
                        />
                      </div>
                    </div>
                    <div class="tab-pane show active" id="today-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive" ref="tableContainerx">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <!-- <th>Type</th> -->
                                  <!-- <th class="text-center">Paid Amount</th> -->
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>
                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in sortedFilteredAppointments"
                                  :key="appointment.appointmentid"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)
                                          "
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span>{{ appointment.patientid }}</span>
                                      </router-link>

                                      <div
                                        v-if="appointment.tokenid > 0 && appointment.appointmentstatus !==  'C'"
                                        class="checked-in-box"
                                      >
                                        <div class="token-number">
                                          <span>
                                            Token No:
                                            <b>{{ appointment.tokenid }}</b>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="appointment.tokenid === 0 && appointment.appointmentstatus !== 'C'"
                                        class="check-in-container"
                                      >
                                        <button
                                          class="main-btn"
                                          @click="opencheckinModal(appointment)"
                                        >Generate Token</button>
                                      </div>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate)
                                    }}
                                    <span
                                      class="d-block text-info"
                                    >
                                      {{
                                      formatTime(appointment.appointmenttime)
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <!-- <td>{{ item.Type }}</td>
                                  <td class="text-center">{{ item.PaidAmount }}</td>-->
                                  <td class="text-end">
                                    <div class="table-action">
                                      <!-- Add Prescription Button -->
                                      <button
                                        v-if="appointment.tokenid > 0 && appointment.prescripid > 0"
                                        class="main-btn"
                                        @click="viewPrescription(appointment.prescripid, appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)"
                                      >View Prescription</button>

                                      <button
                                        v-if="appointment.tokenid > 0 && appointment.prescripid === 0  && (appointment.appointmentstatus === 'E' || appointment.appointmentstatus === 'V')"
                                        class="main-btn"
                                        @click="openPrescription(appointment.patientid, appointment.appointmentid, appointment.firstname, appointment.lastname)"
                                      >Add Prescription</button>
                                      <a
                                        v-if="appointment.tokenid > 0 && appointment.prescripid > 0 && isMobile"
                                        href="javascript:void(0);"
                                        @click="print_Download(appointment.prescripid,  appointment.patientid, appointment.appointmentid)"
                                        class="btn btn-sm bg-primary-light me-2"
                                      >
                                        <i class="fas fa-download"></i> Download PDF
                                      </a>
                                      <a
                                        v-if="appointment.tokenid > 0 && appointment.prescripid > 0 && !isMobile"
                                        href="javascript:void(0);"
                                        @click="print_Download(appointment.prescripid, appointment.appointmentid, appointment.patientid)"
                                        class="btn btn-sm bg-primary-light me-2"
                                      >
                                        <i class="fas fa-print"></i> Print
                                      </a>
                                      <a
                                        v-if="(appointment.tokenid > 0 && (appointment.appointmentstatus === 'E' || appointment.appointmentstatus ==='V') ) && (appointment.tokenid > 0 &&  !(appointment.prescripid > 0)) "
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-warning-light"
                                        @click="markasAbsent(appointment.patientid, appointment.appointmentid)"
                                      >
                                        <i class="fas fa-user-slash"></i> Mark as Absent
                                      </a>
                                      <!-- <a
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-success-light me-2"
                                      >
                                        <i class="fas fa-check"></i> Accept
                                      </a>-->
                                      <a
                                        v-if="appointment.tokenid === 0 && appointment.appointmentstatus === 'E' && !appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <a
                                        v-else-if="appointment.tokenid === 0 && appointment.appointmentstatus === 'E' && appointment.ispast"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-warning-light"
                                        @click="markasAbsent(appointment.patientid, appointment.appointmentid)"
                                      >
                                        <i class="fas fa-user-slash"></i> Mark as Absent
                                      </a>

                                      <span
                                        v-else-if="appointment.prescripid > 0"
                                        class="badge bg-success-light text-white badge-lg"
                                      >Visited</span>
                                      <span
                                        v-else-if="appointment.appointmentstatus === 'A'"
                                        class="badge bg-warning-light text-dark badge-lg"
                                      >Absent</span>

                                      <span v-else-if="appointment.appointmentstatus === 'C'">
                                        <span class="badge bg-danger text-white">Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{{ appointment.reason }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Today Appointment Tab -->
                    <!-- Specific Day Appointment Tab -->
                    <div class="tab-pane" id="specific-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive" ref="tableContainery">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <!-- king -->
                                  <th>Patient Name</th>
                                  <th>Appt Date</th>
                                  <th>Clinic Name</th>
                                  <th style="text-align: right;">
                                    <label class="custom_check" style="text-align: right;">
                                      <input
                                        type="checkbox"
                                        id="active-appointments"
                                        v-model="isActive"
                                        @change="toggleActiveStatus"
                                        checked
                                      />
                                      <span class="checkmark"></span>
                                      Include Cancelled
                                    </label>
                                  </th>

                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="appointment in filteredAppointments"
                                  :key="appointment.id"
                                >
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link
                                        :to="'patient-profile/' + appointment.patientid"
                                        class="avatar avatar-sm me-2"
                                      >
                                        <img
                                          class="avatar-img rounded-circle"
                                          :src="getPatientImage(appointment)"
                                          alt="User Image"
                                        />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + appointment.patientid">
                                        {{ appointment.firstname }} {{ appointment.lastname }}
                                        <span
                                          v-if="appointment.patientage"
                                        >{{ appointment.patientid }} (Age: {{appointment.patientage}})</span>
                                        <span v-else>{{ appointment.patientid }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    {{ formatedDate(appointment.appointmentdate) }}
                                    <span
                                      class="d-block text-info"
                                    >{{ formatTime(appointment.appointmenttime) }}</span>
                                  </td>
                                  <td>{{ appointment.clinicname }}</td>
                                  <td>
                                    <div class="table-action">
                                      <a
                                        href="javascript:void(0)"
                                        class="btn btn-sm bg-info-light"
                                        data-bs-toggle="modal"
                                        @click="viewAppointmentDetails(appointment)"
                                        data-bs-target="#appt_details"
                                      >
                                        <i class="far fa-eye"></i> View
                                      </a>
                                      <span style="margin-left: 5px;"></span>
                                      <a
                                        v-if="appointment.appointmentstatus === 'E'"
                                        href="javascript:void(0);"
                                        class="btn btn-sm bg-danger-light"
                                        @click="cancelAppointment(appointment)"
                                      >
                                        <i class="fas fa-times"></i> Cancel
                                      </a>
                                      <span v-if="appointment.appointmentstatus === 'V'">
                                        <span
                                          class="badge rounded-pill bg-success-light"
                                          style="font-size: 1em; padding: 0.4em 0.8em;"
                                        >Visited</span>
                                      </span>
                                      <span v-if="appointment.appointmentstatus === 'A'">
                                        <span
                                          class="badge rounded-pill bg-warning-light"
                                          style="font-size: 1em; padding: 0.4em 0.8em;"
                                        >Absent</span>
                                      </span>
                                      <span v-if="appointment.appointmentstatus === 'C'">
                                        <span
                                          class="badge rounded-pill bg-danger text-white ms-2"
                                          style="font-size: 1em; padding: 0.4em 0.8em;"
                                        >Cancelled</span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{{ appointment.reason }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Specific Day Appointment Tab -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
  <!-- /Main Wrapper -->
  <!-- Appointment Details Modal -->
  <div class="modal fade custom-modal" id="appt_details">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Appointment Details</h5>
          <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
        </div>
        <div class="modal-body">
          <ul class="info-details">
            <li>
              <div class="details-header">
                <div class="row">
                  <div class="col-md-6">
                    <li>
                      <span class="title">Appointment ID:</span>
                      <span class="text">{{ selectedAppointment.appointmentid }}</span>
                    </li>
                    <li>
                      <span class="title">Appointment Date & Time:</span>
                      <span class="text">
                        {{ formatDate(selectedAppointment.appointmentdate) }} {{
                        formatTime(selectedAppointment.appointmenttime) }}
                      </span>
                    </li>
                  </div>
                  <div class="col-md-6">
                    <div class="text-end">
                      <b-button
                        type="button"
                        :class="{ 'btn bg-success-light btn-sm': appointmentStatus === 'Upcoming', 'btn bg-danger-light btn-sm': appointmentStatus !== 'Upcoming' }"
                        id="topup_status"
                      >{{ appointmentStatus }}</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <span class="title">Status:</span>
              <span class="text">{{ appointmentStatus }}</span>
            </li>
            <li>
              <span class="title">Confirm Date:</span>
              <span class="text">{{ formatedDate(selectedAppointment.createddate) }}</span>
            </li>
            <!-- <li>
              <span class="title">Paid Amount</span>
              <span class="text">$450</span>
            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <clinicfooter />
  <!-- /Appointment Details Modal -->
  <!-- Add Prescription Modal -->
  <div v-if="isModalOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Prescription</h2>
        <h4>Patient Name: {{ patientFirstName }} {{ patientLastName }}</h4>
        <button @click="closeModal">✕</button>
      </div>
      <div class="patient-description-section">
        <h5>Patient Description:</h5>
        <textarea
          id="patientDescription"
          v-model="prescripNote"
          placeholder="Enter patient condition, diagnosis, etc."
          class="patient-description-textarea"
          @input="prescripEdited(prescripId)"
        ></textarea>
      </div>
      <div class="modal-body">
        <div class="medicine-list-container">
          <div class="search-bar">
            <input type="text" placeholder="Search medicines..." v-model="search" />
            <i class="fa fa-search"></i>
          </div>

          <draggable
            v-model="filteredMedicines"
            class="medicine-list"
            :group="{ name: 'prescription', pull: true, put: false }"
            item-key="medicinename"
          >
            <template #header>
              <h3>Medicine List</h3>
            </template>
            <template #item="{ element }">
              <div class="medicine-item">
                <div class="medicine-details">
                  <p>
                    <strong>{{ element.medicinename }}</strong>
                    ({{ element.brandname }})
                  </p>
                  <p>{{ element.dosage }}</p>
                </div>
                <!--button @click="addToPrescription(element)">+</button-->
              </div>
            </template>
          </draggable>
        </div>
        <div class="prescription-list-container">
          <draggable
            v-model="prescription"
            class="prescription-list"
            :group="{ name: 'prescription', pull: false, put: true }"
            item-key="prescripmedid"
            @add="onAddToPrescription"
          >
            <template #header>
              <h3>Prescription List</h3>
            </template>
            <template #item="{ element }">
              <div class="medicine-item">
                <div class="medicine-details">
                  <div class="edit-section">
                    <div v-if="!element.isEditing">
                      <p>
                        <strong>{{ element.medicinename }}</strong>
                        ({{ element.brandname }})
                        <span
                          @click="toggleEdit(element)"
                        >
                          <i class="fas fa-pencil-alt"></i> Edit
                        </span>
                      </p>
                      <p>{{ element.frequency }}, {{ element.duration }} days</p>
                    </div>
                    <div v-if="element.isEditing">
                      <input v-model="element.medicinename" placeholder="Medicine Name" />
                      <input v-model="element.brandname" placeholder="Brand Name" />
                      <input v-model="element.dosage" placeholder="Type, Strength (mg)" />
                      <input v-model="element.frequency" placeholder="Frequency" />
                      <button @click="saveEdit(element)">OK</button>

                      <!-- Frequency Buttons -->
                      <!--div class="frequency-buttons">
            <button
              v-for="time in ['Morning', 'Afternoon', 'Night']"
              :key="time"
              :class="{'active': element.frequency && element.frequency.includes(time)}"
              @click="toggleFrequency(element, time)"
            >
              {{ time }}
            </button>
                      </div-->

                      <!-- Meal Toggle -->
                      <!--div class="meal-toggle">
            <span @click="toggleMeal(element, 'before')" :class="{ active: element.meal === 'before' }">Before Food</span> | 
            <span @click="toggleMeal(element, 'after')" :class="{ active: element.meal === 'after' }">After Food</span>
                      </div-->

                      <!-- duration-->
                      <div class="duration-field">
                        <p>
                          No. of Days:
                          <input
                            type="number"
                            v-model="element.duration"
                            class="duration-input"
                            placeholder="1"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Note Section -->
                  <div>
                    <span @click="toggleNoteSection(element)">
                      <i class="fas fa-pencil-alt"></i>
                      <!-- Small pencil icon -->
                      {{ element.showNote ? 'Close Note' : 'Open Note' }}
                      <p v-if="!element.showNote" class="note-preview">
                        {{ element.note ? (element.note.length > 25 ? element.note.substring(0, 25) + '...' :
                        element.note) : 'Click to Add Note: a/f food or b/f food, etc' }}
                      </p>
                    </span>
                    <textarea
                      class="note-textarea"
                      v-if="element.showNote"
                      v-model="element.note"
                      @input="markAsEdited(element)"
                    ></textarea>
                  </div>
                </div>

                <!-- Delete Button -->
                <button
                  v-if="!element.isEditing"
                  class="delete"
                  @click="removeFromPrescription(element)"
                >✕</button>
              </div>
            </template>
          </draggable>
        </div>
      </div>
      <div class="modal-footer">
        <button
          v-if="prescripId === 0"
          :disabled="isSaveDisabled"
          @click="addPrescription(patientid, appointmentid, prescripNote)"
        >Create</button>
        <button
          v-else-if="isprescripEdited"
          :disabled="isSaveDisabled"
          @click="updatePrescription(patientid, appointmentid, prescripId, prescripNote)"
        >Save</button>
        <button v-else :disabled="isSaveDisabled" @click="createPrescription">Save</button>
        <button @click="closeModal">Close</button>
      </div>
    </div>
  </div>

  <!-- Check-in Modal -->

  <div v-if="ischeckinModalOpen" class="checkinmodal-overlay" @click.self="closecheckinModal">
    <div class="checkinmodal-content">
      <div class="checkinmodal-header">
        <h3>Appointment Details</h3>
        <button type="button" class="btn-close" @click="back" aria-label="Close"></button>
      </div>
      <div class="checkinmodal-body">
        <p>
          Patient Name:
          <strong>{{ selectedAppointment.firstname }} {{ selectedAppointment.lastname }}</strong>
        </p>
        <p>
          Appointment Date:
          <strong>{{ formatDate(selectedAppointment.appointmentdate) }}</strong>
        </p>
        <p>
          Appointment Time:
          <strong>{{ formatTime(selectedAppointment.appointmenttime) }}</strong>
        </p>
        <p>
          Clinic:
          <strong>{{ selectedAppointment.clinicname }}</strong>
        </p>
      </div>
      <b-alert
        v-model="showAlert"
        variant="warning"
        class="mt-2"
      >Tokens can only be generated 30 minutes prior to the Appointment Time.</b-alert>
      <div class="checkinmodal-footer">
        <button
          type="button"
          :disabled="isDisabled"
          class="confirm-btn"
          @click="confirmCheckIn(selectedAppointment)"
        >
          <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
          Confirm
        </button>
        <button type="button" class="back-btn" @click="back">Back</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import doctorupcoming from "@/assets/json/doctor/doctorupcoming.json";
import doctortoday from "@/assets/json/doctor/doctortoday.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import draggable from "vuedraggable";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { ref } from "vue";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import "jspdf-autotable";
import ExcelJS from "exceljs";
export default {
  components: {
    CircleProgress,
    draggable
  },
  data() {
    return {
      title: "Dashboard",
      text: "Clinic Dashboard",
      text1: "Clinic Doctor Dashboard ",
      selectedClinic: "all",
      isSorted: false,
      isSaveDisabled: true,
      isCreateDisabled: true,
      isModalOpen: false,
      ischeckinModalOpen: false,
      isEditingNote: false,
      newNote: "",
      medicines: [],
      medicine: [],
      patientFirstName: "",
      patientLastName: "",
      appointmentid: null,
      precrip_id: null,
      prescription: [],
      prescripNote: "",
      isprescripEdited: false,
      prescription_exists: false,
      search: "",
      doctorDetails: [],
      DoctorId: null,
      statDetails: [],
      formattedDate: "",
      upcomingAppointments: [],
      upcomingAppointmentsfilter: [],
      currentAppointments: [],
      currentAppointmentsfilter: [],
      selectedAppointment: {},
      filteredAppointments: [],
      selectedDate: null,
      doctorupcoming: doctorupcoming,
      doctortoday: doctortoday,
      selectedTab: "today",
      isActive: false,
      doctorinfo: [],
      tokenid: null,
      istokengen: false,
      selectedAppointment: {},
      isDisabled: true,
      loading: false,
      showAlert: true,
      prescripId: null,
      appointmentid: null
    };
  },
  computed: {
    sortedFilteredAppointments() {
      let appointmentsToDisplay = [...this.currentAppointmentsfilter];

      if (this.isSorted) {
        appointmentsToDisplay.sort((a, b) => {
          if (a.tokenid === 0 && b.tokenid !== 0) {
            return 1;
          } else if (a.tokenid !== 0 && b.tokenid === 0) {
            return -1;
          } else {
            return a.tokenid - b.tokenid;
          }
        });
      }

      return appointmentsToDisplay;
    },
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isWindows = /Windows/i.test(userAgent);
      const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      const isMobile = /Mobi|Android/i.test(userAgent);
      return (isMobile || isiOS) && !isWindows;
    },
    filteredMedicines() {
      return this.medicines.filter(item => {
        return (
          !this.search ||
          item.medicinename.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    appointmentStatus() {
      if (this.selectedAppointment.appointmentstatus === "E") {
        return "Upcoming";
      } else {
        return "Cancelled";
      }
    },
    nextFiveDays() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const nextFiveDays = [];
      for (let i = 0; i < 6; i++) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + i);
        const formattedDate = nextDate.toISOString().slice(0, 10);
        nextFiveDays.push(formattedDate);
      }
      return nextFiveDays;
    },
    todayCount() {
      return this.currentAppointmentsfilter.length;
    },
    nextFiveDaysCount() {
      return this.nextFiveDays.map(date => {
        return this.upcomingAppointmentsfilter.filter(appointment => {
          return (
            new Date(appointment.appointmentdate).toISOString().slice(0, 10) ===
            date
          );
        }).length;
      });
    },
    upcomingCount() {
      return this.upcomingAppointmentsfilter.length;
    }
  },
  watch: {
    search(newValue) {
      this.filteredMedicines;
    },
    prescription(newValue) {
      if (newValue.length > 0) {
        this.isSaveDisabled = false;
        this.isCreateDisabled = false;
      }
    }
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab;
    },
    async isValidTime(appointmentDate, appointmentTime) {
      try {
        const response = await axios.get("/doctors/datetime");
        const [month, date, day, time24, time12] = response.data;

        //console.log("response:", response);
        //console.log("Appointment Date:", appointmentDate);
        //console.log("Appointment Time:", appointmentTime);

        const appointmentDateTimeString = `${appointmentDate}T${appointmentTime}`;
        const appointmentDateTime = new Date(appointmentDateTimeString);

        const currentDateTimeString = `${date}T${time24}`;
        const currentDateTime = new Date(currentDateTimeString);

        const appointmentDateTimeMinus30 = new Date(
          appointmentDateTime.getTime() - 30 * 60000
        );

        if (currentDateTime >= appointmentDateTimeMinus30) {
          this.isDisabled = false;
          this.showAlert = false;
        }
      } catch (error) {
        console.error("Error fetching current date and time:", error);
        return false;
      }
    },
    async opencheckinModal(item) {
      await this.isValidTime(item.appointmentdate, item.appointmenttime);
      //console.log("appt:", item);
      this.selectedAppointment = item;
      this.ischeckinModalOpen = true;
      this.$emit("openModal");
    },
    async confirmCheckIn(item) {
      this.loading = true;
      try {
        //console.log("iteminconfirm", item.patientid);
        const response = await axios.post(
          `/patients/${item.patientid}/appointments/${item.appointmentid}/tokens`
        );
        console.log("Confirmed Check-in"); //, this.selectedAppointment);
        this.fetchUpcomingAppointments();
        this.ischeckinModalOpen = false;
        this.$emit("closeModal");
        this.isDisabled = true;
        this.showAlert = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.ischeckinModalOpen = false;
      this.showAlert = true;
      this.isDisabled = true;
      this.$emit("closeModal");
    },

    async markasAbsent(patientid, appointmentid) {
      try {
        const status_data = "A";
        const response = await axios.put(
          `/patients/${patientid}/appointments/${appointmentid}/update?status_data=${status_data}`
        );
        console.log("Marked as Absent");
        this.fetchUpcomingAppointments();
      } catch (error) {
        console.error(error);
      }
    },
    async checkToken(appointmentid, patientid) {
      try {
        const response = await axios.get(
          `/patients/${patientid}/appointments/${appointmentid}/tokens`
        );
        if (response.data && response.data.length > 0) {
          return response.data[0].tokenid;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkPrescriptionExists(appointmentid, patientid) {
      try {
        const response = await axios.get(
          `/patients/${patientid}/appointments/${appointmentid}/prescriptions`
        );

        if (response.data && response.data.length > 0) {
          return {
            prescripId: response.data[0].prescripid,
            prescripNote: response.data[0].note
          };
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error checking prescription:", error);
        return null;
      }
    },
    async fetchMedicines() {
      try {
        const response = await axios.get(
          `/doctors/${this.doctorinfo.doctorId}/medicines`
        );
        this.medicine = response.data.map(med => {
          const [frequencyValue, durationValue] = (med.defaultdose || "").split(
            ","
          );
          const numericDuration = durationValue
            ? parseInt(durationValue.match(/\d+/), 10)
            : 5;
          return {
            medicinename: med.medicinename,
            brandname: med.brandname,
            dosage: `${med.medicinetype}, ${med.medstrength}`,
            frequency: frequencyValue || null,
            duration: numericDuration || 5
          };
        });
      } catch (error) {
        console.error("Error fetching medicines:", error);
      }
    },
    addToPrescription(element) {
      if (!this.prescriptionList.includes(element)) {
        /* const newItem = {
            ...element,
            prescripmedid: 0,
            medicinename: element.medicinename,
            brandname: element.brandname,
            dosage: element.medstrength,
            frequency: element.defaultdose, 
            duration: element.defaultdose, 
            meal: "before",
            note: element.medstrength,
            isEditing: false,
            showNote: false,
          };*/

        this.prescription.push(newItem);
        this.filteredMedicines = this.filteredMedicines.filter(
          med => med.medicinename !== medicine.medicinename
        );
      }
    },
    onAddToPrescription(event) {
      this.isSaveDisabled = false;
      const addedMedicine = event.clone;
      const isAlreadyInPrescription = this.prescription.some(
        med =>
          med.medicinename === addedMedicine.medicinename &&
          med.brandname === addedMedicine.brandname
      );
      event.preventDefault();
      if (isAlreadyInPrescription) {
        console.log("This medicine is already in the prescription.");
      }
      this.$nextTick(() => {
        event.item.remove();
      });
    },
    checkSaveButtonState() {
      this.isSaveDisabled = this.prescription.length === 0;
    },
    initializePrescriptionItem(element) {
      return {
        ...element,
        frequency: element.frequency || [],
        meal: element.meal || "",
        showNote: element.showNote || false,
        note: element.note || "",
        isEditing: element.isEditing || false
      };
    },
    handleDrop(event) {
      const droppedElement = event.item;
      const newElement = this.initializePrescriptionItem(droppedElement);
      this.prescription.push(newElement);
    },
    async openPrescription(patientid, appointmentid, firstname, lastname) {
      try {
        this.prescription = [];
        this.medicines = this.medicine;
        this.openModal(patientid, appointmentid, firstname, lastname, 0, "");
      } catch (error) {
        console.error("Failed to open prescription:", error);
      }
    },
    async addPrescription(patientid, appointmentid, prescripNote) {
      try {
        this.isCreateDisabled = true;
        const response = await axios.post(
          `/patients/${patientid} /appointments/${appointmentid}/prescriptions`,
          {
            appointmentid: appointmentid,
            prescrippath: "default-path",
            note: prescripNote,
            status: "A"
          }
        );

        this.prescripId = response.data;
        await this.createPrescription();
        this.fetchUpcomingAppointments();
      } catch (error) {
        console.error("Failed to create prescription:", error);
      }
    },
    async viewPrescription(
      prescripId,
      patientid,
      appointmentid,
      firstname,
      lastname
    ) {
      try {
        const prescripNote = await this.getNote(appointmentid, patientid);
        this.getMedicines(
          prescripId,
          patientid,
          appointmentid,
          firstname,
          lastname,
          prescripNote
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getMedicines(
      prescripId,
      patientid,
      appointmentid,
      firstname,
      lastname,
      prescripNote
    ) {
      try {
        const response = await axios.get(
          `/patients/1/prescriptions/${prescripId}/medications`
        );

        this.prescription = response.data;

        this.medicines = this.medicine.filter(medicine => {
          return !this.prescription.some(
            prescripItem =>
              prescripItem.medicinename === medicine.medicinename &&
              prescripItem.brandname === medicine.brandname
          );
        });

        this.openModal(
          patientid,
          appointmentid,
          firstname,
          lastname,
          prescripId,
          prescripNote
        );
      } catch (error) {
        console.error("Failed to fetch prescription medications:", error);
      }
    },
    openModal(
      patientid,
      appointmentid,
      firstname,
      lastname,
      prescripId,
      prescripNote
    ) {
      this.patientFirstName = firstname;
      this.patientLastName = lastname;
      this.prescripId = prescripId;
      this.patientid = patientid;
      this.appointmentid = appointmentid;
      this.prescripNote = prescripNote;
      this.isModalOpen = true;
    },
    /*async openModal(patientid, appointmentid, firstname, lastname, prescripid) {
       try {
         console.log("apptid:",appointmentid);
         console.log("appt prescripi:",prescripid);
         const response = await axios.post(`/patients/${patientid}   /appointments/${appointmentid}/prescriptions`, {
           appointmentid: appointmentid,
           prescrippath: 'default-path', // Replace with actual data or leave empty if not needed
           note: '', // Default note or replace with actual data
           status: 'A', 
           medications: [{
       prescripmedid: 0,
       prescripid: 0,
       medicinename: "string",
       dosage: "string",
       frequency: "string",
       duration: "string",
       note: "string"
     }]// Default status or replace with actual data
         });
 
         // Store the returned prescrip_id
         this.prescripId = response.data;
 
         // Open the modal after successful API call
         this.openAddPrescriptionModal();
 
       } catch (error) {
         console.error("Failed to create prescription:", error);
         // Handle error (e.g., show a notification to the user)
       }
     this.patientFirstName = firstname;
     this.patientLastName = lastname;
     this.isModalOpen = true;
   },*/
    closeModal() {
      this.isModalOpen = false;
    },
    async removeFromPrescription(element) {
      const index = this.prescription.indexOf(element);

      if (element.prescripmedid && element.prescripmedid !== 0) {
        try {
          await this.deleteMedicationFromServer(element.prescripmedid);
          if (index !== -1) {
            this.prescription.splice(index, 1);
          }
          this.medicines.push(element);
        } catch (error) {
          console.error("Failed to delete medication:", error);
        }
      } else {
        if (index !== -1) {
          this.prescription.splice(index, 1);
          this.medicines.push(element);
        }
      }
      this.checkSaveButtonState();
    },
    async deleteMedicationFromServer(prescripmedid) {
      try {
        const response = await axios.delete(
          `/patients/1/medications/${prescripmedid}`
        );
        this.checkSaveButtonState();
      } catch (error) {
        console.error("Failed to delete medications:", error);
      }
    },
    onDragEnd(evt) {
      const draggedItem = this.medicines[evt.oldIndex];
      if (evt.from === evt.to) {
      } else {
        this.addToPrescription(draggedItem);
        this.filteredMedicines = this.filteredMedicines.filter(
          med => med.medicinename !== movedItem.medicinename
        );
      }
    },
    async updatePrescription(
      patientid,
      appointmentid,
      prescrip_id,
      prescripnote
    ) {
      try {
        const response = await axios.put(
          `/patients/${patientid}/appointments/${appointmentid}/prescriptions`,
          {
            appointmentid: appointmentid,
            prescrippath: "default-path",
            note: prescripnote,
            status: "A"
          },
          {
            params: {
              prescrip_id: prescrip_id
            }
          }
        );

        this.prescripId = response.data;
        await this.createPrescription();
        this.fetchUpcomingAppointments();
      } catch (error) {
        console.error(error);
      }
    },
    async createPrescription() {
      try {
        const newMedications = this.prescription
          .filter(med => !med.prescripmedid)
          .map(med => {
            //const [frequencyValue, durationValue] = (med.frequency || "").split(","); // Split frequency and duration
            return {
              prescripmedid: 0,
              medicinename: med.medicinename,
              brandname: med.brandname,
              dosage: med.dosage,
              frequency: med.frequency,
              duration: med.duration,
              note: med.note || ""
            };
          });

        const editedMedications = this.prescription
          .filter(
            med => med.prescripmedid && (med.isEdited || med.isnoteEdited)
          ) // Check for edited medicines
          .map(med => {
            const [frequencyValue, durationValue] = (med.frequency || "").split(
              ","
            ); // Split frequency and duration
            return {
              prescripmedid: med.prescripmedid,
              medicinename: med.medicinename,
              brandname: med.brandname,
              dosage: med.dosage,
              frequency: med.frequency,
              duration: med.duration,
              note: med.note || " "
            };
          });
        const medications = [...newMedications, ...editedMedications];
        //console.log("med:", medications);
        const response = await axios.post(
          `/patients/1/prescriptions/${this.prescripId}/medications`,
          medications,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        console.log("Prescription created successfully");//, response.data);

        this.closeModal();
      } catch (error) {
        console.error("Failed to add medications:", error);
      }
    },
    resetPrescription() {
      this.prescription = [];
    },
    toggleEdit(item) {
      item.isEditing = !item.isEditing;
    },
    toggleNoteSection(element) {
      element.showNote = !element.showNote;
    },
    toggleFrequency(element, time) {
      if (!element.frequency) {
        element.frequency = [];
      }
      const index = element.frequency.indexOf(time);
      if (index !== -1) {
        element.frequency.splice(index, 1);
      } else {
        element.frequency.push(time);
      }
    },
    toggleMeal(element, mealType) {
      element.meal = mealType;
    },
    toggleNoteSection(element) {
      element.showNote = !element.showNote;
    },
    addNote() {
      this.isEditingNote = true;
    },
    saveNote() {
      if (this.newNote.trim()) {
        this.$set(this.element, "note", this.newNote);
        this.isEditingNote = false;
      }
    },
    editNote() {
      this.newNote = this.element.note;
      this.isEditingNote = true;
    },
    prescripEdited() {
      this.isprescripEdited = true;
    },
    saveEdit(med) {
      med.isEditing = false;
      med.isEdited = true; // Mark this medicine as edited
    },
    markAsEdited(med) {
      med.isnoteEdited = true;
    },
    toggleEdit(med) {
      med.isEditing = true;
    },
    formatDatecheckin(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
    formatTimecheckin(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { hour: "2-digit", minute: "2-digit", hour12: true };
      return date.toLocaleTimeString("en-US", options);
    },
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.toggle();
    },
    toggle() {
      this.isActive = !this.isActive;
      if (this.selectedDate) {
        this.filterAppointmentsByDate(this.selectedDate);
      }
      this.filterCurrentAppointments();
      this.filterUpcomingAppointments();
    },

    formatShortDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      });
    },
    filterAppointmentsByDate(date) {
      if (!this.isActive) {
        this.filteredAppointments = this.upcomingAppointments.filter(
          appointment => {
            return (
              new Date(appointment.appointmentdate).toLocaleDateString() ===
                date.toLocaleDateString() &&
              appointment.appointmentstatus !== "C"
            );
          }
        );
      } else {
        this.filteredAppointments = this.upcomingAppointments.filter(
          appointment => {
            return (
              new Date(appointment.appointmentdate).toLocaleDateString() ===
              date.toLocaleDateString()
            );
          }
        );
      }
      //console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterUpcomingAppointments() {
      if (!this.isActive) {
        this.upcomingAppointmentsfilter = this.upcomingAppointments.filter(
          appointment => {
            return appointment.appointmentstatus !== "C";
          }
        );
      } else {
        this.upcomingAppointmentsfilter = [...this.upcomingAppointments];
      }
      //console.log("Filtered appointments:", this.filteredAppointments);
    },

    filterCurrentAppointments() {
      if (!this.isActive) {
        //console.log("curr appts:", this.currentAppointments);
        this.currentAppointmentsfilter = this.currentAppointments.filter(
          appointment => {
            return appointment.appointmentstatus !== "C";
          }
        );
        //console.log("Active filtered appointments:",this.currentAppointmentsfilter);
      } else {
        this.currentAppointmentsfilter = [...this.currentAppointments];
      }
      //console.log("Active filtered appointments show:", this.currentAppointmentsfilter);
    },

    selectSpecificDay(date) {
      this.selectedDate = new Date(date);
      this.selectedTab = "specific";
      this.filterAppointmentsByDate(this.selectedDate);
      this.changeTab("specific");
    },

    selectUpcomingAppointments() {
      this.selectedTab = "upcoming";
      this.changeTab("upcoming");
    },
    selectTodayAppointments() {
      this.selectedTab = "today";
      this.changeTab("today");
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatedDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    },
    formatTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    getPatientImage(appointment) {
      return (
        appointment.patientimage || require("@/assets/img/patients/patient.jpg")
      );
    },

    async fetchDoctorStatCount() {
      const doctorId = this.doctorinfo.doctorId;

      const clinicId = this.doctorinfo.clinicId;
      try {
        const response = await axios.get(
          `/clinic/${doctorId}/doctorstats/${clinicId}`
        );
        this.statDetails = response.data;
        //console.log("Additional Details:", this.statDetails);
      } catch (error) {
        console.error("Error fetching doctors count:", error);
      }
    },
    async fetchTodaysAppointments() {
      try {
        const response = await axios.get(
          `/clinic/${this.doctorinfo.doctorId}/clinicappointments/${this.doctorinfo.clinicId}/today`
        );
        this.currentAppointments = response.data.current_day_appointments;
        this.filterCurrentAppointments();
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUpcomingAppointments() {
      try {
        const response = await axios.get(
          `/clinic/${this.doctorinfo.doctorId}/clinicappointments/${this.doctorinfo.clinicId}?filter_type=upcoming`
        );
        this.upcomingAppointments = response.data.upcoming_appointments;
        /*const checkUpcomingPromises = this.upcomingAppointments.map(
          async appointment => {
            const prescriptionExists = await this.checkPrescriptionExists(
              appointment.appointmentid,
              appointment.patientid
            );
            if (prescriptionExists) {
              appointment.prescriptionStatus = "exists";
              appointment.prescripId = prescriptionExists.prescripId;
              appointment.prescripNote = prescriptionExists.prescripNote; // Store the prescription ID if it exists
            } else {
              appointment.prescriptionStatus = "new";
            }
          }
        );
        const checkCurrentPromises = this.currentAppointments.map(
          async appointment => {
            const prescriptionExists = await this.checkPrescriptionExists(
              appointment.appointmentid,
              appointment.patientid
            );
            const tokenExists = await this.checkToken(
              appointment.appointmentid,
              appointment.patientid
            );

            if (prescriptionExists) {
              appointment.prescriptionStatus = "exists";
              appointment.prescripId = prescriptionExists.prescripId;
              appointment.prescripNote = prescriptionExists.prescripNote;
            } else {
              appointment.prescriptionStatus = "new";
            }

            if (tokenExists) {
              appointment.tokenNumber = tokenExists;
            } else {
              appointment.tokenNumber = null;
            }
          }
        );
        await Promise.all([...checkUpcomingPromises, ...checkCurrentPromises]);*/

        if (this.selectedDate && this.selectedTab === "specific") {
          this.filterAppointmentsByDate(this.selectedDate);
        } else if (this.selectedTab === "upcoming") {
          this.filterUpcomingAppointments();
        } else if (this.selectedTab === "today") {
          this.fetchTodaysAppointments();
        }
        this.fetchTodaysAppointments();
        this.filterUpcomingAppointments();
      } catch (error) {
        console.error("Error fetching upcoming appointments", error);
      }
    },
    viewAppointmentDetails(appointment) {
      this.selectedAppointment = appointment;
    },
    cancelAppointment(appointment) {
      const {
        appointmentid,
        appointmentby,
        appointmentdate,
        appointmenttime
      } = appointment;

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment on ${this.formatDate(
          appointmentdate
        )} at ${this.formatTime(appointmenttime)}?`,
        input: "text",
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, cancel it!"
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;

          const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointmentid}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully");//, response.data);

              this.fetchUpcomingAppointments();
              this.fetchDoctorStatCount(this.DoctorId);
            })
            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    fetchAppointments() {
      axios
        .get(
          `/doctors/${this.doctorinfo.doctorId}/appointments?filter_type=upcoming`
        )
        .then(response => {
          this.currentAppointments = response.data.current_day_appointments;
        })
        .catch(error => {
          console.error("Error fetching appointments:", error);
        });
    },
    exportAppointments(format) {
      if (format === "pdf") {
        if (this.selectedTab === "upcoming") {
          if (!this.isActive) {
            const upcomingAppointmentspdf = this.upcomingAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToPDF(this.upcomingAppointmentspdf);
          } else {
            this.exportToPDF(this.upcomingAppointments);
          }
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            const currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToPDF(this.currentAppointmentspdf);
          } else {
            this.exportToPDF(this.currentAppointments);
          }
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                    this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus === "E"
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToPDF(appointmentsForSelectedDay);
          }
        }
      } else if (format === "excel") {
        if (this.selectedTab === "upcoming") {
          if (!this.isActive) {
            this.upcomingAppointmentspdf = this.upcomingAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToExcel(this.upcomingAppointmentspdf);
          }
          this.exportToExcel(this.upcomingAppointments);
        } else if (this.selectedTab === "today") {
          if (!this.isActive) {
            this.currentAppointmentspdf = this.currentAppointments.filter(
              appointment => {
                return appointment.appointmentstatus === "E";
              }
            );
            this.exportToExcel(this.upcomingAppointmentspdf);
          }
          this.exportToExcel(this.currentAppointments);
        } else if (this.selectedTab === "specific") {
          if (!this.isActive) {
            const appointmentsForSelectedDayfilter = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                    this.selectedDate.toLocaleDateString() &&
                  appointment.appointmentstatus === "E"
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDayfilter);
          } else {
            const appointmentsForSelectedDay = this.filteredAppointments.filter(
              appointment => {
                return (
                  new Date(appointment.appointmentdate).toLocaleDateString() ===
                  this.selectedDate.toLocaleDateString()
                );
              }
            );
            this.exportToExcel(appointmentsForSelectedDay);
          }
        }
      }
    },
    exportToPDF(Appointments) {
      const doc = new jsPDF();
      const appointments = Appointments.map(appointment => [
        appointment.firstname + " " + appointment.lastname,
        appointment.appointmentdate,
        this.formatTime(appointment.appointmenttime),
        appointment.clinicname
      ]);

      let heading;
      if (this.selectedTab === "today") {
        heading = "Appointments for Today";
      } else if (this.selectedTab === "upcoming") {
        heading = "Upcomming Appointments";
      } else {
        heading = "Appointments for " + this.formatDate(this.selectedDate);
      }

      const gap = 10;

      const yPos = gap;

      const textWidth =
        (doc.getStringUnitWidth(heading) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const xPos = (doc.internal.pageSize.width - textWidth) / 2;

      doc.text(heading, xPos, yPos);
      doc.autoTable({
        head: [["Patient Name", "Appt Date", "Appt Time", "Clinic Name"]],
        body: appointments,
        startY: yPos + gap
      });
      doc.save("appointments.pdf");
    },
    exportToExcel(Appointments) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Appointments");
      worksheet.columns = [
        { header: "Patient Name", key: "patientName", width: 30 },
        { header: "Appt Date", key: "appointmentDate", width: 20 },
        { header: "Appt Time", key: "appointmentTime", width: 20 },
        { header: "Clinic Name", key: "appointmentClinic", width: 40 }
      ];
      Appointments.forEach(appointment => {
        worksheet.addRow({
          patientName: appointment.firstname + " " + appointment.lastname,
          appointmentDate: appointment.appointmentdate,
          appointmentTime: this.formatTime(appointment.appointmenttime),
          appointmentClinic: appointment.clinicname
        });
      });
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "appointments.xlsx";
        link.click();
      });
    },
    async downloadPrescriptionPDF(prescripid, patientid, slno, prescripNote) {
      try {
        prescripNote = prescripNote
          ? prescripNote.replace(/\n/g, "<br>")
          : "No description provided.";
        const apptdata = await this.fetchApptDetails(patientid, slno);
        const prescriptionData = await this.fetchPrescipDetails(prescripid);
        const formattedDate = await this.formattedDateandTime(
          apptdata.date_and_time
        );
        const logoUrl = require("@/assets/img/logo.png");
        const htmlContent = `
    <html>
    <head>
      <title>Prescription - YourDoc</title>
      <style>
        .modal-body { padding: 20px; color: black; font-family: Arial, sans-serif; }
        .doctor-clinic-info, .prescription-header { margin-bottom: 20px; }
        .rx-symbol { font-size: 24px; margin: 10px 0; display: block; }
        .table-striped {
          width: 100%;
          margin-top: 20px;
        }
        .doctor-clinic-info{
        color: black;
        }
        .table-striped th,
        .table-striped td {
          text-align: left;
          padding: 8px;
        }
        .table-striped th {
          white-space: nowrap;
        }
        .prescription-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          color: black;
        }
        .patient-description-section {
          margin-top: none;
          margin-bottom: 5px;
          padding: 10px;
          color: black;
        }
        .patient-description-textarea {
          width: 100%;
          height: 50px;
          font-size: 14px;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 2px;
          resize: none;
          margin-top: 1px;
          margin-bottom: 2px;
          color: black;
        }
        .note-textarea {
          resize: none;
        }
      </style>
    </head>
    <body>
      <div class="modal-body">
      <img src="${logoUrl}" alt="Logo" class="logo" />
       <h1 style="text-align: center; font-size: 18px;">Prescription - YourDoc</h1>
        <div class="doctor-clinic-info">
          <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
          <p>${apptdata.speciality}</p>
          <p>${apptdata.clinic_name}</p>
        </div>
        <div class="prescription-header">
          <span><strong>Date: </strong>${formattedDate}</span>
          <span class="patient-name"><strong>Patient: </strong>${
            apptdata.patient_name
          }</span>
        </div>
        <span class="rx-symbol">℞</span>
         <div class="patient-description-section">
          <h3>Patient Description:</h3>
          <p>${prescripNote}</p>
        </div>
        <h3>Medicines:</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Medicine Name</th>
              <th>Brand Name</th>
              <th>Dosage</th>
              <th>Frequency</th>
              <th>Duration</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            ${prescriptionData
              .map(
                medication => `
              <tr>
                <td>${medication.medicinename}</td>
                <td>${medication.brandname}</td>
                <td>${medication.dosage}</td>
                <td>${medication.frequency}</td>
                <td>${medication.duration}</td>
                <td>${medication.note || "-"}</td>
              </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
      </div>
    </body>
    </html>
  `;

        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        const opt = {
          margin: 2.75,
          filename: `prescription_${apptdata.patient_name}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
        };

        html2pdf()
          .set(opt)
          .from(element)
          .save();
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
    async getNote(appointmentid, patientid) {
      try {
        const response = await axios.get(
          `/patients/${patientid}/appointments/${appointmentid}/prescriptions`
        );

        if (response.data && response.data.length > 0) {
          return response.data[0].note;
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error checking prescription:", error);
        return null;
      }
    },
    async print_Download(prescripId, appointmentId, patientid) {
      try {
        const prescripNote = await this.getNote(appointmentId, patientid);
        if (this.isMobile) {
          this.downloadPrescriptionPDF(
            prescripId,
            appointmentId,
            patientid,
            prescripNote
          );
        } else {
          this.printPrescription(
            prescripId,
            appointmentId,
            patientid,
            prescripNote
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async printPrescription(
      prescripId,
      appointmentId,
      patientid,
      prescripNote
    ) {
      try {
        prescripNote = prescripNote
          ? prescripNote.replace(/\n/g, "<br>")
          : "No description provided.";
        const apptdata = await this.fetchApptDetails(patientid, appointmentId);
        const prescriptionData = await this.fetchPrescipDetails(prescripId);
        const formattedDate = await this.formattedDateandTime(
          apptdata.date_and_time
        );
        const logoUrl = require("@/assets/img/logo.png");
        const htmlContent = `
    <html>
    <head>
      <title>Prescription - YourDoc</title>
      <style>
        .modal-body { padding: 20px; color: black; font-family: Arial, sans-serif; }
        .doctor-clinic-info, .prescription-header { margin-bottom: 20px; }
        .rx-symbol { font-size: 24px; margin: 10px 0; display: block; }
        .table-striped {
          width: 100%;
          margin-top: 20px;
        }
        .doctor-clinic-info{
        color: black;
        }
        .table-striped th,
        .table-striped td {
          text-align: left;
          padding: 8px;
        }
        .table-striped th {
          white-space: nowrap;
        }
        .prescription-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          color: black;
        }
        .patient-description-section {
          margin-top: none;
          margin-bottom: 5px;
          padding: 10px;
          color: black;
        }
        .patient-description-textarea {
          width: 100%;
          height: 50px;
          font-size: 14px;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 2px;
          resize: none;
          margin-top: 1px;
          margin-bottom: 2px;
          color: black;
        }
        .note-textarea {
          resize: none;
        }
      </style>
    </head>
    <body>
      <div class="modal-body">
      <img src="${logoUrl}" alt="Logo" class="logo" />
       <h1 style="text-align: center; font-size: 18px;">Prescription - YourDoc</h1>
        <div class="doctor-clinic-info">
          <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
          <p>${apptdata.speciality}</p>
          <p>${apptdata.clinic_name}</p>
        </div>
        <div class="prescription-header">
          <span><strong>Date: </strong>${formattedDate}</span>
          <span class="patient-name"><strong>Patient: </strong>${
            apptdata.patient_name
          }</span>
        </div>
        <span class="rx-symbol">℞</span>
         <div class="patient-description-section">
          <h3>Patient Description:</h3>
          <p>${prescripNote}</p>
        </div>
        <h3>Medicines:</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Medicine Name</th>
              <th>Brand Name</th>
              <th>Dosage</th>
              <th>Frequency</th>
              <th>Duration</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            ${prescriptionData
              .map(
                medication => `
              <tr>
                <td>${medication.medicinename}</td>
                <td>${medication.brandname}</td>
                <td>${medication.dosage}</td>
                <td>${medication.frequency}</td>
                <td>${medication.duration}</td>
                <td>${medication.note || "-"}</td>
              </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
      </div>
    </body>
    </html>
  `;

        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        const opt = {
          margin: 2.75,
          filename: `prescription_${apptdata.patient_name}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
        };

        html2pdf()
          .set(opt)
          .from(element)
          .toPdf()
          .get("pdf")
          .then(function(pdf) {
            pdf.autoPrint();
            const pdfBlob = pdf.output("bloburl");

            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = pdfBlob;

            document.body.appendChild(iframe);
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          });
      } catch (error) {
        console.error("Error printing prescription:", error);
      }
    },
    async fetchPrescipDetails(prescripId) {
      try {
        const response = await axios.get(
          `/patients/1/prescriptions/${prescripId}/medications`
        );
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchApptDetails(patientid, slno) {
      try {
        const response = await axios.get(`/patients/${patientid}/appointments`);
        const appointments = response.data.future;

        const matchingAppointment = appointments.find(
          appt => appt.appointment_id === slno
        );

        return matchingAppointment ? matchingAppointment : null;
      } catch (error) {
        console.error("Error fetching appointment details:", error);
        return null;
      }
    },
    async fetchPrescipDetails(prescripId) {
      try {
        const response = await axios.get(
          `/patients/1/prescriptions/${prescripId}/medications`
        );
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async formattedDateandTime(dateString) {
      const date = new Date(dateString);

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      let hours = date.getHours();
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;

      return `${month} ${day}, ${year}, <b>Time: </b>${hours}:${minutes} ${ampm}`;
    },
    scrollTableRight() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLeft() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    },

    scrollTableRightx() {
      const tableContainerx = this.$refs.tableContainerx;
      tableContainerx.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLeftx() {
      const tableContainerx = this.$refs.tableContainerx;
      tableContainerx.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    },

    scrollTableRighty() {
      const tableContainery = this.$refs.tableContainery;
      tableContainery.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLefty() {
      const tableContainery = this.$refs.tableContainery;
      tableContainery.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    }
  },
  beforeMount() {
    this.doctorinfo = this.$store.getters.getclinicDetails;
    this.fetchDoctorStatCount();
    this.fetchUpcomingAppointments();
    this.fetchMedicines();
    this.formatDate();
    //console.log("this is doctor details", this.doctorclinicid);
    const percent = ref(75);
    setInterval(() => {
      if (percent.value === 25) {
        percent.value = 75;
      } else {
        percent.value = 25;
      }
    }, 1000);
  },
  name: "doctor-dashboard"
};
</script>
<style scoped>
.mb-3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  color: #080808;
}
.form-check.form-switch.form-check-label {
  display: flex;
  align-items: center;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 90%;
  max-width: 900px;
  padding: 15px;
  max-height: 95vh;
  min-height: 95vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-body {
  display: flex;
  gap: 20px;
  flex-grow: 1;
}

.medicine-list,
.prescription-list {
  flex: 1;
  max-height: 50vh;
  min-height: 50vh;
  /* Fixed height */
  overflow-y: auto;
  /* Scrollable when items exceed height */
  padding: 5px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.medicine-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.medicine-item:hover {
  background-color: #e9e9e9;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  margin-right: 1rem;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  margin-right: 0.15rem;
}

.nav-link:hover {
  background-color: #0056b3;
}

.modal-content .btn {
  cursor: auto;
}

.custom-badge {
  background-color: #708090;
  color: white;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.6;
}

.edit-icon {
  cursor: pointer;
  color: #007bff;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.frequency-buttons button {
  border: 1px solid #007bff;
  background: white;
  color: #007bff;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.frequency-buttons button.active {
  background-color: green;
  color: white;
}

.meal-toggle span {
  cursor: pointer;
  color: #007bff;
  margin-right: 10px;
}

.meal-toggle span.active {
  font-weight: bold;
  color: green;
}

textarea {
  width: 100%;
  padding: 5px;
  margin-top: 10px;
}

.note-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #333;
  font-size: 14px;
}

.patient-description-section {
  margin-top: none;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #f4f4f4;
}

.patient-description-textarea {
  width: 100%;
  height: 50px;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  resize: none;
  margin-top: 1px;
  margin-bottom: 2px;
  color: black;
}

.note-textarea {
  resize: none;
}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  font-size: 12px;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-bar input {
  flex: 1;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-bar i {
  margin-left: 5px;
  color: #888;
}

.medicine-list-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.prescription-list-container {
  display: flex;
  color: #000;
  flex-direction: column;
  width: 50%;
}

.duration-input {
  width: 35px;
}

.download-text {
  font-size: 12px;
  display: inline;
  margin: 0;
  margin-right: 5px;
  font-weight: bold;
}

.token {
  background-color: #8ce4f3;
  color: #0c91ce;
  border: none;
  border-radius: 20px;
  padding: 5px;
  font-size: 13px;
}

.checked-in-box {
  background-color: #e6f5e9;
  border: 1px solid #28a745;
  border-radius: 8px;
  margin-top: 5px;
  padding: 1px 1px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.token-number {
  margin-top: 1px;
  font-size: 13px;
  color: #000000;
  padding: 1px 1px;
}

.token-number b {
  color: #000;
}

/* pdf and excel icon */
.export-icon {
  width: 30px;
  height: 30px;
}

/* swirl alert close button css*/
.swal-popup-custom {
  position: relative;
}

.swal-close-button-custom {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #d33;
}

.badge-lg {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.check-in-container {
  margin-left: 57px;
  margin-top: 5px;
}

.checkinmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.checkinmodal-content {
  background-color: #fff;
  width: 42%;
  max-width: 1500px;
  padding: 15px;
  max-height: 57vh;
  min-height: 57vh;
  /* width: 100%;
  height: 100%; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(71, 70, 70, 0.2);
  overflow: hidden;
}

@media (max-width: 768px) {
  .checkinmodal-content {
    width: 90%;
    max-width: 600px;
    min-height: auto;
    max-height: 80vh;
    overflow-y: auto;
  }
}

.checkinmodal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.checkinmodal-footer {
  margin-top: 1px;
  display: flex;
  justify-content: flex-end;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

.main-btn {
  background-color: #0e82fd;
  border: 1px solid;
  border-radius: 4px;
  color: #fff;
  padding: 2px 7px;
  margin-right: 5px;
  margin-left: 1px;

  font-size: 15px;
}
.main-btn:hover {
  background-color: #ffffff;
  border: 1px solid #20c0f3;
  color: #0e82fd;
}

button:disabled {
  background-color: #cccccc;
  color: rgb(75, 75, 75);
  cursor: not-allowed;
  opacity: 0.6;
}

.confirm-btn {
  background-color: rgb(0, 199, 90);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

.back-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

.checked-in-box {
  background-color: #e6f5e9;
  border: 1px solid #28a745;
  border-radius: 8px;
  padding: 3px 2px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checked-in-label {
  font-size: 14px;
  background-color: rgb(0, 199, 90);
  display: flex;
  border-radius: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.checked-in-label i {
  margin-right: 5px;
}

.token-number {
  margin-top: 1px;
  font-size: 13px;
  color: #333;
  padding: 1px 1px;
}

.token-number b {
  color: #000;
}

.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.nav-link:hover {
  background-color: #0056b3;
}

.modal-content .btn {
  cursor: auto;
}

.custom-badge {
  background-color: #708090;
  color: white;
}

.export-icon {
  width: 40px;
  height: 40px;
}

.left-align {
  text-align: left;
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.align-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-align {
  text-align: right;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}
</style>