import { createApp } from 'vue'
import { router } from './router';
import App from './App.vue';
import store from './store';
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import VueSelect from 'vue3-select2-component'
import VueFeather from 'vue-feather';
import DatePicker from 'vue3-datepicker';
import VueApexCharts from "vue3-apexcharts";
import Vue3Autocounter from 'vue3-autocounter';
// import VueTelInput from 'vue3-tel-input';
import VueTelInput from 'vue-tel-input';

import i18n from './i18n';
// plugins
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'vue3-tel-input/dist/vue3-tel-input.css';
import 'vue-tel-input/vue-tel-input.css';
import "vue-search-select/dist/VueSearchSelect.css";
import 'vue-multiselect/dist/vue-multiselect.css';

/****** AWS Amplify ******/
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

/***** Vuetify *****/
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

/***********************************************************************/
/* Frontend */
/***********************************************************************/

//Breadcrumb
import Breadcrumb from '@/components/frontend/breadcrumb/aboutus.vue'
import DoctorsSidebar from '@/views/frontend/layouts/doctorsidebar.vue'
import BlogSidebar from '@/views/frontend/layouts/blogsidebar.vue'
import PharmacyFilter from '@/views/frontend/layouts/pharmacyfilter.vue'
import Breadcrumb1 from '@/components/frontend/breadcrumb/breadcrumb1.vue'
import DoctorBreadcrumb from '@/components/frontend/breadcrumb/breadcrumb-doctor.vue'
import ClinicBreadcrumb from '@/components/frontend/breadcrumb/breadcrumb-clinic.vue'
import Mainmenu from '@/components/frontend/mainmenu.vue'
import Mainnav from '@/components/frontend/mainnav.vue'
import Profilewidget from '@/components/frontend/profilewidget.vue'


//pages
import Header from '@/views/frontend/layouts/header.vue'
import HeaderTwo from  '@/views/frontend/layouts/headertwo.vue'
import Headerinfo from '@/views/frontend/layouts/header-info.vue'
import Cursor from '@/views/frontend/layouts/cursor.vue'
import Scrolltotop from '@/views/frontend/layouts/scrolltotop.vue'
import ProfileSidebar from '@/views/frontend/layouts/profilesidebar.vue'

import Footer from '@/views/frontend/layouts/footer.vue'
import IndexBanner from '@/views/frontend/pages/home/indexbanner.vue'

import IndexWork from '@/views/frontend/pages/home/indexwork.vue'
import IndexApp from '@/views/frontend/pages/home/indexapp.vue'
import IndexPartners from '@/views/frontend/pages/home/indexpartners.vue'
import IndexFooter from '@/views/frontend/pages/home/footer.vue'

//Doctor Home
import IndexDoctor from '@/views/frontend/pages/doctorhome/indexdoctor.vue'
import IndexTestimonial from '@/views/frontend/pages/doctorhome/indextestimonial.vue'
import DoctorFooter from '@/views/frontend/pages/doctors/footer.vue'
import BlogdetailComment from '@/views/frontend/pages/blog/blog-details-comment.vue'
import PharmacySlider from '@/views/frontend/pages/pharmacymenu/pharmacy-index/pharmacyslider.vue'
import OverviewContent from '@/views/frontend/pages/pharmacymenu/details/overview-content.vue'
import LocationContent from '@/views/frontend/pages/pharmacymenu/details/location-content.vue'
import ReviewContent from '@/views/frontend/pages/pharmacymenu/details/review-content.vue'
import BusinessContent from '@/views/frontend/pages/pharmacymenu/details/business-content.vue'
import productdetails from '@/views/frontend/pages/pharmacymenu/product-description/product-details.vue'
import productdescriptionsidebar from '@/views/frontend/pages/pharmacymenu/product-description/product-description-sidebar.vue'
import Medlifemedical from '@/views/frontend/pages/pharmacymenu/product/medlifemedical.vue'

//Patient Pages
import PatientFooter from '@/views/frontend/pages/patients/patientfooter.vue'
import Searchlist from '@/views/frontend/pages/patients/searchdoctor/searchlist.vue'
import search2filter from '@/views/frontend/pages/patients/searchdoctor/search2/search2filter.vue'
import search2map from '@/views/frontend/pages/patients/searchdoctor/search2/search2map.vue'
import locations from '@/views/frontend/pages/patients/doctorprofile/locations.vue'
import overview from '@/views/frontend/pages/patients/doctorprofile/overview.vue'
import reviews from '@/views/frontend/pages/patients/doctorprofile/reviews.vue'
import businesshours from '@/views/frontend/pages/patients/doctorprofile/businesshours.vue'
import prescription from '@/views/frontend/pages/patients/dashboard/prescription.vue'
import patientappointment from '@/views/frontend/pages/patients/dashboard/patientappointment.vue'
import billing from '@/views/frontend/pages/patients/dashboard/billing.vue'
import medicalrecords from '@/views/frontend/pages/patients/dashboard/medical-records.vue'
import view_medical_records from '@/views/frontend/pages/patients/dashboard/view-medical-records.vue'
import patient_medical_records from '@/views/frontend/pages/patients/dashboard/patient-medical-record.vue'
import Chatleft from '@/views/frontend/pages/patients/chat/chatleft.vue'
import Chatright from '@/views/frontend/pages/patients/chat/chatright.vue'
import Doctorwidget from '@/views/frontend/pages/patients/doctors/maplist/doctorwidget.vue'
import medicalprescription from '@/views/frontend/pages/patients/medical-records/medicalprescription.vue'
import medical_records_table from '@/views/frontend/pages/patients/medical-records/medical-records-table.vue'
import medical_records_model from '@/views/frontend/pages/patients/medical-records/medical-records-model.vue'
import patient_report_access from '@/views/frontend/pages/patients/medical-records/patient-report-access.vue'
import actions from '@/views/frontend/pages/patients/dependent/actions.vue'
import patientsaccounts1 from '@/views/frontend/pages/patients/accounts/accounts1.vue'
import patientsaccounts2 from '@/views/frontend/pages/patients/accounts/accounts2.vue'
import patientsaccounts3 from '@/views/frontend/pages/patients/accounts/accounts3.vue'
import graphfour from '@/views/frontend/pages/patients/dashboard/graphfour.vue'
import GraphOne from '@/views/frontend/pages/patients/dashboard/graphone.vue'
import GraphTwo from '@/views/frontend/pages/patients/dashboard/graphtwo.vue'
import GraphThree from '@/views/frontend/pages/patients/dashboard/graphthree.vue'


import DoctorSidebar from '@/views/frontend/layouts/doctorsidebar.vue'
import ScheduleSunday from '@/views/frontend/pages/doctors/scheduletiming/schedule-sunday.vue'
import ScheduleMonday from '@/views/frontend/pages/doctors/scheduletiming/schedule-monday.vue'
import ScheduleTuesday from '@/views/frontend/pages/doctors/scheduletiming/schedule-tuesday.vue'
import ScheduleWednesday from '@/views/frontend/pages/doctors/scheduletiming/schedule-wednesday.vue'
import ScheduleThursday from '@/views/frontend/pages/doctors/scheduletiming/schedule-thursday.vue'
import ScheduleFriday from '@/views/frontend/pages/doctors/scheduletiming/schedule-friday.vue'
import ScheduleSaturday from '@/views/frontend/pages/doctors/scheduletiming/schedule-saturday.vue'
import Dappointment from '@/views/frontend/pages/doctors/patient-profile/dappointment.vue'
import Dmedicalrecords from '@/views/frontend/pages/doctors/patient-profile/dmedicalrecords.vue'
import Dprescription from '@/views/frontend/pages/doctors/patient-profile/dprescription.vue'
import Dbilling from '@/views/frontend/pages/doctors/dbilling.vue'
import DoctorChatright from '@/views/frontend/pages/doctors/chat/chat-right.vue'
import DoctorChatleft from '@/views/frontend/pages/doctors/chat/chat-left.vue'
import DoctorChatModel from '@/views/frontend/pages/doctors/chat/chat-model.vue'
import DoctorProfileSettings1 from '@/views/frontend/pages/doctors/profile-settings/doctorprofile-settings1.vue'
import DoctorProfileSettings2 from '@/views/frontend/pages/doctors/profile-settings/doctorprofile-settings2.vue'
import DoctorModel from '@/views/frontend/pages/doctors/doctorblog/doctormodel.vue'
import EditPrescription from '@/views/frontend/pages/doctors/edit-prescription1.vue'
import Accounts1 from '@/views/frontend/pages/doctors/accounts1.vue'
import Accounts2 from '@/views/frontend/pages/doctors/accounts2.vue'
import Accounts3 from '@/views/frontend/pages/doctors/accounts3.vue'
import Experience from '@/views/frontend/pages/doctors/profile-settings/experience.vue'
import Awards from '@/views/frontend/pages/doctors/profile-settings/awards.vue'
import Memberships from '@/views/frontend/pages/doctors/profile-settings/memberships.vue'
import Registrations from '@/views/frontend/pages/doctors/profile-settings/registrations.vue'
import Addslot from '@/views/frontend/pages/doctors/scheduletiming/addslot.vue'
import Editslot from '@/views/frontend/pages/doctors/scheduletiming/editslot.vue'
import OnBoardingSidebar from '@/views/frontend/pages/onboard/onboarding-sidebar.vue'
import OnBoardingSlider from '@/views/frontend/pages/onboard/onboarding-slider.vue'


//clinic pages
import ClinicSidebar from '@/views/frontend/layouts/clinicsidebar.vue'
import DoctorSidebar2 from '@/views/frontend/layouts/doctorsidebar2.vue'
import ClinicPatientSearch from '@/views/frontend/pages/clinics/clinic-patient-search.vue'
import ClinicPatientRegistration from '@/views/frontend/pages/clinics/clinic-patient-registration.vue'
import ClinicHeader from '@/views/frontend/pages/clinics/header.vue'
import ClinicFooter from '@/views/frontend/pages/clinics/footer.vue'



/*******API********/
import axios from "axios";
import './registerServiceWorker'
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.common['api-key'] = process.env.VUE_APP_API_KEY;
const userTokens = store.getters.getUserTokens;
if (userTokens) {
  axios.defaults.headers.common['id-token'] = userTokens.idToken;
  axios.defaults.headers.common['Authorization'] = "Bearer "+ userTokens.accessToken;
}

const app = createApp(App);

// Breadcrumb
app.component('breadcrumb',Breadcrumb)
app.component('breadcrumb1',Breadcrumb1)
app.component('breadcrumb-doctor',DoctorBreadcrumb)
app.component('breadcrumb-clinic',ClinicBreadcrumb)
app.component('doctors-sidebar',DoctorsSidebar)
app.component('blogsidebar',BlogSidebar)
app.component('mainmenu',Mainmenu)
app.component('mainnav',Mainnav)
app.component('profilewidget',Profilewidget)


//pages
app.component('layoutheader', Header)
app.component('layoutheadertwo',HeaderTwo)
app.component('headerinfo',Headerinfo)
app.component('cursor',Cursor)
app.component('scrolltotop',Scrolltotop)
app.component('footerindex',Footer)
app.component('indexbanner',IndexBanner)
app.component('indexdoctor',IndexDoctor)
app.component('indexwork', IndexWork)
app.component('indexapp', IndexApp)
app.component('indextestimonial',IndexTestimonial)
app.component('indexpartners',IndexPartners)
app.component('indexfooter',IndexFooter)
app.component('doctorfooter',DoctorFooter)

app.component('blogdetailscomment',BlogdetailComment)
app.component('pharmacyslider',PharmacySlider)
app.component('overview-content',OverviewContent)
app.component('location-content', LocationContent)
app.component('review-content', ReviewContent)
app.component('business-content',BusinessContent)
app.component('pharmacy-filter',PharmacyFilter)
app.component('medlifemedical',Medlifemedical)
app.component('productdescriptionsidebar',productdescriptionsidebar)
app.component('productdetails',productdetails)

//Patient pages
app.component('profilesidebar',ProfileSidebar)
app.component('patientfooter',PatientFooter)
app.component('searchlist',Searchlist)
app.component('search2filter',search2filter)
app.component('search2map',search2map)
app.component('locations',locations)
app.component('overview',overview)
app.component('reviews',reviews)
app.component('businesshours',businesshours)
app.component('patientappointment',patientappointment)
app.component('medicalrecords',medicalrecords)
app.component('billing',billing)
app.component('prescription',prescription)
app.component('chatright',Chatright)
app.component('chatleft',Chatleft)
app.component('doctorwidget',Doctorwidget)
app.component('medicalprescription',medicalprescription)
app.component('medical-records-table',medical_records_table)
app.component('medical-records-model',medical_records_model)
app.component('actions',actions)
app.component('patientsaccounts1',patientsaccounts1)
app.component('patientsaccounts2',patientsaccounts2)
app.component('patientsaccounts3',patientsaccounts3)
app.component('graphfour',graphfour)
app.component('graphone', GraphOne)
app.component('graphtwo', GraphTwo)
app.component('graphthree', GraphThree)
app.component('doctorsidebar',DoctorSidebar)
app.component('doctormodel',DoctorModel)
app.component('edit-prescription1',EditPrescription)
app.component('schedule-sunday',ScheduleSunday)
app.component('schedule-monday',ScheduleMonday)
app.component('schedule-tuesday',ScheduleTuesday)
app.component('schedule-wednesday',ScheduleWednesday)
app.component('schedule-thursday',ScheduleThursday)
app.component('schedule-friday',ScheduleFriday)
app.component('schedule-saturday',ScheduleSaturday)
app.component('accounts1',Accounts1)
app.component('accounts2',Accounts2)
app.component('accounts3',Accounts3)
app.component('experience',Experience)
app.component('awards',Awards)
app.component('memberships',Memberships)
app.component('registrations',Registrations)
app.component('addslot',Addslot)
app.component('editslot', Editslot)
app.component('dappointment',Dappointment)
app.component('dmedicalrecords',Dmedicalrecords)
app.component('dprescription',Dprescription)
app.component('dbilling',Dbilling)
app.component('doctorprofile-settings1',DoctorProfileSettings1)
app.component('doctorprofile-settings2',DoctorProfileSettings2)
app.component('view-medical-records',view_medical_records)
app.component('patient-medical-record',patient_medical_records)


app.component('doctor-chat-left',DoctorChatleft)
app.component('doctor-chat-right',DoctorChatright)
app.component('doctor-chat-model', DoctorChatModel)

app.component('onboarding-sidebar', OnBoardingSidebar)
app.component('onboarding-slider', OnBoardingSlider)
app.component('patient-report-access',patient_report_access)

//clinic
app.component('clinicsidebar',ClinicSidebar)
app.component('doctorsidebar2',DoctorSidebar2)
app.component('clinic-patient-search',ClinicPatientSearch)
app.component('clinic-patient-registration',ClinicPatientRegistration)
app.component('clinicheader', ClinicHeader)
app.component('clinicfooter', ClinicFooter)


app.component('vue3-autocounter', Vue3Autocounter)
app.component('vue-select', VueSelect)
app.component(VueFeather.name, VueFeather)
app.component('datepicker', DatePicker)
app.use(VueApexCharts)
.use(VueTelInput)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
app.use(store)
.use(Amplify)
.use(vuetify)
.use(i18n);
app.use(router).mount('#app');

