export const DOCTOR_POOL = 
    {
      aws_resource_name: process.env.VUE_APP_DOC_RESOURCE_NAME,
      aws_project_region: process.env.VUE_APP_DOC_PROJECT_REGION,
      aws_cognito_region: process.env.VUE_APP_DOC_COGNITO_REGION,
      aws_user_pools_id: process.env.VUE_APP_DOC_COGNITO_USER_POOLS_ID,
      aws_user_pools_web_client_id: process.env.VUE_APP_DOC_COGNITO_USER_POOLS_WEB_CLIENT_ID,
    };
export const PATIENT_POOL =
    {
      aws_resource_name: process.env.VUE_APP_PAT_RESOURCE_NAME,
      aws_project_region: process.env.VUE_APP_PAT_PROJECT_REGION,
      aws_cognito_region: process.env.VUE_APP_PAT_COGNITO_REGION,
      aws_user_pools_id: process.env.VUE_APP_PAT_COGNITO_USER_POOLS_ID,
      aws_user_pools_web_client_id: process.env.VUE_APP_PAT_COGNITO_USER_POOLS_WEB_CLIENT_ID,
    };
